import React, { useState , useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { useLanguage } from './LanguageContext';
import useSweetAlert from './SweetAlert';
import keywordsData from './keywords.json';
import { Helmet } from 'react-helmet';

const PayPalPaymentPage = () => {
  const { showAlert } = useSweetAlert();
  const [amount, setAmount] = useState(1);
  const [email, setEmail] = useState('');
  const [note, setNote] = useState('smart loader support');
  const [loading, setLoading] = useState(false);
  const [sdkLoading, setSdkLoading] = useState(true);  // State to check if SDK is loading
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const { language } = useLanguage();

  useEffect(() => {
    //window.scrollTo(0, 0);
  }, []);

  // Handling amount change
  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  // Handling emaol change
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Handling emaol change
  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  // Handle payment success
  const handlePaymentSuccess = (details) => {
    setLoading(false);
    setPaymentCompleted(true);
    alert(
      language === 'ar'
        ? `تم الدفع بواسطة ${details.payer.name.given_name}`
        : `Transaction completed by ${details.payer.name.given_name}`
    );
  };

  const handlePay = async() => {
    if(email === "" || email === null || email === undefined || amount === "" || amount === null || amount === undefined || amount < 1) {
        showAlert({
          text: language === 'ar' ? 'الرجاء إدخال حساب باي بال و القيمة 1 أو أكثر يورو !' : 'Enter your PayPal account and amount 1 or more EUR!',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
        return;
      }

    setLoading(true);
    try {
        const formData = new FormData();
        formData.append('cmd', '_xclick');
        formData.append('no_note', '1');
        formData.append('lc', 'UK');
        formData.append('bn', 'PP-BuyNowBF:btn_buynow_LG.gif:NonHostedGuest');
        formData.append('userid', '0');
        formData.append('is_mobile', 'true');
        formData.append('item_name', email);
        formData.append('item_number', '0');
        formData.append('payment_amount', amount);
        formData.append('payment_src', 'smartloader');
        formData.append('custom', note);
        const response2 = await fetch(`https://webapppros.com/portalDash/api/stratpayment`, {
          method: 'POST',
          body: formData,
        });
        const data2 = await response2.json();
        console.log('Uploaded data:', data2);
        if(data2.success) {
            localStorage.setItem('payment_prossess_id', data2.prossess_id);
            //window.open(data2.redirect_url, '_blank');
            window.location.href = data2.redirect_url;
        } else {
            showAlert({
                text: language === 'ar' ? 'حدث خطأ , الرجاء إدخال قيمة 1 أو أكثر يورو' : 'Error prossess your payment, Please enter 1 or more EUR or Please try again later.',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start'
              });
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        showAlert({
          text: language === 'ar' ? 'حدث خطأ , الرجاء إدخال قيمة 1 أو أكثر يورو' : 'Error prossess your payment, Please enter 1 or more EUR or Please try again later.',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
      } finally {
        //setLoading(false);
      }
  }

  // Call initializePayPal on component mount
  //useEffect(() => {
  //}, []);

  const SEO = ({ title, description, keywords }) => (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "${title}",
            "url": "https://samrt-loader.com"
          }
        `}
      </script>
    </Helmet>
  );

  return (
    <Container maxWidth="sm">
      <SEO 
        title={language === 'ar' ? keywordsData.title_payments_ar : keywordsData.title_payments_en}
        description={language === 'ar' ? keywordsData.payments_description_ar : keywordsData.payments_description_en}
        keywords={keywordsData.keywords.join(', ')}
      />
      <Box
        sx={{
          mt: 8,
          p: 4,
          boxShadow: 2,
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="h4" my={2} gutterBottom>
          {language === 'ar' ? 'الدعم عن طريق باي بال' : 'PayPal Payment'}
        </Typography>
        {paymentCompleted ? (
          <Typography variant="h6" color="success.main">
            {language === 'ar' ? 'تم الشراء بنجاح' : 'Payment Successful!'}
          </Typography>
        ) : (
          <>
            <TextField
              label={language === 'ar' ? "حساب باي بال" : "Your PayPal account"}
              variant="outlined"
              fullWidth
              type="email"
              value={email}
              onChange={handleEmailChange}
              sx={{ mb: 4 }}
            />
            <TextField
              label={language === 'ar' ? "القيمة (يورو)" : "Amount (EUR)"}
              variant="outlined"
              fullWidth
              type="number"
              value={amount}
              onChange={handleAmountChange}
              sx={{ mb: 4 }}
            />
            {loading ? (
              <CircularProgress />
            ) : (
              <Box sx={{ mt: 2 }} >
                <Button onClick={handlePay} variant="contained" color="success" aria-label="pay">            
                   {language === 'ar' ? 'شراء' : 'Pay'}
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default PayPalPaymentPage;
