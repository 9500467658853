// src/components/ImagesGallery.js

import React, { useState, useEffect, useCallback } from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Container, Box, Card, CardContent, Grid, Typography, Button, CircularProgress, Modal } from '@mui/material';
import SectionsBar from './ImagesSectionsBar';
import { useLanguage } from './LanguageContext';
import { useTheme } from './ThemeContext';
import { Helmet } from 'react-helmet';
import keywordsData from './keywords.json';

const ImagesGallery = () => {
  const [images, setImages] = useState([]);
  const { language } = useLanguage();
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [page, setPage] = useState(1);
  const [sectionIDSet, setSectionID] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isSectionLoad, setIsSectionLoad] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [sections, setSections] = useState([]);
  const imagesPerPage = 18;
  const { theme } = useTheme();
  //const buttonColor = theme === 'light' ? '#d9abb0' : '#fff'
  const modelBackgroundColor = theme === 'light' ? 'white' : '#090a09'

  const fetchImages = useCallback(async () => {
    if(loading){
      return;
    }
    setLoading(true);
    await fetchSections();
    try {
      const response = await fetch(`https://maoread.com/status/api/list/images_sec?showmaster=images_worlds&sec_id=${sectionIDSet}&page=${page}&recperpage=${imagesPerPage}`);
      const data = await response.json();
      if (data.success && data.action === 'list') {
        setImages(prevVideos => [...prevVideos, ...data.images_sec]);
        setTotalRecordCount(data.totalRecordCount);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  }, [page,sectionIDSet]);

  const fetchSections = async () => {
    if (isSectionLoad){
      return;
    }
    try {
        const response = await fetch('https://maoread.com/status/api/list/images_worlds?recperpage=ALL'); // Replace with your API endpoint
        const data = await response.json();
        setSections(data.images_worlds);
        setIsSectionLoad(true);
    } catch (error) {
        console.error('Error fetching sections:', error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const handleSectionClick = async (sectionId) => {
    setSectionID(sectionId.toString());
    setPage(1);
    setImages([]);
    //fetchVideos();
  };

  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  const handleOpenModal = (image) => {
    setSelectedImage(image);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setOpenModal(false);
  };
  const morgin = language === 'ar' ? '-16px' : '0';
  const width = language === 'ar' ?  'calc(100% + 16px)' :  'calc(100% + 24px)';

  const SEO = ({ title, description, keywords }) => (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "${title}",
            "url": "https://samrt-loader.com"
          }
        `}
      </script>
    </Helmet>
  );
  
  return (
    <div>
      <SEO 
        title={language === 'ar' ? keywordsData.title_images_gallary_ar : keywordsData.title_images_gallary_en}
        description={language === 'ar' ? keywordsData.description_ar : keywordsData.description_en}
        keywords={keywordsData.keywords.join(', ')}
      />
      <SectionsBar sections={sections} onSectionClick={(section) => handleSectionClick(section)} />
      <Container>
      <Box sx={{ textAlign: 'center', marginBottom: 5, marginTop:5 }}>
         <h2>{language === 'ar' ? 'مكتبة الصور' : 'Photos Gallery'}</h2>
      </Box>
      <Grid container spacing={3} style={{marginRight: morgin , width: width}}>
        {images.map(image => (
          <Grid key={image.id} item xs={12} md={6} lg={4}>
            <Card variant="outlined" onClick={() => handleOpenModal(image)}>
              <img
                src={image.image_file.url}
                alt={image.title_image}
                style={{ width: '100%', height: '340px', objectFit: 'cover' }} />
              <CardContent>
                <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
                  <h3>{image.title_image}</h3>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                  <Box mx={5} my={1} textAlign="center">
                    <Typography variant="body2"><ThumbUpIcon style={{fontSize:'medium'}}/>&nbsp;{image.post_likes}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {loading ? (
        <Box sx={{ textAlign: 'center', marginTop: 5 }} my={5}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ textAlign: 'center', marginTop: 5 }} my={5}>
          {images.length < totalRecordCount && (
            <Button aria-label="load_more" variant="contained" onClick={handleLoadMore}>{language === 'ar' ? 'المزيد' : 'Load More'}</Button>
          )}
        </Box>
      )}
    </Container>
    {/* Modal to display video */}
    <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{ width: '80vw', maxWidth: '800px', height: '450px', backgroundColor: [modelBackgroundColor], overflow: 'hidden', margin: 'auto' }}>
          <img
            src={selectedImage ? selectedImage.image_file.url : ''}
            alt={selectedImage ? selectedImage.title_image : ''}
            style={{ width: '100%', height: '380px', objectFit: 'contain' }} />
                      <Box my={2} textAlign="center">
          <Button aria-label="download" variant="contained" color="success" onClick={() => window.open(selectedImage ? selectedImage.image_file.url : '', 'download')} download>
              <FileDownloadIcon />&nbsp;&nbsp;{language === 'ar' ? 'تحميل' : 'Download'}
          </Button>&nbsp;
          <Button aria-label="close" variant="contained" onClick={handleCloseModal}>{language === 'ar' ? 'إغلاق' : 'Close'}</Button>
        </Box></Box>
      </Modal>
  </div>
  );
};

export default ImagesGallery;
