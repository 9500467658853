import React from 'react';

const VideoShareThumbnail = ({ imageUrl, isMobile, onClick }) => {
    const styles = {
        container: {
          position: 'relative',
          display: isMobile ? 'flex' : 'inline-block',
        },
        image: {
          display: 'block',
          width: '100%',
          height: '300px',
          borderRadius: '8px',
        },
        overlay: {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          borderRadius: '8px',
          cursor: 'pointer',
        },
      };
      
  return (
    <div style={styles.container}>
      <img src={imageUrl} alt="Video Thumbnail" style={styles.image} />
      <div style={styles.overlay} onClick={onClick}>
        <svg
          width="50"
          height="50"
          viewBox="0 0 24 24"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8 5v14l11-7z" />
        </svg>
      </div>
    </div>
  );
};

export default VideoShareThumbnail;
