import React, { useState } from 'react';
import { Dialog, Rating, DialogTitle, DialogContentText, DialogContent, DialogActions, Button, Box } from '@mui/material';
import { useLanguage } from './LanguageContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const RateDialog = ({ onClose }) => {
  const { language } = useLanguage();
  const [rating, setRating] = useState(0);
  const [setedData, setData] = useState(0);

  const handleCheckboxChange = async (value) => {
    setRating(value);
  };
  
  const handelRateDialog = async() => {
    try {
      const response = await fetch('https://samrt-loader.com/kydwon/api/rate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ rating: rating }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setData(data);
      console.log('Response from API:', data);
      onClose(data); // Close the dialog after successful API call
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }

  const handleClose = () => {
    onClose(setedData); // Pass the selected rating value to the onClose handler
  };

  // Create a theme with the direction based on the language
  const theme = createTheme({
    direction: language === 'ar' ? 'rtl' : 'ltr',
  });

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={true} dir={language === 'ar' ? 'rtl' : 'ltr'}>
        <DialogTitle textAlign="center">
          {language === 'ar' ? 'قيمنا' : 'Rate us'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText textAlign="center">
            {language === 'ar'
              ? 'نسعد بتقديم تقييمكم لخدماتنا لتجربة مستخدم أفضل .'
              : 'We are pleased with your evaluation of our services for a better user experience.'}
          </DialogContentText>
          <Box display="flex" justifyContent="center" mt={2}>
            <Rating
              name="rate_us"
              value={rating}
              onChange={(event, newValue) => {
                handleCheckboxChange(newValue);
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button aria-label='close' onClick={handleClose} color="primary">
            {language === 'ar' ? 'إغلاق' : 'Close'}
          </Button>
          <Button aria-label='rate' onClick={handelRateDialog} color="success">
            {language === 'ar' ? 'تقييم' : 'Rate'}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default RateDialog;
