import React, { useState, useRef } from 'react';
import { Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

const AudioPlayer = ({ audioUrl , onAudioClick}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio(audioUrl));

  const togglePlayPause = () => {
    var palyset = false;
    if (isPlaying) {
      audioRef.current.pause();
      palyset = false;
    } else {
      audioRef.current.play();
      palyset = true;
    }
    setIsPlaying(!isPlaying);
    onAudioClick(palyset,audioRef);
  };

  return (
      <Button aria-label={"play sound"} variant="contained" color="success" onClick={togglePlayPause}>
        {isPlaying ? (
            <StopIcon />      
        ) : (
            <PlayArrowIcon />       
        )}
      </Button>
  );
};

export default AudioPlayer;
