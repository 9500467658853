import React, { useState, useEffect, useRef , useCallback} from 'react';
import { InputAdornment,LinearProgress,Slider,InputLabel  ,Menu,MenuList,ListItemIcon,TextField,IconButton,Tooltip,FormControl,MenuItem,Box,ListItemButton,List,ListItem,ListItemText,Divider,Dialog,DialogContentText,DialogTitle,DialogContent,DialogActions,Button,ButtonGroup,Typography,CircularProgress,Link } from '@mui/material';
import './Studio.css';
import VideoEditor from './VideoEditor';
import { useTheme } from './ThemeContext';
import  AudioPlayer  from './AudioPlayer';
import { useLoading } from './LoadingContext';
import { useLanguage } from './LanguageContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import CustomGoogleLogin from './CustomGoogleLogin';
import EmojiPickerDialog from './EmojiPickerDialog';
import FaceSwapImagesPicker from './FaceSwapImagesPicker';
import PortraitIcon from '@mui/icons-material/Portrait';
import { updateClick } from './updateClick';
import { jwtDecode } from 'jwt-decode';
import VideoList from './VideoList';
import PasteIcon from '@mui/icons-material/ContentPasteOutlined';
import DownnIcon from '@mui/icons-material/DownloadOutlined';
import axios from 'axios';
import CustomTextField from './CustomTextField';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReactPlayer from 'react-player';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VideoStableIcon from '@mui/icons-material/VideoStable';
import DownloadIcon from '@mui/icons-material/Download';
import useSweetAlert from './SweetAlert';
import { Helmet } from 'react-helmet';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice';
import keywordsData from './keywords.json';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { styled } from '@mui/material/styles';
import AbcIcon from '@mui/icons-material/Abc';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import EditNoteIcon from '@mui/icons-material/EditNote';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KonvaCanvasEditor from './KonvaCanvasEditor'; // To load images

const CustumVideoEditer = () => {
  const siteVer = '19.16.8';
  const { theme } = useTheme();
  const ColorInput = styled('input')({
    width: '100%',
    marginTop: '16px',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  });
    const canvasRef = useRef(null);
    const fileInputRef = useRef(null);
    const videofileInputRef = useRef(null);
    const imagefileInputRef = useRef(null);
    const [thumbnails, setThumbnails] = useState([]);
    const [currentFrame, setCurrentFrame] = useState(new window.Image());
    const [sliderValue, setSliderValue] = useState(0);
    const [videoDuration, setVideoDuration] = useState(0);
    const [currentProssessID, setCurrentProssessID] = useState(0);

    const containerRef = useRef(null);
    const navigate = useNavigate(); // Initialize useNavigate
    const [showUpdateDialog, setShowUpdateDialog] = useState(false);
    const { showAlert } = useSweetAlert();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl_sec, setanchorEl_sec] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [showHowToEditerDialog, setShowHowToEditerDialog] = useState(false);
    const [showLocalVideoEditerDialog, setShowLocalVideoEditerDialog] = useState(false);
    const [previewLocalVideoUrl, setPreviewLocalVideoUrl] = useState('');
    const [stickerGenrateType, setStickerGenrateType] = useState('');

    const isLight = theme === 'light';
    const cnavasBackgroundColor = isLight ? '#eaeaea' : '#0f0b0b';
    const videoListBackgroundColor = isLight ? '#f1f1f1' : '#141010';
    const modelBackgroundColor = theme === 'light' ? 'white' : '#090a09'

    const { showLoading, hideLoading } = useLoading();
    const { language } = useLanguage();
    const [loadingViewBox, setLoadingViewBox] = useState(false);
    const [openStickerModle, setOpenStickerModle] = useState(false);
    const [openDownloadUrlModle, setOpenDownloadUrlModle] = useState(false);
    const [openTextModle, setOpenTextModle] = useState(false);
    const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });
    const [userText, setUserText] = useState("");
    const [textPosition, setTextPosition] = useState({ x: -73, y: -329 });
    const [currentPosition, setCurrentPosition] = useState({ x: -73, y: -329 });
    const [prossessFilesInfo, setProssessFilesInfo] = useState({ currentFile: 0, totalFiles: 0 });

    const [selectedEmoji, setSelectedEmoji] = useState(null);
    const [openEmojiModle, setOpenEmojiModle] = useState(false);
    const [isAllowAdd, setIsAllowAdd] = useState(false);

    const [openFaceSwapModle, setOpenFaceSwapModle] = useState(false);

    const [selectedProjectID, setSelectedProjectID] = useState(0);
    const [emptyVideosListCount, setEmptyVideosListCount] = useState(0);
    const [emptyVideosListArray, setEmptyVideosListArray] = useState([]);

    const [loadingAddEditBox, setLoadingAddEditBox] = useState(false);
    const [loadingDeleteEditerBox, setLoadingDeleteEditerBox] = useState(false);
    const [openDeleteEditerBox, setOpenDeleteEditerBox] = useState(false);
    const [openAddEditerModal, setOpenAddEditerModal] = useState(false);
    const [openBackgroundModle, setOpenBackgroundModle] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [videoTextColor, setVideoTextColor] = useState('#050505');
    const [videoTextSize, setVideoTextSize] = useState(10);
  
    const [emojyPosX, setEmojyPosX] = useState(100);
    const [emojyPosY, setEmojyPosY] = useState(100);
    const [emojyHeight, setEmojyHeight] = useState(50);
    const [emojyWidth, setEmojyWidth] = useState(50);
    const [emojyRotation, setEmojyRotation] = useState(0);

    const [resultViewBox, setResultViewBox] = useState('');
    const [userAudioURL, setUserAudioURL] = useState('');
    const [editerBoxData, setEditerBoxData] = useState([]);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [removeBackGround, setRemoveBackGround] = useState("False");
    const [backgroundUrl, setBackgroundUrl] = useState("");
    const [openLoginDialog, setOpenLoginDialog] = useState(false);
    const [openDeleteVideoBox, setOpenDeleteVideoBox] = useState(false);
    const [openProjectListDialog, setOpenProjectListDialog] = useState(false);
    const [loadingEditerBox, setLoadingEditerBox] = useState(false);
    const [isAddEditer, setIsAddEditer] = useState(false);

    const [loadingEditerAudioBox, setLoadingEditerAudioBox] = useState(false);
    const [loadingDeleteVideoBox, setLoadingDeleteVideoBox] = useState(false);
    const [isPlaySound, setIsPlaySound] = useState(false);
    const [audioRef, setAudioRef] = useState(null);

    //downloads
    const [url, setUrl] = useState('');
    const [orginUrl, setOrginUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [videoData, setVideoData] = useState([]);
    const [videoCover, setVideoCover] = useState("");
    const [clipboardContent, setClipboardContent] = useState('');
    const [isProssess, setIsProssess] = useState(false);

    //recognize model
    const [recognizeLoading, setRecognizeLoading] = useState(false);
    const [recognizeError, setRecognizeError] = useState('');
    const [recognizeResult, setRecognizeResult] = useState(null);
    const [openRecognizeModal, setOpenRecognizeModal] = useState(false);

    //login
    const [userEmail, setUserEmail] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confrormNewPassword, setConformNewPassword] = useState('');
    const [openReg, setOpenReg] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [name, setName] = useState('');
    const [isReadEditer, setIsReadEditer] = useState(() => {
      // Attempt to load the user from localStorage
      const checkIsRead = localStorage.getItem('is_read_editer');
      if (checkIsRead === "true") {
        // Optionally decode token to get user info
        return true;
      }
      return false;
    });

    const [loadingShare, setLoadingShare] = useState(false);
    const [openSoundModle, setOpenSoundModle] = useState(false);

    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedVideoID, setSelectedVideoID] = useState(0);
    const [selectedTime, setSelectedTime] = useState(0);
    const [selectedFrame, setSelectedFrame] = useState(null);
    const [genProcessType, setGenProcessType] = useState('');
    const [cutRanges, setCutRanges] = useState({});
    const [settingStart, setSettingStart] = useState(true); // To track start or end time
    //const [IsLogin, setIsLogin] = useState(false);
    const [showHowToStickerDialog, setShowHowToStickerDialog] = useState(false);
    const [aboutBackDialog, setAboutBackDialog] = useState(false);
    const [isShowText, setIsShowText] = useState(false);
    const [shareData, setShareData] = useState([]);
    const [isUpdate, setIsUpdate] = useState(() => {
      const checkIsUpdate = localStorage.getItem('is_updated');
      if (checkIsUpdate === "true") {
        // Optionally decode token to get user info
        return true;
      }
      return false;
    });
    const [showAds, setShowAds] = useState(false);

    //const storedValue = localStorage.getItem('isMobile');
    //const isMobileBoolean = storedValue === 'true'; // Convert the string back to a boolean
    const [IsLogin, setIsLogin] = useState(() => {
        // Attempt to load the user from localStorage
        const checkIsLogin = localStorage.getItem('isLogin');
        if (checkIsLogin === "true") {
          // Optionally decode token to get user info
          return true;
        }
        return false;
      });


      const handleDrag = (e, data) => {
        const { x, y } = data;
        console.log('Drag position:', { x, y });
        setTextPosition({ x, y });
      };

      const readUpdate = () => {
        setIsUpdate(false);
        localStorage.setItem('currentUpdateVer',siteVer);
        localStorage.setItem('is_updated','false');
      }
  
      const handleCloseDialog = async() => {
        await updateClick('update');
        localStorage.setItem('is_updated','true');
        window.location.reload();
      };

      const handleOpenRecognizeModal = async (url) => {
        setRecognizeLoading(true); // Start loading
        setRecognizeError('');
        setRecognizeResult(null);
        setOpenRecognizeModal(true); 
        await updateClick('recognize');
        try {
            // Send the request to your API
            const response = await axios.post('https://samrt-loader.com/recognize', {
                url, // Passing the url directly in the POST body
            });
    
            const data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
    
            // Check if recognition was successful
            if (data.status.code === 0) {
                setRecognizeResult(data.metadata.music);
            } else {
                setRecognizeError(language === 'ar' ? 'لم يتم العثور على معلومات الموسيقى' : 'No result found');
            }
        } catch (error) {
            setRecognizeError(language === 'ar' ? 'لم يتم العثور على معلومات الموسيقى' : 'No result found');
        } finally {
            setRecognizeLoading(false); // Stop loading
        }
    };
    

      const handleEmailChange = (e) => {
        const email = e.target.value;
        setEmail(email);
        if(email === '') {
          setIsValid(true);
        } else {
          setIsValid(isValidEmail(email));
        }
      };
  
      const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

      const handlePasswordChange = (e) => {
        setPassword(e.target.value);
      };

      
    const handleConfirmPasswordChange = (e) => {
      setConfirmPassword(e.target.value);
    };
  

    const showErrorLogin = () => {
      showAlert({
        text: language === 'ar' ? 'الإيميل أو كلمة المرور خاطئة' : 'Wrong E-mail or password',
        icon: 'error',
        position: language === 'ar' ? 'top-end' : 'top-start'
      });
      hideLoading();
    }

  // Function to handle the drag of text
  const handleDragStop = (e, data) => {
    console.log(textPosition);
    const { x, y } = data;
      
    // Normalize positions based on canvas dimensions
    const canvasElement = containerRef.current; // Assuming this refers to your container element
    const canvasRect = canvasElement.getBoundingClientRect();
    
    console.log('canvasRect.width:', canvasRect.width);
    console.log('canvasRect.height:', canvasRect.height);

    console.log('videoDimensions.width:', videoDimensions.width);
    console.log('videoDimensions.height:', videoDimensions.height);

    console.log('x:',x);
    console.log('y:',y);

  // Normalize the dragged position relative to the video dimensions
  //const scalingFactorX = videoDimensions.width / canvasRect.width;
  //const scalingFactorY = videoDimensions.height / canvasRect.height;
    // Calculate scaling factors
    // Calculate scaling factors
    // Calculate scaling factors
    
    // Calculate scaling factors for width and height
    const scalingFactorX = videoDimensions.width / canvasRect.width;
    const scalingFactorY = videoDimensions.height / canvasRect.height;

    // Normalize X position (stays the same)
    var invertedX = x + canvasRect.width ;
    var normalizedX = Math.max(0, Math.min(videoDimensions.width, invertedX * scalingFactorX));

    if(userText.length >= 20) {
      // Normalize X position (stays the same)
      if(videoTextSize > 15){
        invertedX = invertedX / 4 ;
      } else {
        invertedX = invertedX / 3 ;
      }
      normalizedX = Math.max(0, Math.min(videoDimensions.width, invertedX * scalingFactorX));
    } else {
      if(videoTextSize > 16){
        invertedX = invertedX / 2 ;
        normalizedX = Math.max(0, Math.min(videoDimensions.width, invertedX * scalingFactorX));
      } else if(videoTextSize > 20){
        invertedX = invertedX / 3 ;
        normalizedX = Math.max(0, Math.min(videoDimensions.width, invertedX * scalingFactorX));
      }
    }
    
    // Y position: we flip it because y=0 should start at the top of the video, so we subtract it from canvasRect.height
    const invertedY = y + canvasRect.height ;
    const normalizedY = Math.max(0, Math.min(videoDimensions.height, invertedY * scalingFactorY));

    // Log normalized positions
    console.log('normalizedX:', normalizedX);
    console.log('normalizedY:', normalizedY);

    // Log raw values and their scaled equivalents
    console.log('Raw x:', (x + canvasRect.width / 2), 'scaled x:', normalizedX);
    console.log('Raw y:', invertedY, 'scaled y:', normalizedY);
  

    // Update state or send positions
    setTextPosition({ x: normalizedX, y: normalizedY });
    handleSaveTextModel(true,normalizedX,normalizedY);
    //setTextPosition({ x: data.x, y: data.y });
  };

  // Function to handle the input text change
  const handleTextChange = (e) => {
    setUserText(e.target.value);
  };

  
  const handleDownload = async () => {
    if (!validateUrl(url)) {
      setError(language === 'ar' ? 'الرابط غير صالح' : 'Invalid URL');
      return;
    }
    setLoading(true);
    setVideoData([]); // Clear the previous video data
    setVideoCover("");
    setError('');
    try {
      const response = await axios.post('https://samrt-loader.com/kydwon/api/addfile', { file_name: url });
      if (response.data.success) {
        if(response.data.task_id !== null) {
          checkStatusDownload(response.data.task_id)
        } else {
          setVideoData(response.data.files); // Set videoData state with response data
          setVideoCover(response.data.image_cover);
          setOrginUrl(response.data.file_url);
          setLoading(false);
          const firstVideoUrl = response.data.files[0].file || "";
          setIsAllowAdd(true);
          localStorage.setItem('VideoUrl',firstVideoUrl) ;
          localStorage.setItem('prossessType',"normal") ;
        }
      } else {
        setError(language === 'ar' ? 'لم يتم العثور على فيديو' : 'Failed to download video.');
        setLoading(false);
      }
    } catch (error) {
      setError(language === 'ar' ? 'لم يتم العثور على فيديو' : 'Failed to download video Some Error.');
      setLoading(false);
    }
  };

  const checkStatusDownload = async (taskId) => {
    const statusResponse = await fetch(`https://samrt-loader.com/task_status_down/${taskId}`);
    const statusData = await statusResponse.json();

    if (statusData.status === 'PENDING') {
      setIsProssess(true);
      setTimeout(() => checkStatusDownload(taskId), 5000); // Check again after 5 seconds
    } else if (statusData.status === 'SUCCESS') {
      setVideoData(statusData.files); // Set videoData state with response data
      setVideoCover(statusData.image_cover);
      setOrginUrl(statusData.file_url);
      setLoading(false);
      setIsProssess(false);
      const firstVideoUrl = statusData.files[0].file || "";
      setIsAllowAdd(true);
      localStorage.setItem('VideoUrl',firstVideoUrl) ;
      localStorage.setItem('prossessType',"normal") ;
      //console.error('success path', url);
    } else {
      setError(language === 'ar' ? 'لم يتم العثور على فيديو' : 'Failed to download video Some Error.');
      setLoading(false);
      setIsProssess(false);
    }
  };

  const handlePasteFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const trimmedText = text.replace(/\s/g, ''); // Remove all spaces
      setClipboardContent(trimmedText);
      setUrl(trimmedText); // Update the URL field with the trimmed clipboard content
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
    }
  };
  const buttonBackgroundColor = theme === 'light' ? '#dfc7b2' : '#4d4844'
  const boxBackgroundColor = theme === 'light' ? '#e3dfde' : '#080406'
  const textColor = theme === 'light' ? '#870749' : '#fff'
  const scrollColor = theme === 'light' ? '#f0f0f0' : '#0e0000'

      const generateFileName = (type) => {
        var fileName = '';
        const randomNumber = Math.floor(100000 + Math.random() * 900000); // Generates a 6-digit random number
        if(type === 'video/mp4'){
          fileName = `samrt-loader.com_${randomNumber}.mp4`;
        }else if(type === 'image/png'){  
          fileName = `samrt-loader.com_${randomNumber}.png`;
        } else {
          fileName = `samrt-loader.com_${randomNumber}.gif`;
        }
        return fileName;
      };

      const shareVideo = async (videoUrl) => {
        setLoadingShare(true);
        var type = "video/mp4";
        if(videoUrl.endsWith('.png')) { 
          type = "image/png" ;
        } else if(videoUrl.endsWith('.gif')) {
          type = "image/gif" ;
        } else {
          type = "video/mp4";
        }
        try {
          const proxyUrl = `https://samrt-loader.com/download_share_video?url=${encodeURIComponent(videoUrl)}&type=${type}`;
          const response = await fetch(proxyUrl);
          const blob = await response.blob();
          const file = new File([blob], generateFileName(type), { type: type });
    
          if (navigator.share) {
            await navigator.share({
              files: [file],
              title: 'Check out this video',
              text: 'Here is a video I downloaded!',
            });
          } else {
            console.error('Sharing not supported on this browser');
          }
        } catch (error) {
          console.error('Error sharing video:', error);
        }
        setLoadingShare(false);
      };

      const handleDeletEditerBox = async(projectID) => {
        try{
            setLoadingDeleteEditerBox(true);
            var done = false;
            const formData = new FormData();
            formData.append('key_m[]', projectID);
            const response = await fetch(`https://samrt-loader.com/kydwon/api/delete/projects_video_editer`, {
              method: 'POST',
              headers: {
                'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
              },
              body: formData,
            });
            const data = await response.json();
            if(data.success){
                done = true;
            } else {
                showAlert({
                    text: language === 'ar' ? 
                      'حدث خطأ أثناء الحذف الرجاء المحاولة لاحقا' : 
                      'An error occurred while deleting, Please try login again',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                  });
            }
        } catch(error) {
            showAlert({
                text: language === 'ar' ? 
                  'حدث خطأ أثناء الحذف الرجاء المحاولة لاحقا' : 
                  'An error occurred while deleting, Please try login again',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start',
              });
        } finally {
            setLoadingDeleteEditerBox(false);
            handleCloseDeleteEditerBox();
            if(done){
                showAlert({
                    text: language === 'ar' ? 
                      'تمت الحذف بنجاح' : 
                      'Deleting successful',
                    icon: 'success',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                  });
                  handleOpenEditerBox("");
            }
        }
    };

  const handleCloseDeleteEditerBox = () => {
      setOpenDeleteEditerBox(false);
  };

  const handleClickOpenReg = () => {
    setOpenLoginDialog(false);
    setOpenReg(true);
  };

  const handleCloseTextModel = () => {
    setOpenTextModle(false);
  };

  const handleOpenBackgroundModel = () => {
    setanchorEl_sec(false);
    setOpenBackgroundModle(true);
  };

  const handleOpenTextModel = () => {
    setanchorEl_sec(false);
    setOpenTextModle(true);
  };

  const handleGenerateText = () => {
    //setOpenTextModle(false);
  };

  const handleCloseBackgroundModel = () => {
    setOpenBackgroundModle(false);
  };

  const handleOpenDeleteEditerBox = () => {
      setOpenDeleteEditerBox(true);
  };

  const handleOpenAddNewEditerBox = () => {
      setOpenAddEditerModal(true);
  };

  const handleDelete = async(projectID) => {
      setSelectedProjectID(projectID);
      handleOpenDeleteEditerBox();
  };

      const handleChangeBackgroundType = (event) => {
        setRemoveBackGround(event.target.value);
      };
    

      const handleOpenHowToStickerDialog = () => {
        setShowHowToStickerDialog(true);
      }

      const handelOpenAboutBackgroundModel = () => {
        setAboutBackDialog(true);
      }

      const handleCloseReg = () => {
        setOpenReg(false);
      };

      const handleNameChange = (e) => {
        setName(e.target.value);
      };

      const handleRegister = async() => {
        if (password !== confirmPassword) {
          //alert("Passwords don't match!");
          showAlert({
            text: language === 'ar' ? 'كلمة المرور غير متطابفة' : 'Passwords not match!',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
          return;
        } 
        if (name === '' || email === '' || password === '' || confirmPassword === '') {
          showAlert({
            text: language === 'ar' ? 'الرجاء إدخال جميع الحقول' : 'Please enter all feialds',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
          return;
        }
        if(!isValid){
          showAlert({
            text: language === 'ar' ? 'الرجاء إدخال صيغة الإيميل صحيحة' : 'Please enter a valid e-mail value',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
          return;
        }
        //console.log(`Email: ${email}, Password: ${password}`);
        //handleCloseReg();
        showLoading();
        var done = false;
        // Simulate API call
        try{
          const response = await fetch('https://samrt-loader.com/kydwon/api/register', {
            method: 'POST', // Specify the method
            headers: {
              'Content-Type': 'application/json' // Set the Content-Type header
            },
            body: JSON.stringify({
              user_name: name, // Use the correct field names
              use_email: email, // Use the correct field names
              password: password
            })
          });
          const data = await response.json();
          if(data.success) {
            done = true;
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          showAlert({
            text: language === 'ar' ? 'حدث خطأ أثناء التسجيل, الرجاء الحاولة لاحقا' : 'An error while register, Please try again later',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
          hideLoading();
        } finally {
          hideLoading();
          if(done){
            handleCloseReg();
            showAlert({
              text: language === 'ar' ? 'تم تسجيل حسابك بنجاح برجاء تفعيل الحساب من خلال الإيميل المرسل' : 'Registration success, Please active your account throw sending email.',
              icon: 'success',
              position: language === 'ar' ? 'top-end' : 'top-start'
            });
          } else {
            showAlert({
              text: language === 'ar' ? 'لم يتم التسجيل بنجاح' : 'Registration not success',
              icon: 'error',
              position: language === 'ar' ? 'top-end' : 'top-start'
            });
          }
        }
      };
  

      const handleLogin = async () => {
        if(!isValid){
          showAlert({
            text: language === 'ar' ? 'الرجاء إدخال صيغة الإيميل صحيحة' : 'Please enter a valid e-mail value',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
          return;
        }
        if (email === '' || password === '') {
          showAlert({
            text: language === 'ar' ? 'الرجاء إدخال الإيميل وكلمة المرور' : 'Please enter your e-mail and password',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
          return;
        }
        try {
          //handleClose();
          showLoading();
          setIsLogin(false);
          var done = false;
          // Simulate API call
          const formData = new FormData();
          formData.append('username', email);    
          formData.append('password', password);
  
          const response = await fetch('https://samrt-loader.com/kydwon/api/login', {
            method: 'POST', // Specify the method
            body: formData
          });
  
          const data = await response.json();
          const JWT = data.JWT;
          if(JWT){
            localStorage.setItem('authToken', JWT);
            localStorage.setItem('isLogin', 'true');
            const decodedToken = jwtDecode(JWT);
            const userid = decodedToken.security.userid;
            const username = decodedToken.security.username;
            const userLevelid = decodedToken.security.userlevelid;
            localStorage.setItem('userid', userid)
            localStorage.setItem('username', username)
            localStorage.setItem('userlevelid', userLevelid)
            localStorage.setItem('project_id',"0")
            setIsLogin(true);
            done = true;
          }
          //console.log(data);
        } catch (error) {
          console.error('Error fetching data:', error);
          showErrorLogin();
        } finally {
          if(done){
            hideLoading();
            handleClose();
            window.location.reload();
          } else {
            showErrorLogin();
          }
        }
      };

      const handelCloseAboutBackgroundModel = () => {
        setAboutBackDialog(false);
      }

      const handleThumbles = (thumb) => {
        setThumbnails(thumb);
      }

      const handleCloseRecognizeModal = () => {
        setOpenRecognizeModal(false);
      }

      const handleCloseStickerModel = () => {
        setStickerGenrateType('');
        setOpenStickerModle(false);
      }

      const handleCloseDownloadUrlModel = () => {
        setOpenDownloadUrlModle(false);
      }

      const handleOpenDownloadUrlModel = () => {
        setOpenDownloadUrlModle(true);
      }

      const handleMenuClose = () => {
        setAnchorEl(null);
      };  

      const handleSecMenuClose = () => {
        setanchorEl_sec(null);
      };  
  
      const handleCloseHowToStickerDialog = () => {
        setShowHowToStickerDialog(false);
      }

      const handleCloseHowToEditerDialog = async() => {
        setShowHowToEditerDialog(false);
        setIsReadEditer(true);
        localStorage.setItem('is_read_editer',"true");
        await updateClick('done_read_custum');
      };
      const handleOpenHowToEditerDialog = () => {
        setShowHowToEditerDialog(true);
      };

      const handleProjectNameChange = (e) => {
        setProjectName(e.target.value);
      };

      const handleCloseAddEditerModal = () => {
        setOpenAddEditerModal(false);
      };

      const handleDragEnd = (e) => {
        // Update the text position when dragging stops
        setTextPosition({
          x: e.target.x(),
          y: e.target.y(),
        });
      };

      const handleReqStieData = useCallback(async () => {
        try {
          const token = localStorage.getItem('authToken') || ""
          const response = await fetch('https://samrt-loader.com/kydwon/api/siteInfo' , {
            method: 'GET', // Specify the method
            headers: {
              'X-Authorization':`Bearer ${token}`
            },
          });
          const data = await response.json();
          if (data.success) {
            //setVideoData(response.data.files); // Set videoData state with response data
            setIsMobile(data.is_mobile);
            setShareData(data.share_data);
            if(data.is_mobile){
              localStorage.setItem('isMobile','true')
            } else {
              localStorage.setItem('isMobile','false')
            }
            localStorage.setItem('updateDialogVer', siteVer);
            if(data.is_set_pass){
              localStorage.setItem('is_set_pass', 'true');             
            } else {
              localStorage.setItem('is_set_pass', 'false');
            }
            const updateDialogVer = localStorage.getItem('updateDialogVer');
            if (updateDialogVer !== data.version) {
              setShowUpdateDialog(true);
            }
  
            localStorage.setItem('share_count',data.share_count)
            if(!isReadEditer) {
              if(!isUpdate){
                localStorage.setItem('currentUpdateVer',siteVer);
                setShowHowToEditerDialog(true);
              }
            }
  
            const currentUpdateVer = localStorage.getItem('currentUpdateVer');
            if (currentUpdateVer !== data.version) {
              if(!isUpdate){
                setIsUpdate(true);
              }
            }
            
            var isShowAds = data.is_show_ads;
            if(isShowAds) {
              setTimeout(() => setShowAds(true), 3000); // Check again after 3 seconds
            } else {
              setShowAds(false);
            }
  
            var is_login = data.is_login;
            if(is_login) {
              if(data.user_photo_url != null) {
                localStorage.setItem('user_photo_url',data.user_photo_url);
              }
            } else {
              logout();
            }
          }
        } catch (error) {
        }
      }, [isUpdate, isReadEditer, siteVer]); // Remove shareData from the dependencies
  
      const logout = async() => {
        localStorage.setItem('authToken', null);
        localStorage.setItem('isLogin', 'false');
        localStorage.setItem('userid', null);
        localStorage.setItem('username', null);
        localStorage.setItem('userlevelid', null);
        localStorage.setItem('project_id',"0");
      }

      const [isProjectId, setIsProjectId] = useState(() => {
        // Attempt to load the user from localStorage
        const projectID = localStorage.getItem('project_id');
        if (projectID !== "0") {
          // Optionally decode token to get user info
          return true;
        }
        return false;
      });

      const handleFileChange = async(event) => {
        const file = event.target.files[0];
        if (file) {
          // Call the onUpload function passed as a prop to handle the file upload
          await handleUpload(file);
        }
      };

      const handleImageFileChange = async(event) => {
        const file = event.target.files[0];
        if (file) {
          // Call the onUpload function passed as a prop to handle the file upload
          await handelAddImageFromDevice(file);
        }
      };
      
      const handelAddImageFromDevice = async(file) => {
        handleMenuClose();
        showLoading();
        const formData = new FormData();
        formData.append('file', file);
    
        try {
          const response = await fetch('https://samrt-loader.com/kydwon/api/upload', {
            method: 'POST',
            headers: {
              'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
            },
            body: formData,
          });
    
          if (response.ok) {
            const data = await response.json();
           // alert('Image uploaded successfully!');
            console.log('Uploaded data:', data);
            if (data?.success && data.files?.file?.success) {
              try {
                const fileToken = data.filetoken;
                const formData = new FormData();
                formData.append('process_orgenal_video_file', "0");
                formData.append('process_face_swap_image_file', fileToken);
                formData.append('process_orgenal_audio_file', "0");
                const response2 = await fetch(`https://samrt-loader.com/kydwon/api/edit/process_video_editer/${selectedVideoID}`, {
                  method: 'POST',
                  headers: {
                    'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
                  },
                  body: formData,
                });
                const data2 = await response2.json();
                if(data2.success) {
                  if(data2.process_video_editer.process_face_swap_image_file.url) {
                    setTimeout(() => {
                       setBackgroundUrl(data2.process_video_editer.process_face_swap_image_file.url)
                       hideLoading();
                    }, 3000); // Check again after 3 seconds
                  } else {
                    hideLoading();
                    showAlert({
                      text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, قد يكون ملف غير صالح أو حجم كبير بالرجاء المحاولة في ملف اخر.' : 'Error uploading audio,Your file maybe incruptud or big size Please try another file.',
                      icon: 'error',
                      position: language === 'ar' ? 'top-end' : 'top-start'
                    });
                  }
                }
              } catch (error) {
                console.error('Error uploading image:', error);
                hideLoading();
                showAlert({
                  text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, قد يكون ملف غير صالح أو حجم كبير بالرجاء المحاولة في ملف اخر.' : 'Error uploading audio,Your file maybe incruptud or big size Please try another file.',
                  icon: 'error',
                  position: language === 'ar' ? 'top-end' : 'top-start'
                });
              }
            } else {
              hideLoading();
              showAlert({
                text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, قد يكون ملف غير صالح أو حجم كبير بالرجاء المحاولة في ملف اخر.' : 'Error uploading audio,Your file maybe incruptud or big size Please try another file.',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start'
              });
            }
          } else {
            hideLoading();
            showAlert({
              text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, الرجاء المحاولة لاحقا.' : 'Error uploading audio, Please try again later.',
              icon: 'error',
              position: language === 'ar' ? 'top-end' : 'top-start'
            });
          }
        } catch (error) {
          console.error('Error uploading image:', error);
          hideLoading();
          showAlert({
            text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, الرجاء المحاولة لاحقا.' : 'Error uploading audio, Please try again later.',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        }
      }

      const handleSaveEmojyModel = async(x,y,w,h,r) => {
        //console.log("Emoji new position: ", x, y);
        //console.log("Emoji new size: ", w, h);
        //console.log("Emoji new rotert: ", r);
        showLoading();
        try {
          const formData = new FormData();
          const formattedX = x.toString().replace('.', ',');
          const formattedY = y.toString().replace('.', ',');
          const formattedH = h.toString().replace('.', ',');
          const formattedW = w.toString().replace('.', ',');
          const formattedR = r.toString().replace('.', ',');
          formData.append('process_emoji_pos_x', formattedX);
          formData.append('process_emoji_pos_y', formattedY);
          formData.append('process_emoji_width', formattedW);
          formData.append('process_emoji_height', formattedH);
          formData.append('process_emoji_rotation', formattedR);
          const response2 = await fetch(`https://samrt-loader.com/kydwon/api/edit/process_video_editer/${selectedVideoID}`, {
            method: 'POST',
            headers: {
              'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
            },
            body: formData,
          });
          const data = await response2.json();
          if(data.success) {
            console.log('data saved')
          }
        } catch (error) {

        } finally {
          hideLoading();
        }
      }

      const handleSaveTextModel = async(isMove , normalizedX , normalizedY) => {
        try {
          if(userText === "") {
            showAlert({
              text: language === 'ar' ? 'الرجاء إدخال نص أولا !' : 'Enter text first!',
              icon: 'error',
              position: language === 'ar' ? 'top-end' : 'top-start'
            });
            return;
          }
          showLoading();
          if(isMove){
            const formData = new FormData();
            console.log('normalizedX',normalizedX);
            console.log('normalizedY',normalizedY);
            const formattedX = normalizedX.toString().replace('.', ',');
            const formattedY = normalizedY.toString().replace('.', ',');
            formData.append('process_video_text_pos_x', formattedX);
            formData.append('process_video_text_pos_y', formattedY);
            const response2 = await fetch(`https://samrt-loader.com/kydwon/api/edit/process_video_editer/${selectedVideoID}`, {
              method: 'POST',
              headers: {
                'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
              },
              body: formData,
            });
            const data = await response2.json();
            if(data.success) {
              if(data.process_video_editer.process_video_text !== null) {
                setTextPosition({x:data.process_video_editer.process_video_text_pos_x,y:data.process_video_editer.process_video_text_pos_y});
              } else {
                setTextPosition({ x: -73, y: -329 });
              }
            }
          } else {
            const formData = new FormData();
            var txtColr = '';
            if(videoTextColor === '' || videoTextColor === undefined || videoTextColor === null){
              txtColr = '#050505';
            } else {
              txtColr = videoTextColor;
            }
            formData.append('process_video_text', userText);
            formData.append('process_video_text_pos_x', textPosition.x);
            formData.append('process_video_text_pos_y', textPosition.y);
            formData.append('process_video_text_color', txtColr);
            formData.append('process_video_text_size', videoTextSize);
            const response2 = await fetch(`https://samrt-loader.com/kydwon/api/edit/process_video_editer/${selectedVideoID}`, {
              method: 'POST',
              headers: {
                'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
              },
              body: formData,
            });
            const data = await response2.json();
            if(data.success) {
              if(data.process_video_editer.process_video_text !== null) {
                setUserText(data.process_video_editer.process_video_text);
                setVideoTextSize(data.process_video_editer.process_video_text_size);
                setVideoTextColor(data.process_video_editer.process_video_text_color);
                setTextPosition({x:data.process_video_editer.process_video_text_pos_x,y:data.process_video_editer.process_video_text_pos_y});
                setIsShowText(true);
              } else {
                setUserText("");
                setVideoTextSize(10);
                setVideoTextColor("");
                setTextPosition({ x: -73, y: -329 });
                setIsShowText(false);
              }
              showAlert({
                text: language === 'ar' ? 'تم حفظ النص' : 'text save Successfully.',
                icon: 'success',
                position: language === 'ar' ? 'top-end' : 'top-start'
              });
            }
          }
        } catch (error) {
          console.error('Error uploading text:', error);
          showAlert({
            text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, برجاء المحاولة لاحقا.' : 'Error adding text, Please try again later.',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        } finally{
          hideLoading();
          handleCloseTextModel();
        }
      }

      const handleVideoFileChange = async(event) => {
        const file = event.target.files[0];
        if (file) {
          // Call the onUpload function passed as a prop to handle the file upload
          await handelAddVideoFromDevice(file);
        }
      };

      const handleUpload = async (file) => {
        showLoading();
        const formData = new FormData();
        formData.append('file', file);
    
        try {
          const response = await fetch('https://samrt-loader.com/kydwon/api/upload', {
            method: 'POST',
            headers: {
              'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
            },
            body: formData,
          });
    
          if (response.ok) {
            const data = await response.json();
           // alert('Image uploaded successfully!');
            console.log('Uploaded data:', data);
            if(data.success){
              try {
                const fileToken = data.filetoken;
                const formData = new FormData();
                formData.append('process_orgenal_audio_file', fileToken);
                const response2 = await fetch(`https://samrt-loader.com/kydwon/api/edit/process_video_editer/${selectedVideoID}`, {
                  method: 'POST',
                  headers: {
                    'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
                  },
                  body: formData,
                });
                const data2 = await response2.json();
                if(data2.success) {
                  if(data2.process_video_editer.process_orgenal_audio_file.name) {
                    setTimeout(() => {
                      const mp3Url = `https://samrt-loader.com/kydwon/files/${data2.process_video_editer.process_orgenal_audio_file.name}`
                      setUserAudioURL(mp3Url);
                      hideLoading();
                      showAlert({
                        text: language === 'ar' ? 'تم إضاقة الصوت بنجاح' : 'Audio added success',
                        icon: 'success',
                        position: language === 'ar' ? 'top-end' : 'top-start'
                      });
                    }, 3000); // Check again after 3 seconds
                  } else {
                    hideLoading();
                    showAlert({
                      text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, قد يكون ملف غير صالح أو حجم كبير بالرجاء المحاولة في ملف اخر.' : 'Error uploading audio,Your file maybe incruptud or big size Please try another file.',
                      icon: 'error',
                      position: language === 'ar' ? 'top-end' : 'top-start'
                    });
                  }
                }
              } catch (error) {
                console.error('Error uploading image:', error);
                hideLoading();
                showAlert({
                  text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, قد يكون ملف غير صالح أو حجم كبير بالرجاء المحاولة في ملف اخر.' : 'Error uploading audio,Your file maybe incruptud or big size Please try another file.',
                  icon: 'error',
                  position: language === 'ar' ? 'top-end' : 'top-start'
                });
              }
            }
          } else {
            hideLoading();
            showAlert({
              text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, الرجاء المحاولة لاحقا.' : 'Error uploading audio, Please try again later.',
              icon: 'error',
              position: language === 'ar' ? 'top-end' : 'top-start'
            });
          }
        } catch (error) {
          console.error('Error uploading image:', error);
          hideLoading();
          showAlert({
            text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, الرجاء المحاولة لاحقا.' : 'Error uploading audio, Please try again later.',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        }
      };

    const handleOpenEditerBox = async(type = "") => {
        setIsAddEditer(type === "add");
        try{
            setOpenProjectListDialog(true);
            setLoadingEditerBox(true);
            const response = await fetch(`https://samrt-loader.com/kydwon/api/list/projects_video_editer`, {
              method: 'GET',
              headers: {
                'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
              }
            });
            const data = await response.json();
            if(data.success){
                setEditerBoxData(data.projects_video_editer);
            } else {
                handleCloseProjectList();
                showAlert({
                    text: language === 'ar' ? 
                      'حدث خطأ أثناء تحميل معلومات الحساب, الرجاء تسجيل الدخول' : 
                      'An error occurred while getting your account data, Please try login again',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                  });
                  //localStorage.setItem('authToken', null);
                  //localStorage.setItem('isLogin', 'false');
                  //localStorage.setItem('userid', null);
                  //localStorage.setItem('username', null);
                  //localStorage.setItem('userlevelid', null);  
            }
        } catch(error) {
            handleCloseProjectList();
            showAlert({
                text: language === 'ar' ? 
                  'حدث خطأ أثناء تحميل معلومات الحساب, الرجاء تسجيل الدخول' : 
                  'An error occurred while getting your account data, Please try login again',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start',
              });
              //localStorage.setItem('authToken', null);
              ///localStorage.setItem('isLogin', 'false');
              //localStorage.setItem('userid', null);
              //localStorage.setItem('username', null);
              //localStorage.setItem('userlevelid', null);  
        } finally {
            setLoadingEditerBox(false);
        }
    };

    const handleChooseAdding = async(projectID) => {
      var VideoUrl = localStorage.getItem('VideoUrl')  ;
      if(VideoUrl === null || VideoUrl === undefined || VideoUrl === "") {
        handleCloseProjectList();
        showAlert({
          text: language === 'ar' ? 
            'الرجاء إدخال رابط للإضافة' : 
            'Please enter url first !',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start',
        });
        return;
      }
      try {
        var done = false;
        var statusLimit = false;
        showLoading();
        setIsAllowAdd(false);
        const editerType = localStorage.getItem('prossessType');
        const formData = new FormData();
        formData.append('video_url', VideoUrl);
        formData.append('src_url', orginUrl);
        formData.append('project_id', projectID);
        formData.append('editer_type', editerType);
        
        const response = await fetch(`https://samrt-loader.com/kydwon/api/addtoedit_prossess`, {
            method: 'POST',
            headers: {
                'X-Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            body: formData,
        });

        if (response.status === 204) {
            // Handle the 204 status code
            done = false;
            statusLimit = true;
        } else {
            // Handle other response statuses
            const data = await response.json();
            if (data.success) {
                done = true;
            } else {
                // Handle the case where `success` is false
                showAlert({
                    text: language === 'ar' ? 
                        'حدث خطأ أثناء الإضافة الرجاء المحاولة فيديو اخر لايزيد عن 1 دقيقة' : 
                        'An error occurred while adding, Please try login again',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                });
            }
        }
    } catch (error) {
        showAlert({
            text: language === 'ar' ? 
                'حدث خطأ أثناء الإضافة الرجاء المحاولة فيديو اخر لايزيد عن 1 دقيقة' : 
                'An error occurred while adding, Please try login again',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start',
        });
    } finally {
        hideLoading();
        setOpenProjectListDialog(false);
        setOpenDownloadUrlModle(false);
        localStorage.setItem('prossessType',"normal");
        localStorage.setItem('VideoUrl',"");
        if (done) {
            showAlert({
                text: language === 'ar' ? 
                    'تمت الإضافة بنجاح' : 
                    'Adding successful',
                icon: 'success',
                position: language === 'ar' ? 'top-end' : 'top-start',
            });
            fetchVideos();
            setVideoData([]);
            setIsAllowAdd(false);
        } else {
            if(statusLimit){
                showAlert({
                    text: language === 'ar' ? 
                        'لقد وصلت للحد الأقصى للفيديوهات لهذه القائمة' : 
                        'You reached the video limit to this list',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                });
            } else {
                showAlert({
                    text: language === 'ar' ? 
                        'حدث خطأ أثناء الإضافة الرجاء المحاولة فيديو اخر لايزيد عن 1 دقيقة' : 
                        'An error occurred while adding, Please try login again',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                });
            }
        }
    }
    }

    const handleChoose = async(projectID) => {
        localStorage.setItem('project_id',projectID);
        setSelectedFrame(null);
        setVideos([]);
        setCutRanges({});
        setThumbnails([]);
        setSelectedVideoID(0);
        setSelectedVideo(null);
        setSelectedTime(0);
        setIsProjectId(true);
        fetchVideos();   
    }

    const handleMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleSecMenuClick = (event) => {
      setanchorEl_sec(event.currentTarget);
    };

    const handleCloseDeleteVideoBox = () => {
      setOpenDeleteVideoBox(false);
    };

    const handelDeleteVideoList = async() => {
      setanchorEl_sec(false);
      setOpenDeleteVideoBox(true);
    };

    const handleAddNewEditerBox = async() => {
      try{
          var done = false;
          setLoadingAddEditBox(true);
          const formData = new FormData();
          formData.append('project_by_user_id', localStorage.getItem('userid'));
          formData.append('projject_name', projectName);
          formData.append('project_start_date', "01-01-2024");
          const response = await fetch(`https://samrt-loader.com/kydwon/api/add/projects_video_editer`, {
            method: 'POST',
            headers: {
              'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
            },
            body: formData,
          });
          const data = await response.json();
          if(data.success){
              done = true;
          } else {
              showAlert({
                  text: language === 'ar' ? 
                    'حدث خطأ أثناء الإضافة الرجاء المحاولة لاحقا' : 
                    'An error occurred while adding, Please try login again',
                  icon: 'error',
                  position: language === 'ar' ? 'top-end' : 'top-start',
                });
          }
      } catch(error) {
          showAlert({
              text: language === 'ar' ? 
                'حدث خطأ أثناء الإضافة الرجاء المحاولة لاحقا' : 
                'An error occurred while adding, Please try login again',
              icon: 'error',
              position: language === 'ar' ? 'top-end' : 'top-start',
            });
      } finally {
          setLoadingAddEditBox(false);
          handleCloseAddEditerModal();
          if(done){
              showAlert({
                  text: language === 'ar' ? 
                    'تمت الإضافة بنجاح' : 
                    'Adding successful',
                  icon: 'success',
                  position: language === 'ar' ? 'top-end' : 'top-start',
                });
                handleOpenEditerBox("");
          }
      }
    };

    const fetchVideos = async () => {
        try {
            localStorage.setItem('VideoUrl',"") ;
            localStorage.setItem('prossessType',"") ;
            const token = localStorage.getItem('authToken');        
            const response = await axios.get(
                `https://samrt-loader.com/kydwon/api/list/process_video_editer?showmaster=projects_video_editer&fk_project_id=${localStorage.getItem('project_id')}&order=process_id&ordertype=ASC`,
                {
                    headers: {
                        'X-Authorization': `Bearer ${token}` // Add JWT token to the request headers
                    }
                }
            );
            if (response.data.success) {
                const videoData = response.data.process_video_editer;
                const totalEmptyVideos = 4 - response.data.totalRecordCount;
                const newItemsArray = Array.from({ length: totalEmptyVideos }, (_, index) => index + 1);
                setEmptyVideosListArray(newItemsArray);
                setEmptyVideosListCount(totalEmptyVideos);
                setVideos(videoData);
            }
        } catch (error) {
            console.error('Error fetching videos:', error);
        } finally {
            hideLoading();
            handleCloseProjectList();
        }
    };

    const handelIsPlay = (isPlaying,audioRef) => {
          setIsPlaySound(isPlaying);
          setAudioRef(audioRef);
    }

    const handelOpenPriviewLocalVideoDialog = async(videoName,projectID,prossessID) => {
      setPreviewLocalVideoUrl(`https://samrt-loader.com/kydwon/files/${videoName}`);
      localStorage.setItem('prossessType',"addNew")
      localStorage.setItem('VideoUrl',`https://samrt-loader.com/kydwon/files/${videoName}`)
      setCurrentProssessID(prossessID);
      setShowLocalVideoEditerDialog(true);
    }

    const handelCloseLocalVideoEditerModel = async() => {
      setShowLocalVideoEditerDialog(false); 
      localStorage.setItem('prossessType','canceld');
      await processAddedVideo();
    }
    
    const processAddedVideo = async() => {
      try {
        var done = false;
        var statusLimit = false;
        const projectID = localStorage.getItem('project_id');
        const formData = new FormData();
        const previewLocalVideoType = localStorage.getItem('prossessType');
        const previewLocalVideoUrlSet = localStorage.getItem('VideoUrl');
        if(previewLocalVideoType === 'canceld') {
          showLoading();
        } else {
          setShowLocalVideoEditerDialog(false); 
          showLoading();
        }
        //formData.append('video_url', `https://samrt-loader.com/kydwon/files/${videoName}`);
        formData.append('video_url', previewLocalVideoUrlSet);
        formData.append('src_url', "https://samrt-loader.com");
        formData.append('project_id', projectID);
        formData.append('prossess_id', currentProssessID);
        formData.append('editer_type', previewLocalVideoType);

        const response = await fetch(`https://samrt-loader.com/kydwon/api/addtoedit_prossess`, {
            method: 'POST',
            headers: {
                'X-Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            body: formData,
        });

        if (response.status === 204) {
            // Handle the 204 status code
            done = false;
            statusLimit = true;
        } else {
            // Handle other response statuses
            const data = await response.json();
            if (data.success) {
                done = true;
            } else {
                // Handle the case where `success` is false
                showAlert({
                    text: language === 'ar' ? 
                        'حدث خطأ أثناء الإضافة الرجاء المحاولة فيديو اخر لايزيد عن 1 دقيقة' : 
                        'An error occurred while adding, Please try login again',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                });
            }
        }
    } catch (error) {
        showAlert({
            text: language === 'ar' ? 
                'حدث خطأ أثناء الإضافة الرجاء المحاولة فيديو اخر لايزيد عن 1 دقيقة' : 
                'An error occurred while adding, Please try login again',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start',
        });
    } finally {
        hideLoading();
        if (done) {
          const previewLocalVideoType = localStorage.getItem('prossessType');
          if(previewLocalVideoType === 'canceld') {
            showAlert({
              text: language === 'ar' ? 'تم إلغاء الفيديو' : 'Video cancelled success',
              icon: 'success',
              position: language === 'ar' ? 'top-end' : 'top-start'
            });
          } else {
            fetchVideos();
            showAlert({
              text: language === 'ar' ? 'تم إضاقة الفيديو بنجاح' : 'Video added success',
              icon: 'success',
              position: language === 'ar' ? 'top-end' : 'top-start'
            });
          }
        } else {
            if(statusLimit){
                showAlert({
                    text: language === 'ar' ? 
                        'لقد وصلت للحد الأقصى للفيديوهات لهذه القائمة' : 
                        'You reached the video limit to this list',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                });
            } else {
                showAlert({
                    text: language === 'ar' ? 
                        'حدث خطأ أثناء الإضافة الرجاء المحاولة فيديو اخر لايزيد عن 1 دقيقة' : 
                        'An error occurred while adding, Please try login again',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                });
            }
        }
    }
    }

    const handelAddVideoFromUrl = () => {
      handleMenuClose();
      //navigate(`../`); // Navigate to the ViewRest component
      handleOpenDownloadUrlModel();
    };

    const validateUrl = (url) => {
      const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
        '(\\:\\d+)?' + // port
        '(\\/[-a-z\\d%_.~+@]*)*' + // path
        '(\\?[;&a-z\\d%_.~+=-@]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
        'i'
      );
      return !!urlPattern.test(url);
    };

    const BpIcon = styled('span')(({ theme }) => ({
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
        ...theme.applyStyles('dark', {
          backgroundColor: '#30404d',
        }),
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
        ...theme.applyStyles('dark', {
          background: 'rgba(57,75,89,.5)',
        }),
      },
      ...theme.applyStyles('dark', {
        boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
        backgroundColor: '#394b59',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
      }),
    }));
    
    const BpCheckedIcon = styled(BpIcon)({
      backgroundColor: '#137cbd',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&::before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    });

    // Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

    const handelAddVideoFromDevice = async(file) => {
      handleMenuClose();
      showLoading();
      const formData = new FormData();
      formData.append('file', file);
  
      try {
        const response = await fetch('https://samrt-loader.com/kydwon/api/upload', {
          method: 'POST',
          headers: {
            'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
          },
          body: formData,
        });
  
        if (response.ok) {
          const data = await response.json();
         // alert('Image uploaded successfully!');
          console.log('Uploaded data:', data);
          if (data?.success && data.files?.file?.success) {
            try {
              const fileToken = data.filetoken;
              const formData = new FormData();
              const projectID = localStorage.getItem('project_id');
              formData.append('process_by_user_id', localStorage.getItem('userid'));
              formData.append('by_project_id', projectID);
              formData.append('process_orginal_video_url', "https://samrt-loader.com");
              formData.append('process_src_url', "https://samrt-loader.com");
              formData.append('process_orgenal_video_file', fileToken);
              formData.append('process_orgenal_audio_file', "");
              formData.append('process_face_swap_image_file', "");
              formData.append('process_video_frames', "addNew");
              formData.append('process_video_thumbnail_url', "addNew");
              const response2 = await fetch(`https://samrt-loader.com/kydwon/api/add/process_video_editer?showmaster=projects_video_editer&fk_project_id=${projectID}`, {
                method: 'POST',
                headers: {
                  'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
                },
                body: formData,
              });
              const data2 = await response2.json();
              if(data2.success) {
                if(data2.process_video_editer.process_orgenal_video_file.name) {
                  setTimeout(async () => {
                    await handelOpenPriviewLocalVideoDialog(data2.process_video_editer.process_orgenal_video_file.name,projectID,data2.process_video_editer.process_id)
                    //await processAddedVideo(data2.process_video_editer.process_orgenal_video_file.name,projectID,data2.process_video_editer.process_id)
                  }, 3000); // Check again after 3 seconds
                } else {
                  hideLoading();
                  showAlert({
                    text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, قد يكون ملف غير صالح أو حجم كبير بالرجاء المحاولة في ملف اخر.' : 'Error uploading audio,Your file maybe incruptud or big size Please try another file.',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start'
                  });
                }
              }
            } catch (error) {
              console.error('Error uploading image:', error);
              hideLoading();
              showAlert({
                text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, قد يكون ملف غير صالح أو حجم كبير بالرجاء المحاولة في ملف اخر.' : 'Error uploading audio,Your file maybe incruptud or big size Please try another file.',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start'
              });
            }
          } else {
            hideLoading();
            showAlert({
              text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, قد يكون ملف غير صالح أو حجم كبير بالرجاء المحاولة في ملف اخر.' : 'Error uploading audio,Your file maybe incruptud or big size Please try another file.',
              icon: 'error',
              position: language === 'ar' ? 'top-end' : 'top-start'
            });
          }
        } else {
          hideLoading();
          showAlert({
            text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, الرجاء المحاولة لاحقا.' : 'Error uploading audio, Please try again later.',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        hideLoading();
        showAlert({
          text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, الرجاء المحاولة لاحقا.' : 'Error uploading audio, Please try again later.',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
      }
    }

    const handleDeletImageBox = async () => {
      showLoading();
      try {
        const formData = new FormData();
        formData.append('process_id', selectedVideoID);
        const response2 = await fetch(`https://samrt-loader.com/kydwon/api/deleteimage`, {
          method: 'POST',
          headers: {
            'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
          },
          body: formData,
        });
        const data2 = await response2.json();
        console.log('Uploaded data:', data2);
        if(data2.success) {
          setBackgroundUrl('');
          showAlert({
            text: language === 'ar' ? 'تم حذف الصورة بنجاح' : 'Background delete success',
            icon: 'success',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        showAlert({
          text: language === 'ar' ? 'حدث خطأ أثناء الحذف, الرجاء المحاولة لاحقا.' : 'Error deleting audio, Please try again later.',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
      } finally {
        hideLoading();
      }
    }

    const handleDeletAudioBox = async () => {
      if (isPlaySound) {
        audioRef.current.pause();
      }
      setLoadingEditerAudioBox(true);
      try {
        const formData = new FormData();
        formData.append('process_id', selectedVideoID);
        const response2 = await fetch(`https://samrt-loader.com/kydwon/api/deletesound`, {
          method: 'POST',
          headers: {
            'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
          },
          body: formData,
        });
        const data2 = await response2.json();
        console.log('Uploaded data:', data2);
        if(data2.success) {
          setUserAudioURL('');
          showAlert({
            text: language === 'ar' ? 'تم حذف الصوت بنجاح' : 'Audio delete success',
            icon: 'success',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        }
      } catch (error) {
        console.error('Error uploading sound:', error);
        showAlert({
          text: language === 'ar' ? 'حدث خطأ أثناء الحذف, الرجاء المحاولة لاحقا.' : 'Error deleting audio, Please try again later.',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
      } finally {
        setLoadingEditerAudioBox(false);
      }
    }

    const handleDeletVideoBox = async () => {
      try{
        handleCloseDeleteVideoBox();
        showLoading();
        var done = false;
        const formData = new FormData();
        formData.append('key_m[]', selectedVideoID);
        const response = await fetch(`https://samrt-loader.com/kydwon/api/delete/process_video_editer`, {
          method: 'POST',
          headers: {
            'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
          },
          body: formData,
        });
        const data = await response.json();
        if(data.success){
            done = true;
        } else {
            showAlert({
                text: language === 'ar' ? 
                  'حدث خطأ أثناء الحذف الرجاء المحاولة لاحقا' : 
                  'An error occurred while deleting, Please try login again',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start',
              });
        }
    } catch(error) {
        showAlert({
            text: language === 'ar' ? 
              'حدث خطأ أثناء الحذف الرجاء المحاولة لاحقا' : 
              'An error occurred while deleting, Please try login again',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start',
          });
    } finally {
        hideLoading();
        if(done){
            showAlert({
                text: language === 'ar' ? 
                  'تمت الحذف بنجاح' : 
                  'Deleting successful',
                icon: 'success',
                position: language === 'ar' ? 'top-end' : 'top-start',
              });
              setSelectedVideo(null);
              setSelectedFrame(null);
              fetchVideos();
        }
    }
    }

    useEffect(() => {
      // Call API immediately when component mounts
      handleReqStieData();
      // Set interval to fetch site data every 30 minutes
      const intervalId = setInterval(() => {
        handleReqStieData();
      }, 10 * 60 * 1000); // 10 minutes in milliseconds
  
      // Cleanup the interval when component unmounts
      return () => clearInterval(intervalId);
    }, [handleReqStieData]);
    
    useEffect(() => {
      const newItemsArray = Array.from({ length: 4 }, (_, index) => index + 1);
      setEmptyVideosListArray(newItemsArray);
      setEmptyVideosListCount(4);
        if(IsLogin){
            if(isProjectId){
                showLoading();
                fetchVideos();
                if(containerRef.current){
                  const imageBounds = containerRef.current.getBoundingClientRect(); // Assuming you have a ref to the image/video element
                  const updatedPosition = {
                    x: Math.min(Math.max(0, textPosition.x), imageBounds.width - 100), // Adjust '100' as per text box width
                    y: Math.min(Math.max(0, textPosition.y), imageBounds.height - 50), // Adjust '50' as per text box height
                  };
                  setCurrentPosition(updatedPosition);
                }
            } else {
                handleOpenEditerBox("");
            }
        }else {
            setOpenLoginDialog(true);
        }
        window.scrollTo(0, 0);
    }, [textPosition, containerRef]);

    const handleClose = () => {
        setOpenLoginDialog(false);
        navigate('../');
    }

    const handleCloseProjectList = () => {
        setOpenProjectListDialog(false);
    }

    const handleCloseSoundModel = () => {
      setOpenSoundModle(false);
    }

    const handleOpenSoundModel = () => {
      setanchorEl_sec(false);
      setOpenSoundModle(true);
    }

    const handleSelectVideo = async(video) => {
        setSelectedVideo(video);
        setSelectedVideoID(video.process_id);
        setSelectedFrame(null);
        setLoadingEditerAudioBox(true);
        var isloaded = false;
        try{
          const response = await fetch(`https://samrt-loader.com/kydwon/api/view/process_video_editer/${video.process_id}`, {
            method: 'GET',
            headers: {
              'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
            }
          });
          const data = await response.json();
          if(data.success) {
            const videoHeight = data.process_video_editer.process_video_height;
            const videoWidth = data.process_video_editer.process_video_width;
            setVideoDimensions({ height:videoHeight,width:videoWidth });
            if(data.process_video_editer.process_video_text !== null) {
              setUserText(data.process_video_editer.process_video_text);
              setVideoTextSize(data.process_video_editer.process_video_text_size);
              setVideoTextColor(data.process_video_editer.process_video_text_color);
              setTextPosition({x:data.process_video_editer.process_video_text_pos_x,y:data.process_video_editer.process_video_text_pos_y});
            } else {
              setUserText("");
              setVideoTextSize(10);
              setVideoTextColor("");
              setTextPosition({ x: -73, y: -329 });
              setIsShowText(false);
            }
            if(data.process_video_editer.process_emoji_files !== null) {
              const imojyFileUrl = `https://samrt-loader.com/emoji/${data.process_video_editer.process_emoji_files.name}`
              setSelectedEmoji(imojyFileUrl);
              setEmojyPosX(100);
              setEmojyPosY(100);
              setEmojyHeight(data.process_video_editer.process_emoji_height);
              setEmojyWidth(data.process_video_editer.process_emoji_width);     
              setEmojyRotation(data.process_video_editer.process_emoji_rotation);         
            } else {
              setSelectedEmoji(null);
            }

            if(data.process_video_editer.process_orgenal_audio_file !== null) {
              const mp3Url = `https://samrt-loader.com/kydwon/files/${data.process_video_editer.process_orgenal_audio_file.name}`
              setUserAudioURL(mp3Url);
            } else {
              setUserAudioURL('');
            }
            if(data.process_video_editer.process_face_swap_image_file !== null) {
              setBackgroundUrl(data.process_video_editer.process_face_swap_image_file.url);
            } else {
              setBackgroundUrl('');
            }
            isloaded = true;
          } else {
            setUserAudioURL('');
            setBackgroundUrl('');
          }
        } catch(error){
          setUserAudioURL('');
        } finally {
          setLoadingEditerAudioBox(false);
          if(isloaded) {
            setSelectedFrame(true);
          }
        }
    };

    const handleSelectFrame = (frame, index) => {
        setSelectedFrame(frame);
        handleSelectTime(frame.time); // Pass the index to calculate time
        setSliderValue((frame.time / videoDuration) * 100);  // Adjust the slider value
        //videoElement.current.currentTime = thumb.time;
        const image = new window.Image();
        image.src =  frame.imageSrc;
        setCurrentFrame(image);
        if(userText !== "") {
          setTimeout(() => {
            setIsShowText(true);
          },1000);
        }
    };

    const handleEdit = async(projectID) => {
        
    };

    const handleGenerateAddSoundProcess = () => {
      fileInputRef.current.click();
    };

    const handleGenerateAddBackGroundProcess = () => {
      imagefileInputRef.current.click();
    };

    const handleGenerateAddVideoProcess = () => {
      videofileInputRef.current.click();
    };

    const handleCutRangeChange = (videoId, field, value) => {
      setCutRanges((prev) => {
          const currentRange = prev[videoId] || {};
  
          if (field === 'startTime') {
              // Ensure startTime is less than endTime (if already set)
              if (currentRange.endTime && value >= currentRange.endTime) {
                  showAlert({
                      text: language === 'ar' ? 
                        'يجب أن يكون وقت البداية أقل من وقت النهاية' : 
                        'Start time must be less than the end time',
                      icon: 'error',
                      position: language === 'ar' ? 'top-end' : 'top-start',
                    });
                  return prev; // Do not update if invalid
              }
          } else if (field === 'endTime') {
              // Ensure endTime is greater than startTime (if already set)
              if (currentRange.startTime && value <= currentRange.startTime) {
                  showAlert({
                      text: language === 'ar' ? 
                        'يجب أن يكون وقت النهاية أكبر من وقت البداية' : 
                        'End time must be greater than the start time',
                      icon: 'error',
                      position: language === 'ar' ? 'top-end' : 'top-start',
                    });
                  return prev; // Do not update if invalid
              }
          }
  
          // Update the cut range if validation passes
          return {
              ...prev,
              [videoId]: {
                  ...currentRange,
                  [field]: value
              }
          };
      });
  };

    const handleGenerateProcess = async (processType) => {
      try {
          if (processType === 'cut_video') {
            if(isAllFramesSelected()){
              if(chackAllDeuration()) {
              const selectedCutRanges = Object.entries(cutRanges).map(([videoId, cutRange]) => ({
                process_type: 'cut_video',
                videoId,
                startTime: cutRange.startTime,
                endTime: cutRange.endTime
            }));
            showLoading();
            const response = await axios.post('https://webapppros.com/process_video_editer', {
              videos: selectedCutRanges,
            });
            if (response.data.success) {
                checkStatus(response.data.task_id,processType);
            } else {
                showAlert({
                  text: language === 'ar' ? 'فشل الإنشاء الرجاء المحاولة لاحقا' : 'Error starting the processing, Please try again later !',
                  icon: 'error',
                  position: language === 'ar' ? 'top-end' : 'top-start'
                });
            }
          }else {
            showAlert({
              text: language === 'ar' ? 'جميع المقاطع في القائمة يجب أن لاتزيد مدة القص أكثر من 10 ثوان لكل فيديو' : 'All clips in the list must not exceed 10 seconds for each video.!',
              icon: 'error',
              position: language === 'ar' ? 'top-end' : 'top-start'
            });
            return;
          }
          } else {
            showAlert({
              text: language === 'ar' ? 'الرجاء تحديد إطار الوقت لجميع الفيديوهات في القائمة الجانبية أولا !' : 'Please specify the time frame for all videos in the sidebar!',
              icon: 'error',
              position: language === 'ar' ? 'top-end' : 'top-start'
            });
          }
          } else {
            handleCloseStickerModel();
            const video = cutRanges[selectedVideoID];  // Assuming single video for gif conversion
            if(video.startTime > -1 && video.endTime > 0 && video.startTime !== '' && video.startTime !== null && !isNaN(video.startTime) && video.startTime !== undefined && video.endTime !== '' && video.endTime !== null && !isNaN(video.endTime) && video.endTime !== undefined && stickerGenrateType === 'sticker') {
              const viledDuretion = video.endTime - video.startTime;
              if(viledDuretion > 5){
                showAlert({
                  text: language === 'ar' ? 'فشل الإنشاء, يجب أن لايزيد مدة الستيكر عن 5 ثوان' : 'Error starting the processing, sticker duration must not exceed 5 seconds',
                  icon: 'error',
                  position: language === 'ar' ? 'top-end' : 'top-start'
                });
                return;
              }
              showLoading();
              const response = await axios.post('https://webapppros.com/process_video_editer', {
                process_type: 'video_to_gif',
                remove_back_ground: removeBackGround,
                video_id: selectedVideoID,  // Replace with actual video ID
                startTime: video.startTime,  // Start time in seconds
                endTime: video.endTime,  // End time in seconds
                gen_type: stickerGenrateType
              });
              if (response.data.success) {
                checkStatus(response.data.task_id,processType);
              } else {
                showAlert({
                  text: language === 'ar' ? 'فشل الإنشاء الرجاء المحاولة لاحقا' : 'Error starting the processing, Please try again later !',
                  icon: 'error',
                  position: language === 'ar' ? 'top-end' : 'top-start'
                });
               }
              } else if(stickerGenrateType === 'photo'){   
                if(video.startTime > -1 && video.startTime !== '' && video.startTime !== null && !isNaN(video.startTime) && video.startTime !== undefined) {
                  showLoading();
                  const response = await axios.post('https://webapppros.com/process_video_editer', {
                    process_type: 'video_to_gif',
                    remove_back_ground: removeBackGround,
                    video_id: selectedVideoID,  // Replace with actual video ID
                    startTime: video.startTime,  // Start time in seconds
                    endTime: (video.startTime + 0.1),  // End time in seconds
                    gen_type: stickerGenrateType
                  });
                  if (response.data.success) {
                    checkStatus(response.data.task_id,processType);
                  } else {
                    showAlert({
                      text: language === 'ar' ? 'فشل الإنشاء الرجاء المحاولة لاحقا' : 'Error starting the processing, Please try again later !',
                      icon: 'error',
                      position: language === 'ar' ? 'top-end' : 'top-start'
                    });
                   }
                } else {
                  showAlert({
                    text: language === 'ar' ? 'الرجاء تحديد إطار الوقت للصورة التي تريد إنشائها من شريط الوقت !' : 'Please specify the time frame for the genrated photo from timeline !',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start'
                  });
                }
            } else {
              showAlert({
                text: language === 'ar' ? 'الرجاء تحديد إطار الوقت للفيديو المحدد في القائمة الجانبية أولا !' : 'Please specify the time frame for all videos in the sidebar!',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start'
              });
            }
          }
      } catch (error) {
          console.error('Error sending data to backend:', error);
          showAlert({
            text: language === 'ar' ? 'حدث خطأ الرجاء التأكد من تحديد الوقت لكل فيديو وإعادة المحاولة أو المحاولة لاحقا' : 'An error, Please specify the time frame for all videos in the sidebar or try again later!',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
      } finally {
          setStickerGenrateType('');
          hideLoading();
      }
    };

    const chackAllDeuration = () => {
      let allFramesSelected = true;
      videos.forEach((video) => {
        const videoId = video.process_id; // Assuming each video object has an 'id'
        const cutRange = cutRanges[videoId]; // Get the cut range for this video
        let deu = cutRange.endTime - cutRange.startTime ;
        if (deu > 10) {
          allFramesSelected = false;
        }
      });
      return allFramesSelected;
    };

    const isAllFramesSelected = () => {
      let allFramesSelected = true;
    
      videos.forEach((video) => {
        const videoId = video.process_id; // Assuming each video object has an 'id'
        const cutRange = cutRanges[videoId]; // Get the cut range for this video
    
        if (!cutRange || !Object.values(cutRange).every(
          (value) => value !== '' && value !== null && !isNaN(value) && value > -1 && value !== undefined
        )) {
          allFramesSelected = false;
        }

      });
    
      return allFramesSelected;
    };

    const checkStatus = async (taskId,type) => {
        setLoadingViewBox(true);
        setOpenViewModal(true);
        const statusResponse = await fetch(`https://webapppros.com/task_status_editer/${taskId}`);
        const statusData = await statusResponse.json();
    
        if (statusData.status === 'PENDING') {
          setTimeout(() => checkStatus(taskId), 5000); // Check again after 5 seconds
        } else if (statusData.status === 'PROGRESS') {
          const currrentProssessFileNo = statusData.current_file;
          const totalFiles = statusData.total_files;
          setProssessFilesInfo({currentFile:currrentProssessFileNo,totalFiles:totalFiles});
          setTimeout(() => checkStatus(taskId), 5000); // Check again after 5 seconds
        } else if (statusData.status === 'SUCCESS') {
          const url = statusData.result;
          setResultViewBox(url);
          setGenProcessType(type);
          setLoadingViewBox(false);
          setProssessFilesInfo({currentFile:0,totalFiles:0});
          //console.error('success path', url);
        } else {
          console.error('Error processing video:', statusData.status);
          setOpenViewModal(false);
          setProssessFilesInfo({currentFile:0,totalFiles:0});
          showAlert({
            text: language === 'ar' ? 'فشل الإنشاء الرجاء المحاولة لاحقا' : 'Error starting the processing, Please try again later !',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        }
      };

    const handleSelectTime = (frameIndex) => {
        const currentTime = frameIndex ; // Assuming frame 1 corresponds to 1 second, frame 2 to 2 seconds, etc.
        setSelectedTime(currentTime);
        const field = settingStart ? 'startTime' : 'endTime';
        handleCutRangeChange(selectedVideoID, field, currentTime);
        setSettingStart(!settingStart); // Toggle between start and end time
    };

    const handleCloseViewModal = () => {
        setOpenViewModal(false);
    }

    const handelOpenStickerModle = (type) => {
      setStickerGenrateType(type);
      setanchorEl_sec(false);
      setOpenStickerModle(true);
    }

    const handleOpenEmojiModel = () => {
      setanchorEl_sec(false);
      setOpenEmojiModle(true);
    }

    const handelCloseEmojiModel = () => {
      setOpenEmojiModle(false);
    }

    const handelCloseFaceModel = () => {
      setOpenFaceSwapModle(false);
    }

    const handelSelectFaceModle = async(face) => {
      showLoading();
      try {
        const formData = new FormData();
        formData.append('prossess_id', selectedVideoID);
        formData.append('face_file_name', face.name);
        const response2 = await fetch(`https://samrt-loader.com/kydwon/api/addFace`, {
          method: 'POST',
          headers: {
            'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
          },
          body: formData,
        });
        const data2 = await response2.json();
        if(data2.success) {
          showAlert({
            text: language === 'ar' ? 'تم حفظ قناع الوجه, وسيظهر عند إنشاء الفيديو أو الستيكر أو صورة' : 'Face swap photo saved successfull, the result will show after final genrated of selected video or sticker or photo',
            icon: 'success',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        }
      } catch(error) {
        showAlert({
          text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, قد يكون ملف غير صالح أو حجم كبير بالرجاء المحاولة في ملف اخر.' : 'Error uploading sticker ,Your file maybe incruptud or big size Please try another file.',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
      } finally {
        hideLoading();
      }
    }

    const handelSelectEmojiModle = async(emoji) => {
      showLoading();
      try {
        const formData = new FormData();
        formData.append('prossess_id', selectedVideoID);
        formData.append('emoji_file_name', emoji.name);
        const response2 = await fetch(`https://samrt-loader.com/kydwon/api/addEmoji`, {
          method: 'POST',
          headers: {
            'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
          },
          body: formData,
        });
        const data2 = await response2.json();
        if(data2.success) {
          setSelectedEmoji(emoji.imojy_url);
        }
      } catch(error) {
        showAlert({
          text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, قد يكون ملف غير صالح أو حجم كبير بالرجاء المحاولة في ملف اخر.' : 'Error uploading sticker ,Your file maybe incruptud or big size Please try another file.',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
      } finally {
        hideLoading();
      }
    }

    const handleOpenFaceSwapModel = () => {
      setanchorEl_sec(false);
      setOpenFaceSwapModle(true);
    }
    
    const SEO = ({ title, description, keywords }) => (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "${title}",
              "url": "https://samrt-loader.com"
            }
          `}
        </script>
      </Helmet>
    );

    return (
        <div>
        <SEO 
        title={language === 'ar' ? keywordsData.title_editer_ar : keywordsData.title_editer_en}
        description={language === 'ar' ? keywordsData.description_ar : keywordsData.description_en}
        keywords={keywordsData.keywords.join(', ')}
      />
 {IsLogin ? (
            <div className="studio-container">
            <div className="video-list" style={{ backgroundColor: videoListBackgroundColor }}>
                {videos.map((video, index) => (
                    <div key={video.process_id} className="video-thumbnail" onClick={() => handleSelectVideo(video)}>
                        <img src={video.process_video_thumbnail_url} alt={video.process_orgenal_video_file.name} />
                        <p>{`Video - ${index + 1}`}</p>
                    </div>
                ))}
                {emptyVideosListCount > 0 && (
                  emptyVideosListArray.map((index) => (
                    <div key={index} className="video-thumbnail">
                        <PlayCircleOutlineIcon style={{width:'100%',height:isMobile ? 50 : 100,color:'darkgray'}}/>
                        <p>{`epmty Video - ${videos.length + index}`}</p>
                    </div>
                  ))
                )}
            </div>

            <div className="editor-area">
                <div className="action-bar">
                  <Box>
                    <ButtonGroup variant="contained" color="inherit" aria-label="outlined button group">
                        <Button disabled={!selectedVideo || loadingEditerAudioBox} aria-label="video edit options" variant="contained" color="inherit" onClick={handleSecMenuClick}>
                                      {loadingEditerAudioBox ? (
                                <CircularProgress color='error' size={24} />
                            ) : (
                                <EditNoteIcon />
                            )}
                        </Button>
                        <Menu                                           
                          anchorEl={anchorEl_sec}
                          open={Boolean(anchorEl_sec)}
                          onClose={handleSecMenuClose}
                          aria-label='menu'>
                                   <MenuList>
          <MenuItem onClick={handleOpenTextModel}>
            <ListItemIcon>
              <AbcIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{language === 'ar' ? 'إضافة نص' : 'Add text'}</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleOpenBackgroundModel}>
            <ListItemIcon>
              <WallpaperIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{language === 'ar' ? 'إضافة خلفية' : 'Add background'}</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleOpenFaceSwapModel}>
            <ListItemIcon>
              <FaceRetouchingNaturalIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{language === 'ar' ? 'إضافة قناع وجه' : 'Face Swap'}</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleOpenSoundModel}>
            <ListItemIcon>
              <SettingsVoiceIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{language === 'ar' ? 'إضافة صوت' : 'Add sound'}</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleOpenEmojiModel}>
            <ListItemIcon>
              <AddReactionIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{language === 'ar' ? 'إضافة ستيكر' : 'Add sticker'}</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() =>handelOpenStickerModle('sticker')}>
            <ListItemIcon>
              <VideoStableIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{language === 'ar' ? 'إنشاء ستيكر' : 'GIF/Sticker'}</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handelOpenStickerModle('photo')}>
            <ListItemIcon>
              <PortraitIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{language === 'ar' ? 'إنشاء صورة' : 'PNG/Photo'}</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handelDeleteVideoList}>
            <ListItemIcon>
              <DeleteForeverIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{language === 'ar' ? 'حذف من القائمة' : 'Delete from list'}</ListItemText>
          </MenuItem>
      </MenuList>
                        </Menu>
                        <Button disabled={!isProjectId} aria-label="generate" variant="contained" color="inherit" onClick={handleMenuClick}>
                            <VideoCallIcon />
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          aria-label='menu'
                        >
                          <MenuItem onClick={handelAddVideoFromUrl}>{language === 'ar' ? 'إضافة فيديو من رابط' : 'Add video from url'}</MenuItem>
                          <MenuItem onClick={handleGenerateAddVideoProcess}>{language === 'ar' ? 'إضافة فيديو من الجهاز' : 'Add video from device'}</MenuItem>
                          <input
                                  type="file"
                                  accept="video/mp4"
                                  ref={videofileInputRef}
                                  style={{ display: 'none' }}
                                  onChange={handleVideoFileChange}/>
                        </Menu>
                        <Button aria-label="choos project" variant="contained" color="info" onClick={() => handleOpenEditerBox("")}>
                            <PlaylistPlayIcon />
                        </Button>
                        <Button aria-label="generate" variant="contained" color="success" onClick={() => handleGenerateProcess('cut_video')}>
                            {language === 'ar' ? 'إنشاء' : <PlayArrowIcon />}
                        </Button>
                    </ButtonGroup>
                    </Box>
                </div>
                <Box>
                {selectedVideoID > 0 && (
                        <div className="cut-inputs">
                            <CustomTextField
                                label={language === 'ar' ? 'وقت البداية' : 'Start Time (s)'}
                                variant="outlined"
                                value={cutRanges[selectedVideoID]?.startTime || 0}
                                type="number"
                                name="startTime"
                                onChange={(e) => handleCutRangeChange(selectedVideoID, 'startTime', e.target.value)}
                                margin="normal"
                                inputProps={{ dir: language === 'ar' ? 'rtl' : 'ltr' }}
                            />
                            <CustomTextField
                                label={language === 'ar' ? 'وقت النهاية' : 'End Time (s)'}
                                variant="outlined"
                                value={cutRanges[selectedVideoID]?.endTime || ''}
                                type="number"
                                name="endTime"
                                onChange={(e) => handleCutRangeChange(selectedVideoID, 'endTime', e.target.value)}
                                margin="normal"
                                inputProps={{ dir: language === 'ar' ? 'rtl' : 'ltr' }}
                            />
                        </div>
                    )}
                </Box>

                <Box sx={{
                            overflowX: 'auto',
                            whiteSpace: 'nowrap',
                            padding: '10px',
                            backgroundColor: [boxBackgroundColor],
                            top: -0.5,
                            zIndex: 1,
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
                            '&::-webkit-scrollbar': { // Change scrollbar styles
                              width: '8px',
                              height: '8px',
                              backgroundColor: [scrollColor],
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: '#ccc',
                              borderRadius: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                              backgroundColor: [scrollColor],
                            },
                        }}
                        width="100%">   
                              {/* Thumbnails for each frame */}
        {thumbnails.map((thumb, index) => (
          <Box key={index} className="frame" onClick={() => handleSelectFrame(thumb,index)} sx={{ display: 'inline-block', marginRight: 4 }}>
            <img src={thumb.imageSrc} alt={`Frame ${thumb.time}`} />
            <p style={{ textAlign: 'center' }}>{thumb.time}s</p>
          </Box>
        ))}
                
                </Box>
                
<div className="canvas-preview" style={{ backgroundColor: cnavasBackgroundColor }}>
  {selectedFrame ? (
    <div ref={containerRef} style={{ position: 'relative', display: 'inline-block' }}>
      {/* Text input for user to enter the text */}
      <KonvaCanvasEditor userText={userText} videoUrl={`https://samrt-loader.com/kydwon/files/${selectedVideo.process_orgenal_video_file.name}`} textSize={videoTextSize} textColor={videoTextColor} selectedEmoji={selectedEmoji} emojyPosX={emojyPosX} emojyPosY={emojyPosY} emojyHeight={emojyHeight} emojyWidth={emojyWidth} emojyRotation={emojyRotation} videoDimensions={videoDimensions} isMobile={isMobile} handleSaveTextModel={(isMove,x,y) => handleSaveTextModel(isMove,x,y)} handleSaveEmojyModel={(x,y,w,h,r) => handleSaveEmojyModel(x,y,w,h,r)} setThumbnails={(thum) => handleThumbles(thum)} currentFrame={currentFrame} sliderValue={sliderValue} setSliderValue={(t) => setSliderValue(t)} setVideoDuration={(d) => setVideoDuration(d)} />
    </div>
  ) : (
    <div>
    {emptyVideosListCount === 4 ? (
      isProjectId ? (
        <p style={{textAlign:'center',padding:2}}>{language === 'ar' ? 'قم بإضافة فيديو جديد للقائمة من زر إضافة فيديو في الأعلى' : 'Add a new video to the list by adding a video button at the top.'}&nbsp;<VideoCallIcon /></p>
      ) : (
        <p style={{textAlign:'center',padding:2}}>{language === 'ar' ? 'قم بإختيار أو إضافة موضوع جديد من قائمة المحرر في الأعلى للبدء' : 'Select or add a new project from the editer list at top to start.'}&nbsp;<PlaylistPlayIcon /></p>
      )
    )
   : (
    <p style={{textAlign:'center',padding:2}}>{language === 'ar' ? 'إختر إطار لبدء التحديد' : 'Select a frame to preview'}</p>
   )}
    </div>
  )}
</div>

            </div>
            <Dialog open={openViewModal} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'عرض' : 'View'}</DialogTitle>
        <DialogContent textAlign={'center'}>
        {loadingViewBox ? (
          <div>
                    <p style={{textAlign:'center'}}>{ language === 'ar' ? 'معالجة فيديو رقم ' : 'Prossessing file ' } {prossessFilesInfo.currentFile} { language === 'ar' ? 'من ' : 'of ' } {prossessFilesInfo.totalFiles}</p>
                    <span>
                        <CircularProgress size={24} /> {language === 'ar' ? 'جار الإنشاء (قد يستغرق عدة دقائق) ...' : 'Prossesing (it may take awhile) ...'}
                    </span>
          </div>
                ) : (
                  <span>
                  {/* Check if the resultViewBox ends with '.mp4' or '.gif' */}
                  {resultViewBox.endsWith('.mp4') ? (
                    <Box sx={{ width: '80vw', maxWidth: '500px', overflow: 'hidden', margin: 'auto' }}>
                      <ReactPlayer url={resultViewBox} controls width="100%" style={{ maxHeight: '380px' }} />
                    </Box>
                  ) : resultViewBox.endsWith('.gif') ? (
                    <Box sx={{ width: '80vw', maxWidth: '500px', overflow: 'hidden', margin: 'auto' }}>
                      <img src={resultViewBox} alt='sticker photo' width="300px" height={'100%'} style={{ maxHeight: '300px' }} />
                    </Box>
                  ) : resultViewBox.endsWith('.png') ? (
                    <Box sx={{ width: '80vw', maxWidth: '500px', overflow: 'hidden', margin: 'auto' }}>
                      <img src={resultViewBox} alt='shot photo' width="300px" height={'100%'} style={{ maxHeight: '400px' }} />
                    </Box>
                  ) : (
                    <span>
                      {language === 'ar' ? 'الرجاء المحاولة لاحقاً' : 'Please try again later'}
                    </span>
                  )}
                </span>
                )}
        </DialogContent>
        {!loadingViewBox && (
        <DialogActions>
        <Button aria-label={"close"} disabled={loadingViewBox} onClick={handleCloseViewModal} color="secondary">
          {language === 'ar' ? 'إغلاق' : 'Cancel'}
        </Button>

        {isMobile ? (
          <ButtonGroup variant="contained" color="inherit" aria-label="outlined button group">
              <Button disabled={loadingViewBox} aria-label={"download_edit_video"} color="success" href={`https://samrt-loader.com/proxy-video?url=${encodeURIComponent(resultViewBox)}`} download>
                <DownloadIcon />&nbsp;&nbsp;{language === 'ar' ? 'تحميل' : 'Download'}
              </Button> 
              <Button disabled={loadingShare} aria-label={"download_share_edit_video"} color="info" onClick={() => shareVideo(resultViewBox)}>
                    {loadingShare ? (
                      <span>
                        <CircularProgress size={24} />&nbsp;&nbsp;{language === 'ar' ? 'جار التحميل ...' : 'Donloading ...'}
                      </span>
                    ) : (
                      <span>
                        <SendAndArchiveIcon />&nbsp;&nbsp;{language === 'ar' ? 'تحميل و مشاركة' : 'Download & Share'}
                      </span>
                    )}
              </Button> 
          </ButtonGroup>
        ) : (
          <Button disabled={loadingViewBox} aria-label={"download_edit_video"} color="success" href={`https://samrt-loader.com/proxy-video?url=${encodeURIComponent(resultViewBox)}`} download>
            <DownloadIcon />&nbsp;&nbsp;{language === 'ar' ? 'تحميل' : 'Download'}
          </Button> 
        )}
        </DialogActions>
        )}
      </Dialog>

      <Dialog open={openProjectListDialog} onClose={handleCloseProjectList} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'قائمة المحرر' : 'Editer list'}</DialogTitle>
        <DialogContent>
          <DialogContentText textAlign={'center'}>
            {language === 'ar' ? 'إختيار موضوع جديد للمحرر' : 'Choose your projects'}
          </DialogContentText>
          {loadingEditerBox ? (
            <Box width="100%" minWidth={'300px'} my={5} textAlign={'center'}>
                <CircularProgress color='error' size={24} />
            </Box>
    ) : editerBoxData.length > 0 ? (
        <Box sx={{ maxHeight: '350px', overflow: 'auto' }}>
        <List>
          {editerBoxData.map((project) => (
            <React.Fragment key={project.project_id}>
              <ListItem>
                {isAddEditer ? (
                    <ListItemButton onClick={() => handleChooseAdding(project.project_id)}>
                      <ListItemText
                         primary={project.projject_name}
                         secondary={language === 'ar' ? `( ${project.project_video_count} / 4 )` : `( ${project.project_video_count} / 4 )`}
                      />
                    </ListItemButton>
                ) : (
                    <ListItemButton onClick={() => handleChoose(project.project_id)}>
                      <ListItemText
                        primary={project.projject_name}
                        secondary={language === 'ar' ? `( ${project.project_video_count} / 4 )` : `( ${project.project_video_count} / 4 )`}
                      />
                    </ListItemButton>
                )}
                <Tooltip title={language === 'ar' ? 'تعديل' : 'Edit'}>
                  <IconButton edge="end" disabled={true} aria-label="edit" onClick={() => handleEdit(project.project_id)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={language === 'ar' ? 'حذف' : 'Delete'}>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(project.project_id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
        </Box>
      ) : (
        <Typography textAlign={'center'} color="textSecondary">
          {language === 'ar' ? 'لم يتم العثور على مواضيع' : 'No projects found'}
        </Typography>
      )}
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseProjectList} color="primary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button aria-label={"add project"} onClick={handleOpenAddNewEditerBox} color="success">
            {language === 'ar' ? 'إضافة موضوع جديد' : 'Add new project'}
          </Button>
        </DialogActions>
      </Dialog>
        </div>
        ) : (
          <div className="studio-container">
          <Box textAlign={'center'} alignItems={'center'} width={'100%'} my={5} mt={5}>
             <img src="../favpng_video-editing-logo.webp" alt='video editer' width='270px' height="270px"/>
          </Box>
      <Dialog open={openLoginDialog} onClose={handleClose} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'تسجيل الدخول' : 'Login'}</DialogTitle>
        <DialogContent>
          <DialogContentText textAlign={'center'}>
          {language === 'ar' ? 'الرجاء إدخال الإيميل وكلمة المرور , أو المتابعة عبر حساب جوجل لتسجيل الدخول.' : 'Please enter your email and password, or continue with your Google account.'}
            
          </DialogContentText>
          <div>
          <TextField
            autoFocus
            margin="dense"
            label={language === 'ar' ? 'الإيميل' : 'Email Address'}
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
          />
          {!isValid && <p style={{ color: 'red' ,fontSize:'12px'}}>{language === 'ar' ? "الإيميل غير صالح" : "Invalid email address"}</p>}
          </div>
          <TextField
            margin="dense"
            label={language === 'ar' ? 'كلمة المرور' : 'Password'}
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={handlePasswordChange}
          />
          <h3 style={{textAlign:'center'}}>- {language === 'ar' ? 'أو' : 'OR'} -</h3>
          <Button
            fullWidth
            variant="outlined"
            color='info'
            onClick={handleClickOpenReg}
            style={{marginBottom:'8px'}}
          >
              {language === 'ar' ? 'تسجيل حساب جديد' : 'Register new account'}
          </Button>
          <GoogleOAuthProvider clientId="79586729060-s2fhal0cmcnqhdvg9dhkei9f0fr4esvg.apps.googleusercontent.com">
            <CustomGoogleLogin
              lang={language}
            />
          </GoogleOAuthProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button onClick={handleLogin} color="success">            
            {language === 'ar' ? 'تسجيل الدخول' : 'Login'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openReg} onClose={handleCloseReg} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'التسجيل' : 'Register'}</DialogTitle>
        <DialogContent>
          <DialogContentText textAlign={'center'}>
            {language === 'ar' ? 'الرجاء إدخال بيانات حسابك للتسجيل .' : 'Please enter your data to register a new account'}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label={language === 'ar' ? 'إسم المستخدم' : 'User name'}
            type="text"
            fullWidth
            variant="outlined"
            value={name}
            onChange={handleNameChange}
          />
          <div>
          <TextField
            margin="dense"
            label={language === 'ar' ? 'الإيميل' : 'Email Address'}
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
          />
          {!isValid && <p style={{ color: 'red' ,fontSize:'12px'}}>{language === 'ar' ? "الإيميل غير صالح" : "Invalid email address"}</p>}
          </div>
          <TextField
            margin="dense"
            label={language === 'ar' ? 'كلمة المرور' : 'Password'}
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={handlePasswordChange}
          />
          <TextField
            margin="dense"
            label={language === 'ar' ? 'تأكيد كلمة المرور' : 'Confirm Password'}
            type="password"
            fullWidth
            variant="outlined"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseReg} color="primary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button aria-label={"register"} onClick={handleRegister} color="success">
            {language === 'ar' ? 'التسجيل' : 'Register'}
          </Button>
        </DialogActions>
      </Dialog>
      </div>
        )}
        <Box width={'100%'} textAlign={'center'} alignItems={'center'}>
            <Button aria-label='how to use' color='info' onClick={handleOpenHowToEditerDialog}>
                {language === 'ar' ? 'طريقة الإستعمال' : 'How to use'}
            </Button>
        </Box>

      {/* Dialog How to editer */}
      <Dialog open={showHowToEditerDialog} onClose={handleCloseHowToEditerDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'طريقة الإستعمال' : 'How to use'}</DialogTitle>
        <DialogContent>
        <DialogContentText mt={1} my={2}>
            <p>{language === 'ar' ? 'قم بقص وتجميع الفيديوهات المحملة من مختلف المنصات ودمجها في فيديو واحد يناسب جميع منصات التواصل لمشاركته , جربها لان وإستمتع بإنشاء تجميعة مميزة ومشاركتها مع أصدقائك .' : 'Cut and collect videos downloaded from different platforms and merge them into one video that suits all social media platforms to share. Try it now and enjoy creating a unique collection and sharing it with your friends .'}</p>
            <p>{language === 'ar' ? 'تم إضافة خاصية تحويل الفيديو إلى ستيكر GIF متحرك إلى المحرر.' : 'Adding Video to GIF sticker conversion to the editer.'} <Link onClick={handleOpenHowToStickerDialog} href="#" color="inherit" className="animated-link">{language === 'ar' ? 'طريقة الإستخدام' : 'how to use'}</Link></p>
            <p>{language === 'ar' ? 'ملاحظة : نحن لا نحتفظ بالفيديوهات التي قمت بإنشائها أكثر من 12 ساعة , يتم تجديد قائمتك تلقائيا.' : 'Note: We do not keep your generated videos for more than 12 hours, your list is updated automatically.'}</p>
          </DialogContentText>
          <Box mt={1} my={2}>
          <video controls style={{ width: '100%', maxHeight: '380px' }}>
            <source src={"https://samrt-loader.com/how_to_editer.mp4"} type={"video/mp4"} />
            Your browser does not support the video tag.
          </video>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHowToEditerDialog} color="primary">
            {language === 'ar' ? 'إغلاق' : 'got it !'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog How to sticker */}
      <Dialog open={showHowToStickerDialog} onClose={handleCloseHowToStickerDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'طريقة إنشاء ستيكر من الفيديو' : 'How to sticker video'}</DialogTitle>
        <DialogContentText mt={1} my={2}>
            <p>{language === 'ar' ? 'ماهو الستيكر ؟' : 'What is sticker video ?'}</p>
            <p>{language === 'ar' ? 'هو ملصق بصيغة صورة GIF يستخدم في رسائل الواتس اب أو السوشال ميديا في الكومنت التي تدعم الصور المتحركة' : 'It is a sticker in GIF format used in WhatsApp messages or social media in the comments that support animated images.'}</p>
        </DialogContentText>
        <DialogContent>
          <Box mt={1} my={2}>
          <video controls style={{ width: '100%', maxHeight: '380px' }}>
            <source src={"https://samrt-loader.com/how_to_sticker.mp4"} type={"video/mp4"} />
            Your browser does not support the video tag.
          </video>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHowToStickerDialog} color="primary">
            {language === 'ar' ? 'حسنا !' : 'got it !'}
          </Button>
        </DialogActions>
      </Dialog>
      
    
      {/* Dialog How to editer */}
      <Dialog open={showHowToEditerDialog} onClose={handleCloseHowToEditerDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'طريقة الإستعمال' : 'How to use'}</DialogTitle>
        <DialogContent>
        <DialogContentText mt={1} my={2}>
            <p>{language === 'ar' ? 'قم بقص وتجميع الفيديوهات المحملة من مختلف المنصات ودمجها في فيديو واحد يناسب جميع منصات التواصل لمشاركته , جربها لان وإستمتع بإنشاء تجميعة مميزة ومشاركتها مع أصدقائك .' : 'Cut and collect videos downloaded from different platforms and merge them into one video that suits all social media platforms to share. Try it now and enjoy creating a unique collection and sharing it with your friends .'}</p>
            <p>{language === 'ar' ? 'تم إضافة خاصية تحويل الفيديو إلى ستيكر GIF متحرك إلى المحرر.' : 'Adding Video to GIF sticker conversion to the editer.'} <Link onClick={handleOpenHowToStickerDialog} href="#" color="inherit" className="animated-link">{language === 'ar' ? 'طريقة الإستخدام' : 'how to use'}</Link></p>
            <p>{language === 'ar' ? 'ملاحظة : نحن لا نحتفظ بالفيديوهات التي قمت بإنشائها أكثر من 12 ساعة , يتم تجديد قائمتك تلقائيا.' : 'Note: We do not keep your generated videos for more than 12 hours, your list is updated automatically.'}</p>
          </DialogContentText>
          <Box mt={1} my={2}>
          <video controls style={{ width: '100%', maxHeight: '380px' }}>
            <source src={"https://samrt-loader.com/how_to_editer.mp4"} type={"video/mp4"} />
            Your browser does not support the video tag.
          </video>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHowToEditerDialog} color="primary">
            {language === 'ar' ? 'إغلاق' : 'got it !'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog How to Background */}
      <Dialog open={aboutBackDialog} onClose={handelCloseAboutBackgroundModel} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'طريقة إنشاء خلفية للفيديو' : 'How to add background to video'}</DialogTitle>
        <DialogContent>
          <div style={{textAlign:'center',alignItems:'center'}}>
            <img src='https://samrt-loader.com/add_background.png' alt='add background' width={'100%'} height={200}/>
          </div>
          <Box mt={1} my={2}>
          <video controls style={{ width: '100%', maxHeight: '380px' }}>
            <source src={"https://samrt-loader.com/how_to_backgrond.mp4"} type={"video/mp4"} />
            Your browser does not support the video tag.
          </video>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handelCloseAboutBackgroundModel} color="primary">
            {language === 'ar' ? 'حسنا !' : 'got it !'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteVideoBox} onClose={handleCloseDeleteVideoBox} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'حذف الفيديو' : 'Delete video'}</DialogTitle>
        <DialogContent>
            <Typography textAlign={'center'} color="textSecondary">
               {language === 'ar' ? 'سيتم حذف الفيديو من القائمة, هل تريد الحذف ؟' : 'This video will deleted form your list, Are you sure of delete ?'}
            </Typography>
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseDeleteVideoBox} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button aria-label={"delete"} disabled={loadingDeleteVideoBox} onClick={handleDeletVideoBox} color="error">            
            {loadingDeleteVideoBox ? (
                    <span>
                        <CircularProgress size={24} />
                    </span>
                ) : (
                    <span>
                        {language === 'ar' ? 'حذف' : 'Delete'}
                    </span>
                )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteEditerBox} onClose={handleCloseDeleteEditerBox} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'حذف الموضوع' : 'Delete project'}</DialogTitle>
        <DialogContent>
            <Typography textAlign={'center'} color="textSecondary">
               {language === 'ar' ? 'سيتم حذف الموضوع ولن تتمكن من إستعادة البيانات, هل تريد الحذف ؟' : 'This project will deleted for ever and you will be unable to restore it, Are you sure of delete ?'}
            </Typography>
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseDeleteEditerBox} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button aria-label={"delete"} disabled={loadingDeleteEditerBox} onClick={() => handleDeletEditerBox(selectedProjectID)} color="error">            
            {loadingDeleteEditerBox ? (
                    <span>
                        <CircularProgress size={24} />
                    </span>
                ) : (
                    <span>
                        {language === 'ar' ? 'حذف' : 'Delete'}
                    </span>
                )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAddEditerModal} onClose={handleCloseAddEditerModal} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'إضافة موضوع' : 'Add new project'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label={language === 'ar' ? 'عنوان الموضوع' : 'Project name'}
            type="text"
            fullWidth
            variant="outlined"
            value={projectName}
            onChange={handleProjectNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseAddEditerModal} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button aria-label={"add"} disabled={loadingAddEditBox} onClick={handleAddNewEditerBox} color="success">            
            {loadingAddEditBox ? (
                    <span>
                        <CircularProgress size={24} />
                    </span>
                ) : (
                    <span>
                        {language === 'ar' ? 'إضافة' : 'Add'}
                    </span>
                )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDownloadUrlModle} onClose={handleCloseDownloadUrlModel} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'إضافة فيديو من رابط' : 'Add video by url'}</DialogTitle>
        <DialogContent>
            <Typography textAlign={'center'} color="textSecondary">
               {language === 'ar' ? 'الصق عنوان URL للمنشور أو الوسائط واضغط للتنزيل بدقة عالية' : 'Paste the URL of the post or media and press to download in HD'}
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={4} my={3}>
              <CustomTextField
                label="https://"
                variant="outlined"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                fullWidth
                margin="normal"
                inputProps={{ dir: language === 'ar' ? 'rtl' : 'ltr' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="Paste from clipboard" onClick={handlePasteFromClipboard}>
                        <PasteIcon />
                      </IconButton>
                      <IconButton aria-label="Download" onClick={handleDownload} disabled={loading}>
                        <DownnIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Box width="100%" my={2}>
                {loading &&(
                  <div style={{textAlign:'center'}}>
                    <LinearProgress />
                     &nbsp;
                    {isProssess ? (
                      <Typography color="rgba(204, 19, 87, 0.64)" >{language === 'ar' ? 'جار معالجة الفيديو قد يستغرق وقتا أطول ...' : "Processing video , It may take a while ..."}</Typography>
                    ) : (
                      <Typography color="rgba(204, 19, 87, 0.64)" >{language === 'ar' ? 'جار البحث , لاتحدث الصفحة ...' : "Serching video , Don't refresh ..."}</Typography>
                    )}
                  </div>
                )}
                {error && <Typography color="error" textAlign={'center'}>{error}</Typography>}
              </Box>
              <Box width="100%" my={2}>
                <VideoList videoData={videoData} isMobile={isMobile} videoCover={videoCover} orginUrl={orginUrl} isEditer={true} /> {/* Pass videoData to VideoList */}
              </Box>
            </Box>
            <Box width="100%" textAlign={'center'} my={2}>
              <p style={{color:'burlywood'}}>Powered by Smart Loader</p>
            </Box>
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseDownloadUrlModel} color="inherit">
            {language === 'ar' ? 'إلغاء' : 'close'}
          </Button>
          <Button disabled={!isAllowAdd} aria-label={"add"} onClick={() => handleChooseAdding(localStorage.getItem('project_id'))} color="success">
            {language === 'ar' ? 'إضافة إلى القائمة' : 'Add to list'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openTextModle} onClose={handleCloseTextModel} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'إضافة نص' : 'Add text'}</DialogTitle>
        <DialogContent>
            <Typography textAlign={'center'} color="textSecondary">
               {language === 'ar' ? 'أدخل النص الذي تود ظهوره على المقطع' : 'Add your text to show on current frame'}
            </Typography>
            <CustomTextField
                label={language === 'ar' ? 'أدخل النص للظهور على المقطع' : 'Enter text to add on canvas'}
                variant="outlined"
                value={userText}
                type="text"
                name="addtext"
                onChange={handleTextChange}
                margin="normal"
                inputProps={{ dir: language === 'ar' ? 'rtl' : 'ltr' }}
            />
                  {/* Text Size Slider */}
      <Box mt={3}>
        <InputLabel>{language === 'ar' ? 'حجم النص' : 'Text Size'}</InputLabel>
        <Slider
          value={videoTextSize}
          min={5}
          max={24}
          step={1}
          onChange={(e, value) => setVideoTextSize(value)}
          valueLabelDisplay="auto"
          aria-labelledby="text-size-slider"
        />
      </Box>

      {/* Color Picker Input */}
      <Box mt={3}>
        <InputLabel>{language === 'ar' ? 'اختر لون النص' : 'Pick Text Color'}</InputLabel>
        <ColorInput
          type="color"
          value={videoTextColor}
          onChange={(e) => setVideoTextColor(e.target.value)}
        />
      </Box>
      {/* Text Postion info */}
      <Box mt={3}>
      <Typography color="textSecondary">
               {language === 'ar' ? '* قم بتحريك النص على الصورة لتحديد موقع الظهور.' : '* Drag the text on the image to determine where it will appear.'}
      </Typography>
      </Box>
        </DialogContent>
        <DialogActions>
        <Button aria-label={"close"} onClick={handleCloseTextModel} color="inherit">
            {language === 'ar' ? 'إلغاء' : 'close'}
          </Button>
          <Button aria-label={"save"} onClick={() => handleSaveTextModel(false,0,0)} color="success">
            {language === 'ar' ? 'حفظ' : 'save !'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSoundModle} onClose={handleCloseSoundModel} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'إضافة صوت' : 'Add sound'}</DialogTitle>
        <DialogContent textAlign={'center'} alignItems={'center'}>
            <div>
              {userAudioURL !== '' ? (
                 <AudioPlayer audioUrl={userAudioURL} onAudioClick={(isPlay,audioRefSet) => handelIsPlay(isPlay,audioRefSet)}/>
              ) : (
                 <SettingsVoiceIcon style={{width:'100%' ,height:300}}/>
              )}
            </div>
            &nbsp;
            <div style={{textAlign:'center'}}>
            {userAudioURL !== '' ? (
                <ButtonGroup variant="contained" color="inherit" aria-label="outlined button group">
                  <Button aria-label={"Add sound"} variant="contained" color="inherit" onClick={handleGenerateAddSoundProcess}>
                    {language === 'ar' ? 'تغيير الصوت' : 'Change sound'}
                  </Button>
                  <Button aria-label={"delete sound from list"} variant="contained" color="error" onClick={handleDeletAudioBox}>
                      <DeleteForeverIcon />
                  </Button>
                </ButtonGroup>
              ) : (
                  <Button aria-label={"Add sound"} variant="contained" color="success" onClick={handleGenerateAddSoundProcess}>
                      {language === 'ar' ? 'إضافة صوت' : 'Add sound'}
                  </Button>
              )}
                                <input
                                  type="file"
                                  accept="audio/mp3, video/mp4"
                                  ref={fileInputRef}
                                  style={{ display: 'none' }}
                                  onChange={handleFileChange}/>
            </div>
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseSoundModel} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={openBackgroundModle} onClose={handleCloseBackgroundModel} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'إضافة خلفية' : 'Add Background'}&nbsp;<HelpOutlineIcon onClick={handelOpenAboutBackgroundModel}/></DialogTitle>
        <DialogContent textAlign={'center'} alignItems={'center'}>
            <Typography textAlign={'center'} color="textSecondary" marginBottom={5}>
               {language === 'ar' ? 'خلفية الفيديو' : 'Video background'}
            </Typography>
            <div>
              {backgroundUrl !== '' ? (
                <img src={backgroundUrl} alt='background video' width={'100%'} height={300} />
              ) : (
                 <CenterFocusWeakIcon style={{width:'100%' ,height:300}}/>
              )}
            </div>
            &nbsp;
            <div style={{textAlign:'center'}}>
            {backgroundUrl !== '' ? (
                <ButtonGroup variant="contained" color="inherit" aria-label="outlined button group">
                  <Button aria-label={"change image"} onClick={handleGenerateAddBackGroundProcess} color="inherit">
                    {language === 'ar' ? 'تغيير الصورة' : 'Change image'}
                  </Button>
                  <Button aria-label={"delete image"} onClick={handleDeletImageBox} color="error">
                    <DeleteForeverIcon />
                  </Button>
                </ButtonGroup>
              ) : (
                <Button aria-label={"add image"} onClick={handleGenerateAddBackGroundProcess} color="success">
                  {language === 'ar' ? 'إضافة صورة' : 'Add image'}
                </Button>
              )}
            <input
              type="file"
              accept="image/*"
              ref={imagefileInputRef}
              style={{ display: 'none' }}
              onChange={handleImageFileChange}/>
            </div>
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseBackgroundModel} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openStickerModle} onClose={handleCloseStickerModel} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        {stickerGenrateType === 'photo' ? (
            <DialogTitle textAlign={'center'}>{language === 'ar' ? 'إنشاء صورة' : 'Generate a photo'}</DialogTitle>
        ) : (
            <DialogTitle textAlign={'center'}>{language === 'ar' ? 'إنشاء ستيكر' : 'Generate a sticker'}</DialogTitle>
        )}
        <DialogContent>
            {stickerGenrateType === 'photo' ? (
                <Typography textAlign={'center'} color="textSecondary">
                  {language === 'ar' ? 'إختر نوع الصورة الذي تريد إنشائها' : 'Choose your ganerated photo type'}
                </Typography>
            ) : (
                <Typography textAlign={'center'} color="textSecondary">
                  {language === 'ar' ? 'إختر نوع الستيكر الذي تريد إنشائه' : 'Choose your ganerated sticker type'}
                </Typography>
            )}
            <div>
            <FormControl>
      <RadioGroup
        defaultValue="False"
        aria-labelledby="sticker-customized-radios"
        name="customized-radios"
      >
        <FormControlLabel checked={removeBackGround === 'False'} onChange={handleChangeBackgroundType} value="False" control={<BpRadio />} label={language == 'ar' ? 'مع الخلفية' : 'with background'} />
        <FormControlLabel checked={removeBackGround === 'true'} onChange={handleChangeBackgroundType} value="true" control={<BpRadio />} label={language == 'ar' ? 'من دون الخلفية' : 'without background'} />
      </RadioGroup>
      </FormControl>
    </div>
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseStickerModel} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button aria-label={"generate"}  onClick={() => handleGenerateProcess('video_to_gif')} color="success">            
            {language === 'ar' ? 'إنشاء' : 'Generate'}
          </Button>
        </DialogActions>
      </Dialog>

          {/* Dialog for is Up Date */}
      <Dialog open={isUpdate} onClose={readUpdate} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'ما الجديد !' : 'Whats New !'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <p>{language === 'ar' ? '1- تحسين تحديث بعض خصائص المحرر' : '1- Improved and update features of editer.'}</p>
          <p>{language === 'ar' ? '2- تحسين وتحديث بعض الخصائص الأخرى.' : '2- Improved and updated other features.'}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={readUpdate} color="primary">
            {language === 'ar' ? 'إغلاق' : 'close'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Up Date */}
      <Dialog open={showUpdateDialog} onClose={handleCloseDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'تحديث جديد !' : 'A new Up date !'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language === 'ar' ? 'هناك تحديث جديد للموقع لخدمة أفضل الرجاء تحديث الصفحة من المتصغح ...' : 'There is a new update for the site, For better preference please refresh the page from your explorar ...'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {language === 'ar' ? 'تحديث' : 'Refresh'}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog for Emoji list */}
      <EmojiPickerDialog open={openEmojiModle} onClose={handelCloseEmojiModel} onSelectEmoji={(emoji) => handelSelectEmojiModle(emoji)} />
      {/* Dialog for faces list */}
      <FaceSwapImagesPicker open={openFaceSwapModle} onClose={handelCloseFaceModel} onSelectFace={(face) => handelSelectFaceModle(face)}/>
      {/* Dialog priview video */}
      <Dialog open={showLocalVideoEditerDialog} onClose={handelCloseLocalVideoEditerModel} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'عرض الفيديو' : 'Priview video'}</DialogTitle>
        <DialogContent style={{minWidth:300}}>
          <Box mt={1} my={2}>
          <video controls style={{ width: '100%', maxHeight: '380px' }}>
            <source src={previewLocalVideoUrl} type={"video/mp4"} />
            Your browser does not support the video tag.
          </video>
          </Box>
          <Box width={'100%'} display="flex" my={2} flexDirection="row" justifyContent="center" flexWrap="wrap">
              <VideoEditor videoURL={previewLocalVideoUrl} isMobile={isMobile} isEditer={true}/>
          </Box>
          <Box mx={1} my={1} textAlign="center">
            <Button aria-label={"recognize_audio"} variant="contained" color="info" onClick={() => handleOpenRecognizeModal(previewLocalVideoUrl)}>
                <MusicNoteIcon style={{fontSize:'medium'}}/>&nbsp;&nbsp;{language === 'ar' ? 'التعرف على الموسيقى' : 'Recognize'}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handelCloseLocalVideoEditerModel} color="primary">
            {language === 'ar' ? 'إلغاء' : 'close'}
          </Button>
          <Button onClick={processAddedVideo} color="success">
            {language === 'ar' ? 'إضافة' : 'add'}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog to display recognize audio */}
      <Dialog open={openRecognizeModal} onClose={handleCloseRecognizeModal} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
          <DialogContent style={{minWidth:300}}>
            <Box width="100%" my={5} textAlign={'center'}>
                        {recognizeLoading && <LinearProgress />}
                        {recognizeError && <Typography color="error">{recognizeError}</Typography>}
                        {recognizeResult && (
                        <div>
                            <h2>{language === 'ar' ? 'معلومات موسيقى المقطع' : 'Recognition Result:'}</h2>
                            <p>{language === 'ar' ? 'العنوان:' : 'Title:'} {recognizeResult[0].title}</p>
                            <p>{language === 'ar' ? 'الفنان:' : 'Artist:'} {recognizeResult[0].artists.map(artist => artist.name).join(', ')}</p>
                            <p>{language === 'ar' ? 'الألبوم:' : 'Album:'} {recognizeResult[0].album.name}</p>
                            <p>{language === 'ar' ? 'تاريخ النشر:' : 'Release Date:'} {recognizeResult[0].release_date}</p>
                        </div>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseRecognizeModal} color="primary">
                    {language === 'ar' ? 'إغلاق' : 'close'}
                  </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CustumVideoEditer;
