// src/components/HomePage.js
import React, { useEffect , useState , useCallback } from 'react';
import './downloads.css';
import { Container,Accordion,AccordionSummary,AccordionDetails,Link, Grid, Box, Card, CardMedia, CardContent, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, Button } from '@mui/material';
import VideoDownloadForm from './VideoDownloadForm';
import Typewriter from 'typewriter-effect';
import { useLanguage } from './LanguageContext';
import PageviewIcon from '@mui/icons-material/Pageview';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import keywordsData from './keywords.json';
import { updateClick } from './updateClick';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const HomePage = () => {
    const siteVer = '19.16.8';
    const [shareData, setShareData] = useState([]);
    const [isUpdate, setIsUpdate] = useState(() => {
      const checkIsUpdate = localStorage.getItem('is_updated');
      if (checkIsUpdate === "true") {
        // Optionally decode token to get user info
        return true;
      }
      return false;
    });
    const { language } = useLanguage();
    const [isMobile, setIsMobile] = useState(false);
    const [showAds, setShowAds] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate
    const facebook = language === 'ar' ? 'فيس بوك' : 'facebook'
    const instgram = language === 'ar' ? 'إنستجرام' : 'Instagram'
    const snapChat = language === 'ar' ? 'سناب شات' : 'Snapchat'
    const tikTok = language === 'ar' ? 'تيك توك' : 'TikTok'
    const kwai = language === 'ar' ? 'كاواي' : 'Kwai'
    const youtube = language === 'ar' ? 'يوتيوب' : 'YouTube'
    const twiter = language === 'ar' ? 'تويتر' : 'twiter'
    const others = language === 'ar' ? 'وغيرها من المواقع' : 'And More ..'
    const [showUpdateDialog, setShowUpdateDialog] = useState(false);
    const [showHowToEditerDialog, setShowHowToEditerDialog] = useState(false);
    const [showHowToStickerDialog, setShowHowToStickerDialog] = useState(false);
    const [isReadEditer, setIsReadEditer] = useState(() => {
      // Attempt to load the user from localStorage
      const checkIsRead = localStorage.getItem('is_read_editer');
      if (checkIsRead === "true") {
        // Optionally decode token to get user info
        return true;
      }
      return false;
    });

    const readUpdate = () => {
      setIsUpdate(false);
      localStorage.setItem('currentUpdateVer',siteVer);
      localStorage.setItem('is_updated','false');
    }

    const handleCloseDialog = async() => {
      await updateClick('update');
      localStorage.setItem('is_updated','true');
      window.location.reload();
    };

    const handleCloseHowToEditerDialog = async() => {
      setShowHowToEditerDialog(false);
      setIsReadEditer(true);
      localStorage.setItem('is_read_editer',"true");
      await updateClick('done_read');
    };

    const handelAdClick = async() => {
      window.open('https://vpn366.com', '_blank');
      await updateClick('vpn_ad_click');
    }

    const handleOpenHowToStickerDialog = () => {
      setShowHowToStickerDialog(true);
    }

    const handleCloseHowToStickerDialog = () => {
      setShowHowToStickerDialog(false);
    }

    const handleOpenNav = (Url) => {
      navigate(`/${Url}`); // Navigate to the ViewRest component
    };

    const SEO = ({ title, description, keywords }) => (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "${title}",
              "url": "https://samrt-loader.com"
            }
          `}
        </script>
      </Helmet>
    );

    const handleReqStieData = useCallback(async () => {
      try {
        const token = localStorage.getItem('authToken') || ""
        const response = await fetch('https://samrt-loader.com/kydwon/api/siteInfo' , {
          method: 'GET', // Specify the method
          headers: {
            'X-Authorization':`Bearer ${token}`
          },
        });
        const data = await response.json();
        if (data.success) {
          //setVideoData(response.data.files); // Set videoData state with response data
          setIsMobile(data.is_mobile);
          setShareData(data.share_data);
          if(data.is_mobile){
            localStorage.setItem('isMobile','true')
          } else {
            localStorage.setItem('isMobile','false')
          }
          localStorage.setItem('updateDialogVer', siteVer);
          if(data.is_set_pass){
            localStorage.setItem('is_set_pass', 'true');             
          } else {
            localStorage.setItem('is_set_pass', 'false');
          }
          const updateDialogVer = localStorage.getItem('updateDialogVer');
          if (updateDialogVer !== data.version) {
            setShowUpdateDialog(true);
          }

          localStorage.setItem('share_count',data.share_count)
          if(!isReadEditer) {
            if(!isUpdate){
              localStorage.setItem('currentUpdateVer',siteVer);
              setShowHowToEditerDialog(true);
            }
          }

          const currentUpdateVer = localStorage.getItem('currentUpdateVer');
          if (currentUpdateVer !== data.version) {
            if(!isUpdate){
              setIsUpdate(true);
            }
          }
          
          var isShowAds = data.is_show_ads;
          if(isShowAds) {
            setTimeout(() => setShowAds(true), 3000); // Check again after 3 seconds
          } else {
            setShowAds(false);
          }

          var is_login = data.is_login;
          if(is_login) {
            if(data.user_photo_url != null) {
              localStorage.setItem('user_photo_url',data.user_photo_url);
            }
          } else {
            logout();
          }
        }
      } catch (error) {
      }
    }, [isUpdate, isReadEditer, siteVer]); // Remove shareData from the dependencies

    const logout = async() => {
      localStorage.setItem('authToken', null);
      localStorage.setItem('isLogin', 'false');
      localStorage.setItem('userid', null);
      localStorage.setItem('username', null);
      localStorage.setItem('userlevelid', null);
      localStorage.setItem('project_id',"0");
    }

    useEffect(() => {
      // Call API immediately when component mounts
      handleReqStieData();
      // Set interval to fetch site data every 30 minutes
      const intervalId = setInterval(() => {
        handleReqStieData();
      }, 10 * 60 * 1000); // 10 minutes in milliseconds
  
      // Cleanup the interval when component unmounts
      return () => clearInterval(intervalId);
    }, [handleReqStieData]);
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    
  return (
    <Container>
      <SEO 
        title={language === 'ar' ? keywordsData.title_ar : keywordsData.title_en}
        description={language === 'ar' ? keywordsData.description_ar : keywordsData.description_en}
        keywords={keywordsData.keywords.join(', ')}
      />
      <div className="homepage-header">
        {showAds && (
          <Box width={'100%'} alignItems={'center'}>
            {language === "ar" ? (
              <img style={{width:'100%', maxWidth:'400px'}} alt='vpn 366' src='https://samrt-loader.com/ads_logo/vpn366_ar.webp' onClick={handelAdClick} />
            ) : (
              <img style={{width:'100%', maxWidth:'400px'}} alt='vpn 366' src='https://samrt-loader.com/ads_logo/vpn366.webp' onClick={handelAdClick} />
            )}
          </Box>
        )}
        <h2>{language === 'ar' ? 'تحميل وتعديل الفيديوهات من ' : 'Download & Edit videos from '}
            <Typewriter
                options={{
                   strings: [facebook,instgram,snapChat,tikTok,kwai,youtube,twiter,others],
                   autoStart: true,
                   loop: true,
                }}
            />
        </h2>
        <p>{language === 'ar' ? 'الصق عنوان URL للمنشور أو الوسائط واضغط للتنزيل بدقة عالية' : 'Paste the URL of the post or media and press to download in HD'}</p>
        <VideoDownloadForm isMobile={isMobile} shareData={shareData} />
      </div>
      <div className="homepage-content">
        <h2>{language === 'ar' ? 'طريقة التحميل' : 'How it works ?'}</h2>
        <ol>
          <li>
            <h3>{language === 'ar' ? 'إبحث عن فيديو' : 'Search for a Video'}</h3>
            <p>
            {language === 'ar' ? 'انسخ عنوان URL للفيديو الذي تريد تنزيله من أي موقع تواصل اجتماعي منصة الوسائط، مثل فيس بوك أو إنستجرام أو سناب شات أو تيك توك أو كاواي أو يوتيوب أو تويتر وغيرها من المواقع التي تدعمها المنصة . ': 'Copy the URL of the video you want to download from any social media platform, such as Snapchat, TikTok, Kawi, Facebook, YouTube, twiter or Instagram and alot of site list that we support .'}
            </p>
          </li>
          <li>
            <h3>{language === 'ar' ? 'إنسخ ولصق رابط القيديو أو المشاركة' : 'Paste the URL'}</h3>
            <p>
              {language === 'ar' ? 'الصق عنوان URL في الحقل المقدم على موقعنا.' : 'Paste the URL into the provided field on our website.'}
            </p>
          </li>
          <li>
            <h3>{language === 'ar' ? 'التحميل' : 'Download!'}</h3>
            <p>
            {language === 'ar' ? 'انقر فوق الزر "تحميل"، وحدد التنسيق والجودة المطلوبة للفيديو الخاص بك.' : 'Click the "Download" button, and select the desired format and quality for your video.'}
            </p>
          </li>
        </ol>
        <Container>
        <Box textAlign="center" width="100%"  my={7}>
        <h2>{language === 'ar' ? 'المكتبة' : 'Gallery'}</h2>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <Card variant="outlined">
              <CardContent>
                <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
                <img
                src="./video-camera_7147359.webp"
                alt={language === 'ar' ? 'مكتبة الفيديو' : 'Video Gallery'}
                style={{ width: '100%', height: '90px', objectFit: 'contain' }} />
                  <h2 >{language === 'ar' ? 'مكتبة الفيديو' : 'Video Gallery'}</h2>
                </Box>
                <Box mx={5} my={1} textAlign="center">
                  <Button aria-label="view_videos" variant="contained" color="info" onClick={() => handleOpenNav('./VideoGallery')}>
                    <PageviewIcon />&nbsp;&nbsp;{language === 'ar' ? 'عرض' : 'View'}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card variant="outlined">
              <CardContent>
                <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
                <img
                src="./camera_7147354.webp"
                alt={language === 'ar' ? 'مكتبة الصور' : 'Photos Gallery'}
                style={{ width: '100%', height: '90px', objectFit: 'contain' }} />
                  <h2>{language === 'ar' ? 'مكتبة الصور' : 'Photos Gallery'}</h2>
                </Box>
                <Box mx={5} my={1} textAlign="center">
                  <Button aria-label="view_photos" variant="contained" color="info" onClick={() => handleOpenNav('./ImagesGallery')}>
                    <PageviewIcon />&nbsp;&nbsp;{language === 'ar' ? 'عرض' : 'View'}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card variant="outlined">
              <CardContent>
                <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
                <img
                src="./open-book_7147304.webp"
                alt={language === 'ar' ? 'مكتبة الكلمات' : 'Words Gallery'}
                style={{ width: '100%', height: '90px', objectFit: 'contain' }} />
                  <h2>{language === 'ar' ? 'مكتبة الكلمات' : 'Words Gallery'}</h2>
                </Box>
                <Box mx={5} my={1} textAlign="center">
                  <Button aria-label="view_words" variant="contained" color="info" onClick={() => handleOpenNav('./TextsGallery')}>
                    <PageviewIcon />&nbsp;&nbsp;{language === 'ar' ? 'عرض' : 'View'}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        </Box>
        <Box width="100%"  my={7}>
          <Box width="100%"  textAlign={'center'}>
             <h2>{language === 'ar' ? 'أسئلة شائعة' : 'F&Q'}</h2>
          </Box>
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {language === 'ar' ? 'لماذا يشير تنزيل Smart Loader هذا إلى أنه لا يمكنني تنزيل هذا الفيديو؟' : "Why does this Smart Loader download say I can't download this video?"}
        </AccordionSummary>
        <AccordionDetails>
          {language === 'ar' ? 'هناك العديد من الأسباب التي أدت إلى فشل التنزيل: مقاطع الفيديو خاصة أو محذوفة أو مقيدة بدولة واحدة فقط ، روابط التنزيل الخاصة بك ليست روابط فيديو . مثال الارتباط htps://vt.site.com/ZSJmdax66/' : 'There are many reasons why download failed: videos are private, deleted or restricted to only one country, your download links are not video links. Example link htps://vt.site.com/ZSJmdax66/'}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          {language === 'ar' ? 'هل أحتاج إلى حساب Smart Loader لتحميل مقاطع الفيديو؟' : 'Do I need to have a Smart Loader account to download videos?'}
        </AccordionSummary>
        <AccordionDetails>
         {language === 'ar' ? 'لا، لست بحاجة إلى حساب Smart Loader. يمكنك تشغيل مقطع الفيديو الذي يتم تنزيله بمجرد إدخال رابط. ما عليك سوى لصقه في حقل الإدخال أعلى الصفحة والنقر فوق الزر "تنزيل". ستزيل خدمة التنزيل الخاصة بنا العلامة المائية وسيكون الفيديو جاهزًا للاستخدام في غضون ثوانٍ قليلة.' : 'No, you do not need to have a Smart Loader account. You can launch the download video with as little as a link. Simply paste it into the input field at the top of the page and click the "Download" button. Our Downloader service will remove the watermark and the video will be ready to use in a few seconds.'}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          {language === 'ar' ? 'هل أحتاج إلى حساب Smart Loader لتحرير وتعديل مقاطع الفيديو؟' : 'Do I need to have a Smart Loader account to Edit videos?'}
        </AccordionSummary>
        <AccordionDetails>
         {language === 'ar' ? 'نعم تحتاج لتسجيل الدخول للوصول لأداة المحرر وتعديل الفيديوهات وإنشاء قائمتك الخاصة . نحن لانحتفظ بالفيديوهات المنشئة عن طريق أداة المحرر لأكثر من 12 ساعة' : 'Yes, you need to log in to access the editor tool, edit videos, and create your own playlist. We do not keep videos created through the editor tool for more than 12 hours.'}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          {language === 'ar' ? 'هل أحتاج إلى حساب Smart Loader لمشاركة مقاطع الفيديو؟' : 'Do I need to have a Smart Loader account to share videos on this site?'}
        </AccordionSummary>
        <AccordionDetails>
         {language === 'ar' ? 'نعم تحتاج لتسجيل الدخول لمشاركة مقاطع الفيديو المحملة أو المنتجة عن طريق أداة المحرر , وسيتم إنشاء المشاركة ورابط المصدر تلقائيا للحفاظ على حقوق ملكية الطرف الأخر' : "Yes, you need to log in to share videos uploaded or produced via the editor tool, and the share and source link will be created automatically to maintain the other party's ownership rights."}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          {language === 'ar' ? 'لماذا تم حذف مشاركتي من صندوق المشاركات ؟' : 'Why my share has been deleted from share box ?'}
        </AccordionSummary>
        <AccordionDetails>
         {language === 'ar' ? 'يتم حذف المشاركات تلقائيا عبر الذكاء الصناعي أو عن طريق أحد المراقبين لعدم توافق المشاركة مع شروط إستخدام الموقع , قد تكون المشاركة تخص حساب خاص أو تحتوي على مقاطع مخلة أو حكومية أو قطاعات خاصة أو لاتكون من نوع (Watch,reels,shorts).' : "Posts are automatically deleted by AI or by our moderators for not complying with the site's terms of use. The post may belong to a private account or contain indecent, governmental, or private sector clips or not be of the type (Watch, reels, shorts)."}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          {language === 'ar' ? 'هل يجب علي الدفع لتحميل مقاطع الفيديو بدون علامة مائية؟' : 'Do I have to pay to download videos without watermark?'}
        </AccordionSummary>
        <AccordionDetails>
          {language === 'ar' ? 'لا، لست مضطرًا إلى دفع أي شيء، لأن أداة التنزيل عبر الإنترنت لدينا بدون علامة مائية تظل مجانية تمامًا! يدعم Smart Loader جميع المتصفحات الحديثة، بما في ذلك Google Chrome وMozilla Firefox وSafari وMicrosoft Edge والمزيد.' : 'No, you dont have to pay for anything, because our online downloader without watermark remains entirely for free! Smart Loader support all modern browsers, including Google Chrome, Mozilla Firefox, Safari, Microsoft Edge, and more.'}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          {language === 'ar' ? 'كيفية حفظ مقاطع فيديو  باستخدام iPhone / iPad؟' : 'How to save videos using iPhone/iPad?'}
        </AccordionSummary>
        <AccordionDetails>
          {language === 'ar' ? 'إذا كان نظام التشغيل لديك أقل من 13 ، فيمكنك تحديث البرنامج الخاص بك ، أو اتباع التعليمات لاستخدام برنامج Smart Loader Downloader الخاص بنا. في الماضي ، لم يكن بإمكانك حفظ أي مقاطع فيديو على جهاز iPhone أو iPad بسبب سياسات Apple. ولكن الآن ، مع نظام التشغيل 13 أو أعلى ، يدعم Safari تنزيل الملفات على جهازك. يرجى اتباع التعليمات الخاصة بكيفية تنزيل مقاطع فيديو Smart Loader باستخدام جهاز iPhone / iPad الذي يعمل بنظام التشغيل OS 13.' : "If your OS is below 13, you can update your software, or follow the instructions to use our Smart Loader Downloader. In the past, you couldn't save any videos on your iPhone or iPad due to Apple's policies. But now, with OS 13 or above, Safari supports downloading files to your device. Please follow the instructions on how to download Smart Loader videos with your iPhone/iPad running OS 13."}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          {language === 'ar' ? 'كيفية حفظ مقاطع فيديو بهاتف Android الخاص بي؟' : 'How to save videos to my Android phone?'}
        </AccordionSummary>
        <AccordionDetails>
          {language === 'ar' ? 'لديك خياران لتنزيل مقاطع فيديو باستخدام أجهزتك التي تعمل بنظام Android باستخدام أدوات تنزيل Smart Loader. قم بتنزيل تطبيقات SnapX الخاصة بنا على PlayStore. استخدم متصفح هاتفك المحمول ، اذهب إلى Smart Loader, واتبع التعليمات لاستخدام Smart Loader Download بدون علامة مائية.' : "You have two options to download videos using your Android devices with Smart Loader downloaders. Download our SnapX apps on PlayStore. Use your mobile browser, go to Smart Loader, and follow the instructions to use Smart Loader Download without watermark."}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          {language === 'ar' ? 'أين يتم حفظ مقاطع فيديو عد تنزيلها؟' : 'Where are videos saved after downloading?'}
        </AccordionSummary>
        <AccordionDetails>
          {language === 'ar' ? 'عادةً ما يتم حفظ مقاطع الفيديو التي تم تنزيلها في أي مجلد قمت بتعيينه كمجلد افتراضي ، وعادةً ما يكون معرض الصور الخاص بك. بعد استخدام برنامج Smart Loader Downloader الخاص بنا ، يمكنك الانتقال إلى المعرض للتحقق من مقاطع الفيديو الخاصة بك' : "Downloaded videos are usually saved in whatever folder you set as default, usually your gallery. After using our Smart Loader Downloader, you can go to the gallery to check your videos."}
        </AccordionDetails>
      </Accordion>
        </Box>
        <Box width="100%"  marginTop={14}>
      <Grid>
        <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
            <Grid>
            <Card sx={{ maxWidth: 50, maxHeight: 100 }}>
            <CardMedia 
              component="img"
              height="50"
              image={'https://samrt-loader.com/social_icons/facebook.png'}
              alt={`facebook`}
            />
          </Card>
            </Grid>
            <Grid>
            <Card sx={{ maxWidth: 50, maxHeight: 100 }}>
            <CardMedia 
              component="img"
              height="50"
              image={'https://samrt-loader.com/social_icons/instagram.png'}
              alt={`instagram`}
            />
          </Card>
            </Grid>
            <Grid>
            <Card sx={{ maxWidth: 50, maxHeight: 100 }}>
            <CardMedia 
              component="img"
              height="50"
              image={'https://samrt-loader.com/social_icons/threads.png'}
              alt={`Threads`}
            />
          </Card>
            </Grid>
            <Grid>
            <Card sx={{ maxWidth: 50, maxHeight: 100 }}>
            <CardMedia 
              component="img"
              height="50"
              image={'https://samrt-loader.com/social_icons/tiktok.png'}
              alt={`tiktok`}
            />
          </Card>
            </Grid>
            <Grid>
            <Card sx={{ maxWidth: 50, maxHeight: 100 }}>
            <CardMedia 
              component="img"
              height="50"
              image={'https://samrt-loader.com/social_icons/snapchat.png'}
              alt={`snapchat`}
            />
          </Card>
            </Grid>
            <Grid>
            <Card sx={{ maxWidth: 50, maxHeight: 100 }}>
            <CardMedia 
              component="img"
              height="50"
              image={'https://samrt-loader.com/social_icons/twitter.png'}
              alt={`twitter`}
            />
          </Card>
            </Grid>
            <Grid>
            <Card sx={{ maxWidth: 50, maxHeight: 100 }}>
            <CardMedia 
              component="img"
              height="50"
              image={'https://samrt-loader.com/social_icons/pinterest.png'}
              alt={`pinterest`}
            />
          </Card>
            </Grid>
            <Grid>
            <Card sx={{ maxWidth: 50, maxHeight: 100 }}>
            <CardMedia 
              component="img"
              height="50"
              image={'https://samrt-loader.com/social_icons/kwai.webp'}
              alt={`kwai`}
            />
          </Card>
            </Grid>
            <Grid>
            <Card sx={{ maxWidth: 50, maxHeight: 100 }}>
            <CardMedia 
              component="img"
              height="50"
              image={'https://samrt-loader.com/social_icons/youtube.png'}
              alt={`youtube`}
            />
          </Card>
            </Grid>
        </Grid>
      </Grid>
      </Box>
        </Container>
      </div>

      {/* Dialog for is Up Date */}
      <Dialog open={isUpdate} onClose={readUpdate} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'ما الجديد !' : 'Whats New !'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <p>{language === 'ar' ? '1- تحسين تحديث بعض خصائص المحرر' : '1- Improved and update features of editer.'}</p>
          <p>{language === 'ar' ? '2- تحسين وتحديث بعض الخصائص الأخرى.' : '2- Improved and updated other features.'}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={readUpdate} color="primary">
            {language === 'ar' ? 'إغلاق' : 'close'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Up Date */}
      <Dialog open={showUpdateDialog} onClose={handleCloseDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'تحديث جديد !' : 'A new Up date !'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language === 'ar' ? 'هناك تحديث جديد للموقع لخدمة أفضل الرجاء تحديث الصفحة من المتصغح ...' : 'There is a new update for the site, For better preference please refresh the page from your explorar ...'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {language === 'ar' ? 'تحديث' : 'Refresh'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog How to editer */}
      <Dialog open={showHowToEditerDialog} onClose={handleCloseHowToEditerDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'أداة المحرر' : 'New feature'}</DialogTitle>
        <DialogContent>
          <DialogContentText mt={1} my={2}>
            <p>{language === 'ar' ? 'قم بقص وتجميع الفيديوهات المحملة من مختلف المنصات ودمجها في فيديو واحد يناسب جميع منصات التواصل لمشاركته , جربها لان وإستمتع بإنشاء تجميعة مميزة ومشاركتها مع أصدقائك .' : 'Cut and collect videos downloaded from different platforms and merge them into one video that suits all social media platforms to share. Try it now and enjoy creating a unique collection and sharing it with your friends .'}</p>
            <p>{language === 'ar' ? 'تم إضافة خاصية تحويل الفيديو إلى ستيكر GIF متحرك إلى المحرر.' : 'Adding Video to GIF sticker conversion to the editer.'} <Link onClick={handleOpenHowToStickerDialog} href="#" color="inherit" className="animated-link">{language === 'ar' ? 'طريقة الإستخدام' : 'how to use'}</Link></p>
            <p>{language === 'ar' ? 'إضافة خيار إضافة خلفية للفيديو لأداة المحرر' : 'Added option to add background to video to editor tool'}</p>
          <div style={{textAlign:'center',alignItems:'center'}}>
            <img src='https://samrt-loader.com/add_background.png' alt='add background' width={'100%'} height={300}/>
          </div>
          </DialogContentText>
          <Box mt={1} my={2}>
          <video controls style={{ width: '100%', maxHeight: '380px' }}>
            <source src={"https://samrt-loader.com/how_to_editer.mp4"} type={"video/mp4"} />
            Your browser does not support the video tag.
          </video>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHowToEditerDialog} color="primary">
            {language === 'ar' ? 'حسنا !' : 'got it !'}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog How to sticker */}
      <Dialog open={showHowToStickerDialog} onClose={handleCloseHowToStickerDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'طريقة إنشاء ستيكر من الفيديو' : 'How to sticker video'}</DialogTitle>
        <DialogContentText mt={1} my={2}>
            <p>{language === 'ar' ? 'ماهو الستيكر ؟' : 'What is sticker video ?'}</p>
            <p>{language === 'ar' ? 'هو ملصق بصيغة صورة GIF يستخدم في رسائل الواتس اب أو السوشيال ميديا في الكومنت التي تدعم الصور المتحركة' : 'It is a sticker in GIF format used in WhatsApp messages or social media in the comments that support animated images.'}</p>
        </DialogContentText>
        <DialogContent>
          <Box mt={1} my={2}>
          <video controls style={{ width: '100%', maxHeight: '380px' }}>
            <source src={"https://samrt-loader.com/how_to_sticker.mp4"} type={"video/mp4"} />
            Your browser does not support the video tag.
          </video>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHowToStickerDialog} color="primary">
            {language === 'ar' ? 'حسنا !' : 'got it !'}
          </Button>
        </DialogActions>
      </Dialog>
      </Container>
  );
};

export default HomePage;