import React, { useRef, useState, useEffect } from 'react';
import { Stage, Layer, Image as KonvaImage, Text } from 'react-konva';
import EmojiComponent from './EmojiComponent';
import { Dialog, CircularProgress, DialogContent } from '@mui/material';
import { useLanguage } from './LanguageContext';

const KonvaCanvasEditor = ({
  videoUrl,  
  userText,
  textColor,
  textSize,
  selectedEmoji,
  emojyPosX,
  emojyPosY,
  emojyWidth,
  emojyHeight,
  emojyRotation,
  videoDimensions,
  isMobile,
  handleSaveTextModel,
  handleSaveEmojyModel,
  setThumbnails,
  currentFrame,
  sliderValue,
  setSliderValue,
  setVideoDuration
}) => {
  const videoElement = useRef(null);
  const { language } = useLanguage();
  const stageRef = useRef(null);
  const canvasRef = useRef(null);
  const [textPosition, setTextPosition] = useState({ x: 50, y: 50 });
  const [emojySize, setEmojySize] = useState({ w: emojyWidth, h: emojyHeight });
  const [loadStatus, setLoadStatus] = useState(0);
  //const [currentFrame, setCurrentFrame] = useState(new window.Image());
  //const [thumbnails, setThumbnails] = useState([]);
  const [isLoadedThumbnails, setIsLoadedThumbnails] = useState(false);

  // Initialize the video element and load the video
  useEffect(() => {
    //console.log('videoUrl : ',videoUrl);
    if (videoUrl) {
      videoElement.current = document.createElement('video');
      videoElement.current.src = videoUrl;
      videoElement.current.loop = false;
      videoElement.current.muted = true;
      videoElement.current.addEventListener('loadedmetadata', () => {
        const d = videoElement.current.duration;
        setVideoDuration(d); // Get video duration
        if(isLoadedThumbnails === false){
          videoElement.current.play();
          captureThumbnails(d);  // Capture thumbnails once video metadata is loaded
        }
      });
    }
  }, [videoUrl]);

  // Capture video frames at regular intervals (every second)
  const captureThumbnails = (videoDuration) => {
    const thumbImages = [];
    const canvas = canvasRef.current;
    let currentSecond = 0;
    const totalFrames = Math.floor(videoDuration); // 1 frame per second

    console.log('totalFrames : ',totalFrames)
  
    const captureNextFrame = () => {
      if (currentSecond > Math.floor(videoDuration)) {
        // All frames captured, set the thumbnails
        setThumbnails(thumbImages);
        setIsLoadedThumbnails(true);
        return;
      }
      // Seek to the current second
      videoElement.current.currentTime = currentSecond;
      //console.log('currentSecond : ',currentSecond)
      setLoadStatus((currentSecond / videoDuration) * 100);
      videoElement.current.onseeked = () => {
        setTimeout(() => {
          const context = canvas.getContext('2d');
          context.drawImage(videoElement.current, 0, 0, videoDimensions.width, videoDimensions.height);
    
          // Convert canvas to image
          const thumbnail = canvas.toDataURL();
          thumbImages.push({ time: currentSecond, imageSrc: thumbnail });
    
          // Move to the next second
          currentSecond += 1;
          captureNextFrame();
        }, 200); // Adjust this delay as needed
      };
    };
  
    // Start the capture process

    captureNextFrame();
  };
  

  const handleEmojiSize = (w, h) => {
    setEmojySize({ w: w, h: h });
  };

  return (
    <div>
      <Stage
        ref={stageRef}
        width={isMobile ? 260 : 300}
        height={400}
        style={{ border: '1px solid #ccc' }}
      >
        <Layer>
          {/* Render the video frame */}
          <KonvaImage
            image={currentFrame}
            width={isMobile ? 260 : 300}
            height={400}
          />

          {/* Render user text on top of the video */}
          {userText && (
            <Text
              text={userText}
              fontSize={textSize}
              fill={textColor}
              width={isMobile ? 260 : 300}
              fontFamily="Arial"
              padding={10}
              align="center"
              x={textPosition.x}
              y={textPosition.y}
              draggable
              onDragEnd={(e) => {
                const newPos = e.target.getAbsolutePosition();
                setTextPosition(newPos);
                handleSaveTextModel(true, newPos.x, newPos.y);
              }}
            />
          )}

          {/* Render emoji using the EmojiComponent */}
          {selectedEmoji && (
            <EmojiComponent
              isMobile={isMobile}
              videoDimensions={videoDimensions}
              selectedEmoji={selectedEmoji}
              emojyPosX={emojyPosX}
              emojyPosY={emojyPosY}
              emojyWidth={emojyWidth}
              emojyHeight={emojyHeight}
              emojyRotation={emojyRotation}
              onEmojiDragEnd={(e) => {
                const node = e.target;
                const newPos = node.getAbsolutePosition();
                handleSaveEmojyModel(
                  newPos.x,
                  newPos.y,
                  emojyWidth,
                  emojyHeight,
                  e.target.rotation()
                );
              }}
              handleSaveEmojyModel={handleSaveEmojyModel}
              handleSize={handleEmojiSize}
            />
          )}
        </Layer>
      </Stage>

      {/* Video Timeline Slider */}
      <input
        type="range"
        min="0"
        max="100"
        value={sliderValue}
        onChange={(e) => setSliderValue(e.target.value)}
        style={{ width: "100%", marginTop: "20px" }}
      />


      {/* Hidden canvas for frame extraction */}
      <canvas
        ref={canvasRef}
        style={{ display: "none" }}
        width={videoDimensions.width}
        height={videoDimensions.height}
      />
<Dialog open={!isLoadedThumbnails}>
  <DialogContent
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      minWidth: '250px', // Adjust to your desired width
    }}
  >
    <CircularProgress style={{ marginBottom: '20px' }} />
    <p>
      {language === 'ar'
        ? `جار التحميل ${Math.floor(loadStatus)} %`
        : `Reading video ${Math.floor(loadStatus)} %`}
    </p>
  </DialogContent>
</Dialog>

    </div>
  );
};

export default KonvaCanvasEditor;
