// src/NotFound.js
import React from 'react';
import { Link, Container,Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useLanguage } from './LanguageContext';
import { Helmet } from 'react-helmet';
import keywordsData from './keywords.json';

const NotFound = () => {
    const { language } = useLanguage();
    const SEO = ({ title, description, keywords }) => (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebSite",
                "name": "${title}",
                "url": "https://samrt-loader.com"
              }
            `}
          </script>
        </Helmet>
      );

    return (
        <Container>
        <SEO 
            title={language === 'ar' ? keywordsData.title_page_not_found_ar : keywordsData.title_page_not_found_en}
            description={language === 'ar' ? keywordsData.description_ar : keywordsData.description_en}
            keywords={keywordsData.keywords.join(', ')}
        />
        <Box width="100%"  my={3}>
            <Link href="../../" color="inherit" className="animated-link">
              <HomeIcon />&nbsp;{language === 'ar' ? 'الرئيسية' : 'Home'}
            </Link>
        </Box>
        <Box mt={4} mx={2} my={20} textAlign="center">
        <div>
            <h1>{language === 'ar' ? 'لم يتم العثور على الصفحة - 404' : '404 - Page Not Found'}</h1>
            <p>{language === 'ar' ? 'الصفحة المطلوبة غير متوفرة' : 'The page your looking for does not exist.'}</p>
        </div>
        </Box>
        </Container>
    );
};

export default NotFound;