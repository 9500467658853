import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useLanguage } from './LanguageContext';
import { Button, Box, CircularProgress, Modal,ButtonGroup } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CanvasDraw from 'react-canvas-draw';
import { useTheme } from './ThemeContext';
import './downloads.css';
import { updateClick } from './updateClick';
import ReactPlayer from 'react-player';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const VideoEditor = ({ videoURL , isMobile , onEditerClick , isEditer}) => {
  const { language } = useLanguage();
  const [effect, setEffect] = useState('none');
  const { theme } = useTheme();
  const [removeWatermark, setRemoveWatermark] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');
  const [loading, setLoading] = useState(false); // Add a loading state
  const [isError, setisError] = useState(false); // Add a loading state
  const [isEmptyError, setisEmptyError] = useState(false); // Add a loading state
  const [randomNumber, setRandomNumber] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const [watermarkRemovalType, setWatermarkRemovalType] = useState('none'); // 'auto' or 'manual'
  const [resolution, setResolution] = useState('orginal'); // '360p' or '260p'
  const [loadingCan, setLoadingCan] = useState(false);
  const [frameURL, setFrameURL] = useState({ first: '', last: '' });
  const [canvasLoaded, setCanvasLoaded] = useState(false);
  const canvasRef = useRef(null);
  const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [loadingShare, setLoadingShare] = useState(false);
  const modelBackgroundColor = theme === 'light' ? 'white' : '#090a09'

  const shareVideo = async (videoUrl,fileName) => {
    setLoadingShare(true);
    try {
      const response = await fetch(videoUrl);
      const blob = await response.blob();
      const file = new File([blob], fileName, { type: 'video/mp4' });

      if (navigator.share) {
        await navigator.share({
          files: [file],
          title: 'Check out this video',
          text: 'Here is a video I downloaded!',
        });
      } else {
        console.error('Sharing not supported on this browser');
      }
    } catch (error) {
      console.error('Error sharing video:', error);
    }
    setLoadingShare(false);
  };

  const handleOpenModal = async() => {
    await updateClick('howto');
    setOpenModal(true);
  };

  const handleOpenViewModal = async() => {
    setOpenViewModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };

  const handleCanvasLoad = () => {
    setCanvasLoaded(true);
  };

  const handleClearCanvas = () => {
    canvasRef.current.clear(); // Clear the canvas
  };

  const generateRandomNumber = () => {
    const number = Math.floor(Math.random() * 100000000) + 1; // Generates a random number between 1 and 100
    setRandomNumber(number);
  };

    // Scroll handlers
    const scrollLeft = () => {
      containerRef.current.scrollBy({ left: -100, behavior: 'smooth' });
    };
  
    const scrollRight = () => {
      containerRef.current.scrollBy({ left: 100, behavior: 'smooth' });
    };
  
    const scrollUp = () => {
      containerRef.current.scrollBy({ top: -100, behavior: 'smooth' });
    };
  
    const scrollDown = () => {
      containerRef.current.scrollBy({ top: 100, behavior: 'smooth' });
    };

  const handleEffectChange = (event) => {
    const eff = event.target.value;
    setEffect(eff);
    if (eff !== 'none') {
      setDialogOpen(true);
    } else if(removeWatermark){
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
    }
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const handleTouchStart = (event) => {
        if (event.targetTouches.length === 1) {
          const touch = event.targetTouches[0];
          container.dataset.touchStartX = touch.pageX;
          container.dataset.touchStartY = touch.pageY;
          container.dataset.scrollStartX = container.scrollLeft;
          container.dataset.scrollStartY = container.scrollTop;
        }
      };

      const handleTouchMove = (event) => {
        if (event.targetTouches.length === 1) {
          const touch = event.targetTouches[0];
          const dx = touch.pageX - container.dataset.touchStartX;
          const dy = touch.pageY - container.dataset.touchStartY;
          container.scrollLeft = container.dataset.scrollStartX - dx;
          container.scrollTop = container.dataset.scrollStartY - dy;
          event.preventDefault();
        }
      };

      container.addEventListener("touchstart", handleTouchStart);
      container.addEventListener("touchmove", handleTouchMove);

      return () => {
        container.removeEventListener("touchstart", handleTouchStart);
        container.removeEventListener("touchmove", handleTouchMove);
      };
    }

    if (frameURL && canvasRef.current) {
      const img = new Image();
      img.src = frameURL;
      img.onload = () => {
        canvasRef.current.loadSaveData({
          lines: [], // Clear existing lines if needed
        }, true);
      };
    }
  }, [containerRef,frameURL]);

  const handleResulotionTypeChange = async(event) => {
    const type = event.target.value;
    setResolution(type);
    if (type === 'orginal' && effect === 'none' && !removeWatermark) {
      setDialogOpen(false);
    } else {
      setDialogOpen(true);
    }
  }

  const handleWatermarkRemovalTypeChange = async(event) => {
    const type = event.target.value;
    setWatermarkRemovalType(type);
    if(type === "manual"){
        setRemoveWatermark(true); // Automatically enable watermark removal if a type is selected
        setDialogOpen(true);
        setLoadingCan(true);
        try {
          const response = await axios.post('https://samrt-loader.com/kydwon/api/capture-frame_v2', { videoURL });
          if(response.data.success){
            setFrameURL({ first: response.data.firstFrameURL, last: response.data.lastFrameURL });
            const videoDimension = response.data.dimensions;
            const [width, height] = videoDimension.split('x').map(Number);
            setVideoDimensions({ width: width, height: height }); // Set video dimensions
            console.log('height:', height); // Add this line
            console.log('width:', width); // Add this line
          } else {
            setWatermarkRemovalType('none');
            setRemoveWatermark(false); // Automatically enable watermark removal if a type is selected
            setDialogOpen(false);
            setisError(true);
          }
      } catch (error) {
          console.error('Error capturing frame:', error);
          setWatermarkRemovalType('none');
          setRemoveWatermark(false); // Automatically enable watermark removal if a type is selected
          setDialogOpen(false);
          setisError(true);
        }
        setLoadingCan(false);
    }else if(type === "auto"){
        setRemoveWatermark(true); // Automatically enable watermark removal if a type is selected
        setDialogOpen(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading state to true when button is pressed
    generateRandomNumber();
    if(watermarkRemovalType === "manual" ){
        try {
          let watermarkPositions = [];
          if (removeWatermark && canvasRef.current) {
            const data = canvasRef.current.getSaveData();
            const canvasElement = canvasRef.current.canvasContainer; // or canvasRef.current.canvas
            const canvasRect = canvasElement.getBoundingClientRect();
            const scalingFactorX = videoDimensions.width / canvasRect.width;
            const scalingFactorY = videoDimensions.height / canvasRect.height;
            watermarkPositions = JSON.parse(data).lines.map(line => {
              const xCoords = line.points.map(point => point.x * scalingFactorX);
              const yCoords = line.points.map(point => point.y * scalingFactorY);
              return {
                x: Math.min(...xCoords),
                y: Math.min(...yCoords),
                w: Math.max(...xCoords) - Math.min(...xCoords),
                h: Math.max(...yCoords) - Math.min(...yCoords)
              };
            });
          }
            console.log('watermarkPositions:', watermarkPositions); // Add this line
            if (watermarkPositions.length === 0) {
              // Handle the case where watermarkPositions is empty
              console.log('watermarkPositions array is empty');
              setisEmptyError(true);
              setLoading(false); // Set loading state to true when button is pressed
              return;
            }
            const formData = new FormData();
            formData.append('videoURL', videoURL);
            formData.append('effect', effect);
            formData.append('removeWatermark', removeWatermark);
            formData.append('watermarkPositions', JSON.stringify(watermarkPositions));
            formData.append('resolution', resolution);
            formData.append('type', 'custum');
            const response = await fetch('https://samrt-loader.com/kydwon/api/upload_edit', {
              method: 'POST',
              body: formData,
            });
          
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
          
            const data = await response.json();
            checkStatus(data.task_id);
          } catch (error) {
            console.error('Error processing video:', error);
            setLoading(false);
            setisError(true);
          }
    }else{
      try {
        const formData = new FormData();
        formData.append('videoURL', videoURL);
        formData.append('effect', effect);
        formData.append('removeWatermark', removeWatermark);
        formData.append('resolution', resolution);
        formData.append('type', 'auto');
        const response = await fetch('https://samrt-loader.com/kydwon/api/upload_edit', {
          method: 'POST',
          body: formData,
        });
      
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      
        const data = await response.json();
        checkStatus(data.task_id);
      } catch (error) {
        console.error('Error processing video:', error);
        setLoading(false); // Set loading state to false on error
        setisError(true);
      }
    }
  };

  const checkStatus = async (taskId) => {
    const statusResponse = await fetch(`https://samrt-loader.com/task_status/${taskId}`);
    const statusData = await statusResponse.json();

    if (statusData.status === 'processing') {
      setTimeout(() => checkStatus(taskId), 5000); // Check again after 5 seconds
    } else if (statusData.status === 'completed') {
      const url = statusData.output_path;
      setDownloadLink(url);
      setLoading(false);
      setisError(false);
      setisEmptyError(false);
      setWatermarkRemovalType('none');
      setDialogOpen(false);
      if(isEditer){
        localStorage.setItem('prossessType',"edit");
        localStorage.setItem('VideoUrl',url);
      }
    } else {
      console.error('Error processing video:', statusData.status);
      setLoading(false); // Set loading state to false on error
      setisError(true);
    }
  };

  return (
    <div>
      <Box width={'100%'} display="flex" my={2} flexDirection="row" textAlign="center" flexWrap="wrap">
        <h2 textAlign="center">{language === 'ar' ? 'التعديل على الفيديو' : 'Edit video setting'}</h2>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box width="100%" textAlign="center" my={2}>
        <Box width="100%" textAlign="center" my={2}>
          <select value={resolution} onChange={handleResulotionTypeChange}>
            <option value="orginal">{language === 'ar' ? 'الجودة الأساسية' : 'Orginal Resolution'}</option>
            <option value="orginal_no_sound">{language === 'ar' ? 'الأساسية بدون صوت' : 'Orginal no sound'}</option>
            <option value="360p">{language === 'ar' ? '360 بكسل' : '360p'}</option>
            <option value="360p_no_sound">{language === 'ar' ? '360 بكسل بدون صوت' : '360p no sound'}</option>
            <option value="240p">{language === 'ar' ? '240 بكسل' : '240p'}</option>
            <option value="240p_no_sound">{language === 'ar' ? '240 بكسل بدون صوت' : '240p no sound'}</option>
          </select>
        </Box>
          <select value={effect} onChange={handleEffectChange}>
            <option value="none">{language === 'ar' ? 'من دون تأثير' : 'None'}</option>
            <option value="grayscale">{language === 'ar' ? 'تدرج الرمادي' : 'Grayscale'}</option>
            <option value="invert">{language === 'ar' ? 'عكس الألوان' : 'Invert color'}</option>
            <option value="color_correction">{language === 'ar' ? 'تصحيح الألوان' : 'Color correction'}</option>
            <option value="blur">{language === 'ar' ? 'طمس الفيديو' : 'Blur video'}</option>
            <option value="cartoon">{language === 'ar' ? 'تأثير كرتوني' : 'Cartoonish effect'}</option>
          </select>
            <label>
              <input type="radio" value="auto" checked={watermarkRemovalType === 'auto'} onChange={handleWatermarkRemovalTypeChange} />
              {language === 'ar' ? 'طمس العلامة المائية تلقائيًا' : 'Auto Blur Watermark'}
              </label>
              <label>
              <input type="radio" value="manual" checked={watermarkRemovalType === 'manual'} onChange={handleWatermarkRemovalTypeChange} />
              {language === 'ar' ? 'طمس العلامة المائية يدويًا' : 'Manual Blur Watermark'}
            </label>
        </Box>
        <Box width="100%" textAlign="center" my={2}>
          {dialogOpen && (
            watermarkRemovalType === 'manual' ? (
                <div>
       {removeWatermark && (
            loadingCan ? (
            <span>
                <Box width="100%" alignItems="center" textAlign="center" my={2}>
                   <CircularProgress size={24} />
                </Box>
            </span>
          ) : (
            <div>
            <Box width="100%" alignItems="center" textAlign="center" my={2}>
            <Box width="100%" textAlign="center" my={2}>
            <h5>{language === 'ar' ? 'قم بتحديد مكان طمس العلامة المائية عبر رسم دائرة حول أماكن ظهور العلامة المائية':'Place of the water blur by drawing a circle around the watermark show positions.'}</h5>
            <Button color="info" onClick={handleOpenModal}>{language === 'ar' ? 'طريقة الإستخدام':'How to use'}</Button>
            <Button color="info" onClick={handleClearCanvas}>{language === 'ar' ? 'إستعادة':'Reset'}</Button>
            </Box>
            <Box width="100%" alignItems="center" textAlign="center" my={2}>
  <div
    ref={containerRef}
  >
    <CanvasDraw
      ref={canvasRef}
      canvasWidth={(isMobile ? 200 : 800) * (videoDimensions.height / videoDimensions.width)}  // Set the width based on the available space
      canvasHeight={(isMobile ? 300 : 800) * (videoDimensions.height / videoDimensions.width)} // Set the height based on the available space and the aspect ratio of the video
      imgSrc={isFirst ? frameURL.first : frameURL.last}
      style={{
        width: '100%', // Set the width to 100% of the parent container
        maxWidth:300,
        height: 300 * (videoDimensions.height / videoDimensions.width), // Set the height to 100% of the parent container
        position: 'relative'
      }}
      onLoad={handleCanvasLoad}
    />
  </div>
</Box>
<Box width="100%" alignItems="center" textAlign="center" my={2}>
<ButtonGroup variant="contained" color="secondary" aria-label="outlined button group">
                      <Button aria-label='first shot' color="secondary" onClick={() => setIsFirst(true)}>{language === 'ar' ? 'صورة 1' : 'first'}</Button>
                      <Button aria-label='last shot' color="secondary" onClick={() => setIsFirst(false)}>{language === 'ar' ? 'صورة 2' : 'last'}</Button>
                  </ButtonGroup>
</Box>
           </Box>
          </div>
          )
        )}
        <Box width="100%" textAlign="center" my={2}>
          {dialogOpen && !loadingCan && (
            <Button
              type="submit"
              aria-label={"download_edit_video"}
              variant="contained"
              color="info"
              disabled={loading}
            >
              {loading ? (
                <span>
                  <CircularProgress size={24} />&nbsp;&nbsp;{language === 'ar' ? 'قد يستغرق التعديل من 1 إلى 5 دقائق' : 'Editing it may take from 1 to 5 Min'}
                </span>
              ) : (
                <span>
                  <EditIcon style={{fontSize:'medium'}}/>&nbsp;&nbsp;{language === 'ar' ? 'التعديل' : 'Apply Effect'}
                </span>
              )}
            </Button>
          )}
        </Box>
                </div>
            ) : (
                <div>
                <Button
              type="submit"
              aria-label={"download_edit_video"}
              variant="contained"
              color="info"
              disabled={loading} // Disable button while loading
            >
              {loading ? (
                <span>
                  <CircularProgress size={24} />&nbsp;&nbsp;{language === 'ar' ? 'قد يستغرق التعديل من 1 إلى 5 دقائق' : 'Editing it may take from 1 to 5 Min'}
                </span>
              ) : (
                <span>
                  <EditIcon style={{fontSize:'medium'}}/>&nbsp;&nbsp;{language === 'ar' ? 'التعديل' : 'Apply Effect'}
                </span>
              )}
            </Button>
                </div>
            )
          )}
        </Box>
      </form>
      {downloadLink && (
        <Box width="100%" textAlign="center" my={2}>
          {!isEditer && (
          <Box width="100%" textAlign="center" my={2}>
            <Button aria-label={"add_to_editer"} variant="contained" color="inherit" onClick={() => onEditerClick('edit',downloadLink)}>
              {language === 'ar' ? 'إضافة إلى المحرر' : 'Add to editer'}
            </Button>
          </Box>
          )}
          {isMobile ? (
              <ButtonGroup variant="contained" color="info" aria-label="outlined button group">
                  <Button aria-label={"download_edit_video"} variant="contained" color="success" href={downloadLink} download={"samrt-loader.com_" + randomNumber + ".mp4"}>
                      <FileDownloadIcon style={{fontSize:'medium'}}/>&nbsp;&nbsp;{language === 'ar' ? 'تحميل' : 'Download'}
                  </Button> 
                  <Button disabled={loadingShare} aria-label={"download_share_edit_video"} variant="contained" color="info" onClick={() => shareVideo(downloadLink,"samrt-loader.com_" + randomNumber + ".mp4")}>
                      {loadingShare ? (
                          <span>
                              <CircularProgress size={24} />&nbsp;&nbsp;{language === 'ar' ? 'جار التحميل ...' : 'Donloading ...'}
                           </span>
                      ) : (
                          <span>
                              <SendAndArchiveIcon style={{fontSize:'medium'}}/>&nbsp;&nbsp;{language === 'ar' ? 'تحميل ومشاركة' : 'Download & Share'}
                          </span>
                      )}
                  </Button> 
                  <Button aria-label={"view_edit_video"} variant="contained" color="primary" onClick={handleOpenViewModal}>
                      <PlayArrowIcon style={{fontSize:'medium'}}/>&nbsp;&nbsp;{language === 'ar' ? 'عرض' : 'View'}
                  </Button> 
              </ButtonGroup>
          ) : (
            <ButtonGroup variant="contained" color="info" aria-label="outlined button group">
            <Button
            type="submit"
            aria-label={"download_edit_video"}
            variant="contained"
            color="success"
            href={downloadLink} download={"samrt-loader.com_" + randomNumber + ".mp4"}
          >
            <FileDownloadIcon style={{fontSize:'medium'}}/>&nbsp;&nbsp;{language === 'ar' ? 'تحميل الفيديو المعدل' : 'Download Processed Video'}
          </Button>
          <Button aria-label={"view_edit_video"} variant="contained" color="primary" onClick={handleOpenViewModal}>
              <PlayArrowIcon style={{fontSize:'medium'}}/>&nbsp;&nbsp;{language === 'ar' ? 'عرض' : 'View'}
          </Button> 
         </ButtonGroup>
          )}
        </Box>
      )}
      {isEmptyError && (
        <Box width="100%" textAlign="center" my={2}>
          <p style={{color:'red'}}>{language === 'ar' ? 'فشل تعديل الملف برجاء تحديد منطقة طمس واحدة على الأقل' : 'Failed to edit the video, please select at least on watermark position.'}</p>
        </Box>
      )}
      {isError && (
        <Box width="100%" textAlign="center" my={2}>
          <p style={{color:'red'}}>{language === 'ar' ? 'فشل تعديل الملف برجاء إختيار فيديو اخر لايزيد عن 1 دقيقة' : 'Failed to edit the video, please choose another file to edit with a maximum 1 min'}</p>
        </Box>
      )}
    {/* Modal to display view video */}
    <Modal open={openViewModal} onClose={handleCloseViewModal}>
      <Box sx={{ width: '80vw', maxWidth: '800px', height: '450px', backgroundColor: [modelBackgroundColor], overflow: 'hidden', margin: 'auto' }}>
          <ReactPlayer url={downloadLink} controls width="100%" style={{ maxHeight: '380px' }} />
          <Box my={2} textAlign="center">
          <Button aria-label="close" variant="contained" onClick={handleCloseViewModal}>{language === 'ar' ? 'إغلاق' : 'Close'}</Button>
        </Box>
      </Box>
    </Modal>
    {/* Modal to display video */}
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box sx={{ width: '80vw', maxWidth: '800px', height: '450px', backgroundColor: [modelBackgroundColor], overflow: 'hidden', margin: 'auto' }}>
          <video controls style={{ width: '100%', maxHeight: '380px' }}>
            <source src={"https://samrt-loader.com/how_to_edit.mp4"} type={"video/mp4"} />
            Your browser does not support the video tag.
          </video>
          <Box my={2} textAlign="center">
          <Button aria-label="close" variant="contained" onClick={handleCloseModal}>{language === 'ar' ? 'إغلاق' : 'Close'}</Button>
        </Box>
      </Box>
    </Modal>
    </div>
  );
};

export default VideoEditor;
