// src/components/TextsGallery.js

import React, { useState, useEffect, useCallback } from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Container, Box, Card, CardContent, Grid, Typography, Button, CircularProgress } from '@mui/material';
import SectionsBar from './TextSectionsBar';
import { useLanguage } from './LanguageContext';
import useSweetAlert from './SweetAlert';
import { Helmet } from 'react-helmet';
import keywordsData from './keywords.json';

const TextsGallery = () => {
  const { showAlert } = useSweetAlert();
  const [texts, setTexts] = useState([]);
  const { language } = useLanguage();
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [page, setPage] = useState(1);
  const [sectionIDSet, setSectionID] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSectionLoad, setIsSectionLoad] = useState(false);
  const [sections, setSections] = useState([]);
  const textsPerPage = 18;

  const fetchTexts = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    await fetchSections();
    try {
      const response = await fetch(`https://maoread.com/status/api/list/text_sec?showmaster=text_worlds&sec_id=${sectionIDSet}&page=${page}&recperpage=${textsPerPage}`);
      const data = await response.json();
      if (data.success && data.action === 'list') {
        setTexts(preTexts => [...preTexts, ...data.text_sec]);
        setTotalRecordCount(data.totalRecordCount);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  }, [page, sectionIDSet]);

  const fetchSections = async () => {
    if (isSectionLoad) {
      return;
    }
    try {
      const response = await fetch('https://maoread.com/status/api/list/text_worlds?recperpage=ALL'); // Replace with your API endpoint
      const data = await response.json();
      setSections(data.text_worlds);
      setIsSectionLoad(true);
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  };

  useEffect(() => {
    fetchTexts();
  }, [fetchTexts]);

  const handleSectionClick = async (sectionId) => {
    setSectionID(sectionId.toString());
    setPage(1);
    setTexts([]);
  };

  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      //alert();
      showAlert({
        text: language === 'ar' ? 'تم نسخ النص للحافظة' : 'Text copied to clipboard!',
        icon: 'success',
        position: language === 'ar' ? 'top-end' : 'top-start'
      });
    }, (err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  const morgin = language === 'ar' ? '-16px' : '0';
  const width = language === 'ar' ? 'calc(100% + 16px)' : 'calc(100% + 24px)';

  const SEO = ({ title, description, keywords }) => (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "${title}",
            "url": "https://samrt-loader.com"
          }
        `}
      </script>
    </Helmet>
  );
  
  return (
    <div>
      <SEO 
        title={language === 'ar' ? keywordsData.title_text_gallary_ar : keywordsData.title_text_gallary_en}
        description={language === 'ar' ? keywordsData.description_ar : keywordsData.description_en}
        keywords={keywordsData.keywords.join(', ')}
      />
      <SectionsBar sections={sections} onSectionClick={(section) => handleSectionClick(section)} />
      <Container>
        <Box sx={{ textAlign: 'center', marginBottom: 5, marginTop: 5 }}>
          <h2>{language === 'ar' ? 'مكتبة الكلمات' : 'Words Gallery'}</h2>
        </Box>
        <Grid container spacing={3} style={{ marginRight: morgin, width: width }}>
          {texts.map(text => (
            <Grid key={text.id} item xs={12} md={6} lg={4}>
              <Card variant="outlined">
                <CardContent>
                  <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
                    <h3>{text.title_text}</h3>
                  </Box>
                  <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                    <Box mx={5} my={1} textAlign="center">
                      <Typography variant="body2"><ThumbUpIcon style={{fontSize:'medium'}}/>&nbsp;{text.post_likes}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ textAlign: 'center', marginTop: 2 }}>
                    <Button color="inherit" variant="contained" onClick={() => handleCopy(text.title_text)}>
                        {language === 'ar' ? 'نسخ النص' : 'Copy'}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {loading ? (
          <Box sx={{ textAlign: 'center', marginTop: 5 }} my={5}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ textAlign: 'center', marginTop: 5 }} my={5}>
            {texts.length < totalRecordCount && (
              <Button aria-label="load_more" variant="contained" onClick={handleLoadMore}>
                {language === 'ar' ? 'المزيد' : 'Load More'}
              </Button>
            )}
          </Box>
        )}
      </Container>
    </div>
  );
};

export default TextsGallery;
