import React, { useEffect, useRef } from 'react';
import { Image, Transformer } from 'react-konva';
import useImage from 'use-image';

const EmojiComponent = ({ isMobile, videoDimensions, selectedEmoji, onEmojiDragEnd , emojyPosX, emojyPosY, emojyWidth, emojyHeight, emojyRotation ,handleSaveEmojyModel,handleSize }) => {
    const [image] = useImage(selectedEmoji);
    const imgRef = useRef();
    const trRef = useRef();
  
    useEffect(() => {
      if (imgRef.current) {
        const img = trRef.current.nodes([imgRef.current]);
        trRef.current.getLayer().batchDraw();
        img.on('transformend', () => {
            // Get the transformed size and position
            const newWidth = img.width() * img.scaleX();  // Don't forget to account for scale
            const newHeight = img.height() * img.scaleY(); // Apply scale to get the real size
            handleSize(newWidth,newHeight);
            const position = img.position();
            const rotation = img.rotation();
              // Calculate scaling factors relative to video dimensions
            const scalingFactorX = videoDimensions.width / (isMobile ? 260 : 300); // Adjust for mobile or desktop
            const scalingFactorY = videoDimensions.height / 400;
  
            // Normalize X position
            let normalizedX = position.x * scalingFactorX;
  
            // Normalize Y position (flip based on video height)
            let normalizedY = position.y * scalingFactorY;
  
            // Ensure the values stay within the video bounds
            normalizedX = Math.max(0, Math.min(videoDimensions.width, normalizedX));
            normalizedY = Math.max(0, Math.min(videoDimensions.height, normalizedY));
            handleSaveEmojyModel(normalizedX, normalizedY,newWidth,newHeight,rotation);

            // Optionally, reset scale to 1 after transform to avoid further scaling issues
            img.scaleX(1);
            img.scaleY(1);
          });
      }
    }, []);
  
    return (
      <React.Fragment>
        <Image
          ref={imgRef}
          image={image}
          x={emojyPosX}
          y={emojyPosY}
          width={emojyWidth}
          height={emojyHeight}
          rotation={emojyRotation}
          draggable
          onDragEnd={onEmojiDragEnd}
        />
        <Transformer ref={trRef} rotateEnabled={true} />
      </React.Fragment>
    );
  };

export default EmojiComponent;
