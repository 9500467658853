import React, { useEffect , useState } from "react";
import { Box, Typography, Button, Container, CircularProgress } from "@mui/material";
import { useLanguage } from "./LanguageContext";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Helmet } from 'react-helmet';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import keywordsData from './keywords.json';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const PaymentSuccess = () => {
  const { language } = useLanguage();
  const [paymentProssessID, setPaymentProssessID] = useState(0);
  const [invoiceID, setInvoiceID] = useState(0);
  const [invoiceDate, setInvoiceDate] = useState('');
  const [invoiceAmount, setInvoiceAmount] = useState(0);
  const [invoiceTax, setInvoiceTax] = useState(0);
  const [invoiceTotal, setInvoiceTotal] = useState(0);
  const [invoiceCustmerName, setInvoiceCustmerName] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const SEO = ({ title, description, keywords }) => (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "${title}",
            "url": "https://webapppros.com"
          }
        `}
      </script>
    </Helmet>
  );

  const ResponsiveTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>*</TableCell>
              <TableCell>{language === 'ar' ? 'القيمة' : 'Value'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{language === 'ar' ? 'رقم الفاتورة' : 'Invoice ID'}</TableCell>
              <TableCell>{invoiceID}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{language === 'ar' ? 'إسم العميل' : 'Customer Name'}</TableCell>
              <TableCell>{invoiceCustmerName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{language === 'ar' ? 'تاريخ الإصدار' : 'Invoice Date'}</TableCell>
              <TableCell>{invoiceDate}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{language === 'ar' ? 'قيمة الفاتورة' : 'Invoice Amount'}</TableCell>
              <TableCell>{invoiceAmount} {language === 'ar' ? 'يورو' : 'EUR'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{language === 'ar' ? 'قيمة الضريبة' : 'Invoice Tax'}</TableCell>
              <TableCell>{invoiceTax} {language === 'ar' ? 'يورو' : 'EUR'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{language === 'ar' ? 'إجمالي الفاتورة' : 'Invoice Total'}</TableCell>
              <TableCell>{invoiceTotal} {language === 'ar' ? 'يورو' : 'EUR'}</TableCell>
            </TableRow>
            {/* Add more rows here */}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const getPaymentData = async (paymentID, retryCheck = 0) => {
    setLoading(true);
    
    const totalRetry = retryCheck + 1;
    
    if (totalRetry > 9) {
      setLoading(false);
      setPaymentProssessID(0);
      return;
    }
    
    try {
      const formData = new FormData();
      formData.append('paymentID', paymentID);
      
      const response2 = await fetch(`https://webapppros.com/portalDash/api/checkSuccessPayment`, {
        method: 'POST',
        body: formData,
      });
      
      const data2 = await response2.json();
      
      if (data2.success) {
        setPaymentProssessID(data2.paymentID);
        setInvoiceID(data2.invoice_id);
        setInvoiceCustmerName(data2.custmer_name);
        setInvoiceDate(data2.invoice_date);
        setInvoiceAmount(data2.invoice_amount);
        setInvoiceTax(data2.invoice_tax);
        setInvoiceTotal(data2.invoice_total);
        setLoading(false);
      } else {
        setTimeout(() => getPaymentData(paymentID, totalRetry), 3000); // Retry after 2 seconds
      }
    } catch (error) {
      console.error('Error:', error);
      setTimeout(() => getPaymentData(paymentID, totalRetry), 3000); // Retry after 2 seconds in case of error
    }
  };
  
  
  useEffect(async() => {
    const paymentID = localStorage.getItem('payment_prossess_id');
    if(paymentID){
      await getPaymentData(paymentID,0);
    } else {
      navigate('./');
    }
  },[])

  return (
    <Container maxWidth="sm">
      <SEO 
        title={language === 'ar' ? keywordsData.title_success_payments_ar : keywordsData.title_success_payments_en}
        description={language === 'ar' ? keywordsData.sucess_payments_description_ar : keywordsData.sucess_payments_description_en}
        keywords={keywordsData.keywords.join(', ')}
      />
      <Box
        width={'100%'}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mt: 8,
          p: 4,
          boxShadow: 2,
          borderRadius: 2,
          textAlign: "center",
          bgcolor: "background.paper",
          width:'100%'
        }}
      >
      {loading ? (
        <div>
          <CircularProgress />
          <p>{language === 'ar' ? 'جار تأكيد العملية ...' : 'Conforming payment ...'}</p>
        </div>
      ) : (
        paymentProssessID === 0 ? (
          <div style={{width:'100%'}}>
            <HighlightOffIcon sx={{ fontSize: 80, color: "error.main" }} />
            <Typography variant="h4" gutterBottom sx={{ mt: 3 }}>
              {language === "ar" ? "لم تتم المعاملة بنجاح!" : "Payment failed!"}
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
              {language === "ar" ? `حدث خطأ في عملية االدفع برجاء مراجعة الدعم`: `Error while processing your payment, Please contact support.`}
            </Typography>
          </div>
        ) : (
          <div style={{width:'100%'}}>
            <CheckCircleOutlineIcon sx={{ fontSize: 80, color: "success.main" }} />
            <Typography variant="h4" gutterBottom sx={{ mt: 3 }}>
              {language === "ar" ? "تمت المعاملة بنجاح!" : "Payment Successful!"}
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
              {language === "ar" ? `شكراً لك على الدعم!`: `Thank you, for your support!`}
            </Typography>
          </div>
        )
      )}
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 4 }}
          disabled={loading}
          onClick={() => window.location.href = 'https://samrt-loader.com'}
        >
          {language === "ar" ? "الرئيسية" : "Home"}
        </Button>
      </Box>
    </Container>
  );
};

export default PaymentSuccess;
