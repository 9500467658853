import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, IconButton, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useLanguage } from './LanguageContext';

const FaceSwapImagesPicker = ({ open, onClose, onSelectFace }) => {
  const [faceList, setFaceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { language } = useLanguage();

  // Fetch emoji list from the API
  useEffect(() => {
    if (open) {
      axios.get('https://samrt-loader.com/kydwon/api/get-faces-list') // Replace with your actual API endpoint
        .then((response) => {
            setFaceList(response.data.face_list); // Assuming API returns emoji list under "imoji_list"
          setLoading(false);  // Stop loading when data is fetched
        })
        .catch((error) => {
          console.error('Error fetching emoji list:', error);
          setLoading(false);  // Stop loading even if there's an error
        });
    }
  }, [open]);  // Re-fetch the emoji list each time the dialog opens

  const handleFaceClick = (face) => {
    onSelectFace(face);  // Pass the selected emoji back
    onClose();  // Close the dialog
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle textAlign={'center'}>{language === 'ar' ? 'إختر وجه' : 'Pick a face' }</DialogTitle>
      <DialogContent textAlign={'center'}>
        {loading ? (
          <CircularProgress /> 
        ) : (
          <Grid container spacing={2}>
            {faceList.map((face, index) => (
              <Grid item key={index}>
                <IconButton onClick={() => handleFaceClick(face)}>
                  {/* Render emoji using its URL */}
                  <img src={face.face_url} alt={`face-${face.face_id}`} style={{ width: 50, height: 50 }} />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
          <Button aria-label={"close"} onClick={onClose} color="primary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
        </DialogActions>
    </Dialog>
  );
};

export default FaceSwapImagesPicker;
