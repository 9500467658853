import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Dialog,DialogContent,DialogActions,DialogTitle,Container,Link,CircularProgress , Box, Button, ButtonGroup,Card,CardContent } from '@mui/material';
import './downloads.css';
import { useLanguage } from './LanguageContext';
import { useTheme } from './ThemeContext';
import useSweetAlert from './SweetAlert';
import { Helmet } from 'react-helmet';
import keywordsData from './keywords.json';
import VideoShareThumbnail from './VideoShareThumbnail';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const ShareBox = () => {
  const { language, toggleLanguage } = useLanguage();
  const { theme, toggleTheme } = useTheme();
  const [videos, setVideos] = useState([]);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isError, setIsError] = useState(false);
  const [loadingLike, setLoadingLike] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);
  const [isLoadAccount, setIsLoadinAccount] = useState(false);
  const [userPhotoURL, setUserPhoto] = useState('https://samrt-loader.com/kydwon/smartloader.webp');
  const [userName, setUserName] = useState('User');
  const [userFaceBookAccount, setFaceBookAccount] = useState('None');
  const [userInstaAccount, setInstaAccount] = useState('None');
  const [userSnapAccount, setSnapAccount] = useState('None');
  const [userTikTokAccount, setTikTokAccount] = useState('None');
  const [userTwitAccount, setTwitAccount] = useState('None');
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [currentShareURL, setCurrentShareURL] = useState('');
  const [loadingDown, setLoadingDown] = useState({});

  const [isMobileBoolean, setIsMobileBoolean] = useState(() => {
    const checkIsMo = localStorage.getItem('isMobile');
    if (checkIsMo === "true") {
      // Optionally decode token to get user info
      return true;
    }
    return false;
  });

  const { showAlert } = useSweetAlert();

  const styles = {
    image: {
      width: '100px',
      height: '100%',
      borderRadius: '50%',
      objectFit: 'cover',
    },
  };

  const handleCloseAccount = () => {
    setOpenAccount(false);
  }

  const handleOpenViewDownloadModal = async(videoID,fileUrl) => {
    const checkIsLogin = localStorage.getItem('isLogin');
    if (checkIsLogin === "true") {
        try{
            // Set the specific loading state for the clicked button
            setLoadingDown(prevState => ({
                            ...prevState,
                             [videoID]: true,  // Set the loading state for this specific video
                          }));
            const formData = new FormData();
            formData.append('video_id', videoID);
            const res = await fetch('https://samrt-loader.com/kydwon/api/updateDownload' , {
              method: 'POST', // Specify the method
              headers: {
                 'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
              },
              body: formData
            });
            const data = await res.json();
            if(data.success){
                window.location.href = fileUrl;
            } else {
                showAlert({
                    text: language === 'ar' ? 'الرجاء تحديث الصفحة وإعادة المحاولة' : 'Please refresh page and try agian !',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start'
                  });
            }
        } catch (error) {
            showAlert({
                text: language === 'ar' ? 'الرجاء تحديث الصفحة وإعادة المحاولة' : 'Please refresh page and try agian !',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start'
              });
        } finally {
            setLoadingDown(prevState => ({
              ...prevState,
              [videoID]: false,  // Reset loading state for this specific video
          }));
        }
    } else {
        showAlert({
            text: language === 'ar' ? 'الرجاء تسجيل الدخول للتحميل' : 'Please login to download',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
    }
  }


  const openVideoSrc = async(videoUrl) => {
    const checkIsLogin = localStorage.getItem('isLogin');
    if (checkIsLogin === "true") {
        window.open(videoUrl , '_blank')
    } else {
        showAlert({
            text: language === 'ar' ? 'الرجاء تسجيل الدخول للتحميل' : 'Please login to download',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
    }
  }

  const handleCloseShareDialog = () => {
    setCurrentShareURL('');
    setShowShareDialog(false);
  }

  const handlePlayButtonClick = async(videoURL) => {
    // Add your logic to handle the play button click
    console.log("Play button clicked!");
    setCurrentShareURL(videoURL);
    setShowShareDialog(true);
  };

  const openUserInfoModel = async(userID) => {
    try{
        setOpenAccount(true);
        setIsLoadinAccount(true);
        const formData = new FormData();
        formData.append('user_id', userID);
        const res = await fetch('https://samrt-loader.com/kydwon/api/getUserInfo' , {
          method: 'POST', // Specify the method
          headers: {
             'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
          },
          body: formData
        });
        const data = await res.json();
        if(data.success) {
            if(data.user_photo_url){
                setUserPhoto(data.user_photo_url)
            }
            if(data.user_name){
                setUserName(data.user_name)
            }
            if(data.facebook_account){
                setFaceBookAccount(data.facebook_account)
            }
            if(data.insta_account){
                setInstaAccount(data.insta_account)
            }
            if(data.snap_account){
                setSnapAccount(data.snap_account)
            }
            if(data.twit_account){
                setTwitAccount(data.twit_account)
            }
            if(data.tiktok_account){
                setTikTokAccount(data.tiktok_account)
            }
        } else {
            showAlert({
                text: language === 'ar' ? 'الرجاء تسجيل الدخول أولا' : 'Please login first!',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start'
              });
              handleCloseAccount();
        }
    } catch (error) {
        showAlert({
            text: language === 'ar' ? 'الرجاء تسجيل الدخول أولا' : 'Please login first!',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
          handleCloseAccount();
    } finally {
        setIsLoadinAccount(false);
    }
  }

  useEffect(() => {
    fetchVideos(currentPage);
  }, [currentPage]);

  const fetchVideos = async (page) => {
    try {
      setLoading(true);
      const response = await fetch(`https://samrt-loader.com/kydwon/api/list/share_box_view2?page=${page}`);
      const data = await response.json();
      
      if (data.success) {
        setVideos(prevVideos => [...prevVideos, ...data.share_box_view2]);
        setTotalRecordCount(data.totalRecordCount);

        // Check if we have loaded all records
        if (videos.length + data.share_box_view2.length >= data.totalRecordCount) {
          setHasMore(false);
        }
      } else {
        console.error('Failed to fetch videos');
        setIsError(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setIsError(true);
    }
    setLoading(false);
  };

  const loadMoreVideos = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const SEO = ({ title, description, keywords }) => (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "${title}",
            "url": "https://samrt-loader.com"
          }
        `}
      </script>
    </Helmet>
  );

  return (
    <div>
      <SEO 
        title={language === 'ar' ? keywordsData.title_share_box_ar : keywordsData.title_share_box_en}
        description={language === 'ar' ? keywordsData.description_ar : keywordsData.description_en}
        keywords={keywordsData.keywords.join(', ')}
      />
    { isMobileBoolean ? (
      <Box my={2} textAlign="center" width={'100%'}>
        {videos.map((video, index) => (
        <Card key={video.share_id} my={5} width={'100%'} textAlign="center" style={{ marginBottom: '20px' , textAlign:'center'}}>
            <VideoShareThumbnail imageUrl={video.share_cover_url.url} isMobile={isMobileBoolean} onClick={() => handlePlayButtonClick(video.share_url)} />
          <p>{video.share_comment}</p>
          <p>
          <Link onClick={() => openUserInfoModel(video.share_by_user_id)} color="inherit" className="animated-link">
              {language === 'ar' ? 'بواسطة : ' : 'by user : '}{video.share_by_user_name}
          </Link>
          </p>
          <p>
          <Link onClick={() => openVideoSrc(video.orginal_share_src_url)} color="inherit" className="animated-link">
              {language === 'ar' ? 'رابط المصدر' : 'Src url'}
          </Link>
          </p>
          <Box width={'100%'} my={5} textAlign="center">
          <ButtonGroup variant="contained" color="info" aria-label="outlined button group">
                  <Button disabled={loadingDown[video.share_id]} aria-label={"download_share"} variant="contained" color="success" onClick={() => handleOpenViewDownloadModal(video.share_id , video.share_url)} download>
                    {loadingDown[video.share_id] ? (
                        <div>
                           <CircularProgress size={24} />&nbsp;&nbsp;{language === 'ar' ? 'جار التحميل ...' : 'Downloading...'}
                        </div>
                    ) : (
                        <div>
                           {language === 'ar' ? 'تحميل' : 'Download'} {video.share_comments}&nbsp;&nbsp;<FileDownloadIcon style={{fontSize:'medium'}}/>
                        </div>
                    )}
                  </Button> 
              </ButtonGroup>
              </Box>
        </Card>
        ))}
        {hasMore && !isError ? (
           <Box width={'100%'} my={5} textAlign={'center'}>
            <Button disabled={loading} aria-label={"load more"} variant="contained" color="inherit" onClick={loadMoreVideos}>
                {loading ? (
                    <span>
                        <CircularProgress size={24} />
                    </span>
                ) : (
                    <span>
                        {language === 'ar' ? 'المزيد' : 'Load more'}
                    </span>
                )}
            </Button>
           </Box>
        ) : (
            <Box width={'100%'} my={10} textAlign={'center'}>
                <h3>
                    {language === 'ar' ? 'لا توجد مشاركات جديدة لهذا اليوم' : 'No more share at this time !'}
               </h3>
            </Box>
        )}
    </Box>
    ) : (
        <Container>
        <Box mx={5} my={1} textAlign="center">
          {videos.map((video, index) => (
            <Card key={video.share_id} mx={5} my={5} textAlign="center" style={{ marginBottom: '20px' , textAlign:'center'}}>
            <CardContent>
            <VideoShareThumbnail imageUrl={video.share_cover_url.url} isMobile={isMobileBoolean} onClick={() => handlePlayButtonClick(video.share_url)} />
              <p>{video.share_comment}</p>
              <p>
          <Link href="#" onClick={() => openUserInfoModel(video.share_by_user_id)} color="inherit" className="animated-link">
               {language === 'ar' ? 'بواسطة : ' : 'by user : '}{video.share_by_user_name}
          </Link>
          </p>
          <p>
          <Link href="#" onClick={() => openVideoSrc(video.orginal_share_src_url)} color="inherit" className="animated-link">
              {language === 'ar' ? 'رابط المصدر' : 'Src url'}
          </Link>
          </p>
              <ButtonGroup variant="contained" color="info" aria-label="outlined button group">
                <Button disabled={loadingDown[video.share_id]} aria-label={"download_share"} variant="contained" color="success" onClick={() => handleOpenViewDownloadModal(video.share_id , video.share_url)} download>
                  {loadingDown[video.share_id] ? (
                        <div>
                           <CircularProgress size={24} />&nbsp;&nbsp;{language === 'ar' ? 'جار التحميل ...' : 'Downloading...'}
                        </div>
                    ) : (
                        <div>
                           {language === 'ar' ? 'تحميل' : 'Download'} {video.share_comments}&nbsp;&nbsp;<FileDownloadIcon style={{fontSize:'medium'}}/>
                        </div>
                    )}
                </Button> 
              </ButtonGroup>
              </CardContent>
            </Card>
          ))}
    
          {hasMore && !isError ? (
           <Box width={'100%'} my={5} textAlign={'center'}>
            <Button disabled={loading} aria-label={"load more"} variant="contained" color="inherit" onClick={loadMoreVideos}>
                {loading ? (
                    <span>
                        <CircularProgress size={24} />
                    </span>
                ) : (
                    <span>
                        {language === 'ar' ? 'المزيد' : 'Load more'}
                    </span>
                )}
            </Button>
           </Box>
          ) : (
            <Box width={'100%'} my={10} textAlign={'center'}>
                <h3>
                    {language === 'ar' ? 'لا توجد مشاركات جديدة لهذا اليوم' : 'No more share at this time !'}
               </h3>
            </Box>
          )}
        </Box>
        </Container>
    )}
    <Dialog open={openAccount} onClose={handleCloseAccount} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'عرض ملف المستخدم' : 'View account info'}</DialogTitle>
        <DialogContent>
          {isLoadAccount ? (
            <Box width="100%" minWidth={'300px'} my={5} textAlign={'center'}>
                <CircularProgress color='error' size={24} />
            </Box>
          ) : (
            <Box width="100%" my={5} alignItems={'center'} textAlign={'center'}>
                    <img
                       src={userPhotoURL}
                        alt={'user image'}
                        style={styles.image}
                    />
              &nbsp;
              <p>{ userName }</p>
              <Box width="100%" minWidth={'300px'} my={2} textAlign={'left'}>
                   <p><img src="https://samrt-loader.com/social_icons/facebook.png" height={'20px'} width={'20px'} alt='facebook'/>&nbsp;&nbsp;{userFaceBookAccount}</p>
                   <p><img src="https://samrt-loader.com/social_icons/instagram.png" height={'20px'} width={'20px'} alt='instagram'/>&nbsp;&nbsp;{userInstaAccount}</p>
                   <p><img src="https://samrt-loader.com/social_icons/snapchat.png" height={'20px'} width={'20px'} alt='snapchat'/>&nbsp;&nbsp;{userSnapAccount}</p>
                   <p><img src="https://samrt-loader.com/social_icons/tiktok.png" height={'20px'} width={'20px'} alt='tiktok'/>&nbsp;&nbsp;{userTikTokAccount}</p>
                   <p><img src="https://samrt-loader.com/social_icons/twitter.png" height={'20px'} width={'20px'} alt='twitter'/>&nbsp;&nbsp;{userTwitAccount}</p>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAccount} color="primary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Daily Share */}
      <Dialog open={showShareDialog} onClose={handleCloseShareDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogContent style={{width:'300px'}}>
        <Box mt={1} my={2}>
                  <ReactPlayer url={currentShareURL} controls={true}
                                   config={{
                                    file: {
                                       attributes: {
                                           controlsList: 'nodownload', // This hides the download button
                                        },
                                    }}} width="100%" height="300px" style={{ maxHeight:'300px' }} />
                    </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseShareDialog} color="primary">
              {language === 'ar' ? 'إغلاق' : 'Close'}
            </Button>
          </DialogActions>             
      </Dialog>
    </div>
  );
};

export default ShareBox;
