// src/components/VideoList.js

import React, { useState } from 'react';
import { Tooltip,ListItemButton,IconButton,List,ListItem,ListItemText,Divider,Dialog,DialogContentText,DialogTitle,DialogContent,DialogActions,TextField,Button,ButtonGroup,LinearProgress,Modal,Typography,CircularProgress } from '@mui/material';
import { useLanguage } from './LanguageContext';
import Box from '@mui/material/Box';
import VideoEditor from './VideoEditor';
import { useTheme } from './ThemeContext';
import axios from 'axios';
import { updateClick } from './updateClick';
import VideoThumbnail from './VideoThumbnail';
import ReactPlayer from 'react-player';
import useSweetAlert from './SweetAlert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLoading } from './LoadingContext';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import CoffeeIcon from '@mui/icons-material/Coffee';

const VideoList = ({ videoData , isMobile , videoCover , orginUrl , isEditer }) => {
    const { showAlert } = useSweetAlert();
    const { language } = useLanguage();
    const navigate = useNavigate(); // Initialize useNavigate
    const { theme } = useTheme();
    const [recognizeResult, setRecognizeResult] = useState(null);
    const [recognizeError, setRecognizeError] = useState('');
    const [editerType, setEditerType] = useState('normal');
    const [shareComment, setShareComment] = useState('');
    const [projectName, setProjectName] = useState('');
    const [editUrl, setEditUrl] = useState('');
    const [selectedProjectID, setSelectedProjectID] = useState(0);
    const [loadingShareBox, setLoadingShareBox] = useState(false);
    const [showGoToEditerModel, setShowGoToEditerModel] = useState(false);
    const [loadingEditerBox, setLoadingEditerBox] = useState(false);
    const [loadingAddEditBox, setLoadingAddEditBox] = useState(false);
    const [loadingDeleteEditerBox, setLoadingDeleteEditerBox] = useState(false);
    const { showLoading, hideLoading } = useLoading();

    const [editerBoxData, setEditerBoxData] = useState([]);
    //const [newEditerBox, setNewEditerBox] = useState(false);
    const [openEditerBox, setOpenEditerBox] = useState(false);
    const [openAddEditerModal, setOpenAddEditerModal] = useState(false);
    const [openSupportPaymentModal, setOpenSupportPaymentModal] = useState(false);
    const [openDeleteEditerBox, setOpenDeleteEditerBox] = useState(false);
    const [recognizeLoading, setRecognizeLoading] = useState(false);
    const [loadingShare, setLoadingShare] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openVideoModal, setOpenVideoModal] = useState(false);
    const [openShareBox, setOpenShareBox] = useState(false);
    const [amount, setAmount] = useState(1);
    const [email, setEmail] = useState('');
    const [note, setNote] = useState('smart loader support');
    const [loading, setLoading] = useState(false);
  
    // Handling amount change
    const handleAmountChange = (event) => {
      setAmount(event.target.value);
    };
  
    // Handling emaol change
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };
  
    // Handling emaol change
    const handleNoteChange = (event) => {
      setNote(event.target.value);
    };

    const modelBackgroundColor = theme === 'light' ? 'white' : '#090a09'
    if (!videoData || videoData.length === 0) {
        return (
            <h2>
                {language === 'ar' ? '' : ''}
            </h2>
        );
    }

    const firstVideoUrl = videoData[0].file || "";
    const firstVideoTitle = videoData[0].title || "";


    if(isEditer){
      var VideoProssessType = localStorage.getItem('prossessType')  ;
      if(VideoProssessType === null || VideoProssessType === undefined || VideoProssessType === "") {
        localStorage.setItem('prossessType',"normal");
        localStorage.setItem('VideoUrl',firstVideoUrl);
      }
    }

    const generateFileName = () => {
        const randomNumber = Math.floor(100000 + Math.random() * 900000); // Generates a 6-digit random number
        return `samrt-loader.com_${randomNumber}.mp4`;
    };

    const handleClose = () => {
        setOpenShareBox(false);
    };

    const handleOpenShareBox = () => {
        const checkIsLogin = localStorage.getItem('isLogin');
        if (checkIsLogin === "true") {
          // Optionally decode token to get user info
          setOpenShareBox(true);
        } else {
            showAlert({
                text: language === 'ar' ? 
                  'الرجاء تسجيل الدخول للإضافة' : 
                  'Please login to add !',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start',
              });
        }
    };

    const handleShareBoxCommentChange = (e) => {
        setShareComment(e.target.value);
    };  

    const shareVideo = async (videoUrl) => {
        setLoadingShare(true);
        try {
          const response = await fetch(videoUrl);
          const blob = await response.blob();
          const file = new File([blob], generateFileName(), { type: 'video/mp4' });
    
          if (navigator.share) {
            await navigator.share({
              files: [file],
              title: 'Check out this video',
              text: 'Here is a video I downloaded!',
            });
          } else {
            console.error('Sharing not supported on this browser');
          }
        } catch (error) {
          console.error('Error sharing video:', error);
        }
        setLoadingShare(false);
    };

    const handleCloseEditerBox = () => {
        setOpenEditerBox(false);
    };

    const handleCloseGoToEditer = () => {
      setShowGoToEditerModel(false);
    };

    const handleProjectNameChange = (e) => {
        setProjectName(e.target.value);
    };

    const handlePay = async() => {
      if(email === "" || email === null || email === undefined || amount === "" || amount === null || amount === undefined || amount < 1) {
          showAlert({
            text: language === 'ar' ? 'الرجاء إدخال حساب باي بال و القيمة 1 أو أكثر يورو !' : 'Enter your PayPal account and amount 1 or more EUR!',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
          return;
        }
  
      setLoading(true);
      try {
          const formData = new FormData();
          formData.append('cmd', '_xclick');
          formData.append('no_note', '1');
          formData.append('lc', 'UK');
          formData.append('bn', 'PP-BuyNowBF:btn_buynow_LG.gif:NonHostedGuest');
          formData.append('userid', '0');
          formData.append('is_mobile', 'true');
          formData.append('item_name', email);
          formData.append('item_number', '0');
          formData.append('payment_amount', amount);
          formData.append('payment_src', 'smartloader');
          formData.append('custom', note);
          const response2 = await fetch(`https://webapppros.com/portalDash/api/stratpayment`, {
            method: 'POST',
            body: formData,
          });
          const data2 = await response2.json();
          console.log('Uploaded data:', data2);
          if(data2.success) {
              localStorage.setItem('payment_prossess_id', data2.prossess_id);
              //window.open(data2.redirect_url, '_blank');
              window.location.href = data2.redirect_url;
          } else {
              showAlert({
                  text: language === 'ar' ? 'حدث خطأ , الرجاء إدخال قيمة 1 أو أكثر يورو' : 'Error prossess your payment, Please enter 1 or more EUR or Please try again later.',
                  icon: 'error',
                  position: language === 'ar' ? 'top-end' : 'top-start'
                });
          }
        } catch (error) {
          console.error('Error uploading image:', error);
          showAlert({
            text: language === 'ar' ? 'حدث خطأ , الرجاء إدخال قيمة 1 أو أكثر يورو' : 'Error prossess your payment, Please enter 1 or more EUR or Please try again later.',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        } finally {
          //setLoading(false);
        }
    };  

    const handleOpenEditerBox = async(type = "normal" , Eurl = "") => {
        const checkIsLogin = localStorage.getItem('isLogin');
        if (checkIsLogin === "true") {
        try{
            setOpenEditerBox(true);
            setLoadingEditerBox(true);
            const response = await fetch(`https://samrt-loader.com/kydwon/api/list/projects_video_editer`, {
              method: 'GET',
              headers: {
                'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
              }
            });
            const data = await response.json();
            if(data.success){
                setEditerType(type);
                setEditUrl(Eurl);
                setEditerBoxData(data.projects_video_editer);
            } else {
                handleCloseEditerBox();
                showAlert({
                    text: language === 'ar' ? 
                      'حدث خطأ أثناء تحميل معلومات الحساب, الرجاء تسجيل الدخول' : 
                      'An error occurred while getting your account data, Please try login again',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                  });
                  localStorage.setItem('authToken', null);
                  localStorage.setItem('isLogin', 'false');
                  localStorage.setItem('userid', null);
                  localStorage.setItem('username', null);
                  localStorage.setItem('userlevelid', null);  
            }
        } catch(error) {
            handleCloseEditerBox();
            showAlert({
                text: language === 'ar' ? 
                  'حدث خطأ أثناء تحميل معلومات الحساب, الرجاء تسجيل الدخول' : 
                  'An error occurred while getting your account data, Please try login again',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start',
              });
              localStorage.setItem('authToken', null);
              localStorage.setItem('isLogin', 'false');
              localStorage.setItem('userid', null);
              localStorage.setItem('username', null);
              localStorage.setItem('userlevelid', null);  
        } finally {
            setLoadingEditerBox(false);
        }
        } else {
            showAlert({
                text: language === 'ar' ? 
                  'الرجاء تسجيل الدخول للإضافة' : 
                  'Please login to add !',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start',
              });
        }    
    };

    const handleAddToShareBox = async (url) => {
        try{
            var done = false;
            setLoadingShareBox(true);
            const formData = new FormData();
            formData.append('share_url', url);
            formData.append('orginal_share_src_url', orginUrl);
            formData.append('share_comment', shareComment);
            formData.append('share_by_user_id', localStorage.getItem('userid'));
            formData.append('share_by_user_name', localStorage.getItem('username'));
            formData.append('share_likes', '0');
            formData.append('share_comments', '0');
            const response = await fetch(`https://samrt-loader.com/kydwon/api/add/share_box`, {
              method: 'POST',
              headers: {
                'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
              },
              body: formData,
            });
            const data = await response.json();
            if(data.success){
                done = true;
            } else {
                showAlert({
                    text: language === 'ar' ? 
                      'حدث خطأ أثناء الإضافة الرجاء المحاولة لاحقا' : 
                      'An error occurred while adding, Please try login again',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                  });
            }
        } catch(error) {
            showAlert({
                text: language === 'ar' ? 
                  'حدث خطأ أثناء الإضافة الرجاء المحاولة لاحقا' : 
                  'An error occurred while adding, Please try login again',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start',
              });
        } finally {
            setLoadingShareBox(false);
            handleClose();
            if(done){
                showAlert({
                    text: language === 'ar' ? 
                      'تمت الإضافة بنجاح' : 
                      'Adding successful',
                    icon: 'success',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                  });
            }
        }
    };

    const handleOpenModal = async (url) => {
        setRecognizeLoading(true); // Start loading
        setRecognizeError('');
        setRecognizeResult(null);
        setOpenModal(true); 
        await updateClick('recognize');
        try {
            // Send the request to your API
            const response = await axios.post('https://samrt-loader.com/recognize', {
                url, // Passing the url directly in the POST body
            });
    
            const data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
    
            // Check if recognition was successful
            if (data.status.code === 0) {
                setRecognizeResult(data.metadata.music);
            } else {
                setRecognizeError(language === 'ar' ? 'لم يتم العثور على معلومات الموسيقى' : 'No result found');
            }
        } catch (error) {
            setRecognizeError(language === 'ar' ? 'لم يتم العثور على معلومات الموسيقى' : 'No result found');
        } finally {
            setRecognizeLoading(false); // Stop loading
        }
    };
    
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleCloseVideoModal = () => {
        setOpenVideoModal(false);
    };

    const handleCloseAddEditerModal = () => {
        setOpenAddEditerModal(false);
    };

    const handleCloseDeleteEditerBox = () => {
        setOpenDeleteEditerBox(false);
    };

    const handleOpenDeleteEditerBox = () => {
        setOpenDeleteEditerBox(true);
    };

    const handleOpenAddNewEditerBox = () => {
        setOpenAddEditerModal(true);
    };

    const handleDelete = async(projectID) => {
        setSelectedProjectID(projectID);
        handleOpenDeleteEditerBox();
    };

    const handleGoToEditer = () => {
      setShowGoToEditerModel(false);
      showLoading();
      navigate(`../editer`); // Navigate to the ViewRest component
    };

    const goToPaymentPage = async() => {
      //navigate(`../payments`); // Navigate to the ViewRest component
      setOpenSupportPaymentModal(true);
      await updateClick('support');
    }

    const handleCloseSupportPaymentModal = () => {
      setLoading(false);
      setOpenSupportPaymentModal(false);
    }

    const handleDeletEditerBox = async(projectID) => {
        try{
            setLoadingDeleteEditerBox(true);
            var done = false;
            const formData = new FormData();
            formData.append('key_m[]', projectID);
            const response = await fetch(`https://samrt-loader.com/kydwon/api/delete/projects_video_editer`, {
              method: 'POST',
              headers: {
                'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
              },
              body: formData,
            });
            const data = await response.json();
            if(data.success){
                done = true;
            } else {
                showAlert({
                    text: language === 'ar' ? 
                      'حدث خطأ أثناء الحذف الرجاء المحاولة لاحقا' : 
                      'An error occurred while deleting, Please try login again',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                  });
            }
        } catch(error) {
            showAlert({
                text: language === 'ar' ? 
                  'حدث خطأ أثناء الحذف الرجاء المحاولة لاحقا' : 
                  'An error occurred while deleting, Please try login again',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start',
              });
        } finally {
            setLoadingDeleteEditerBox(false);
            handleCloseDeleteEditerBox();
            if(done){
                showAlert({
                    text: language === 'ar' ? 
                      'تمت الحذف بنجاح' : 
                      'Deleting successful',
                    icon: 'success',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                  });
                  handleOpenEditerBox();
            }
        }
    };

    const handleEdit = async(projectID) => {
        
    };

    const handleChoose = async (projectID) => {
        try {
            var done = false;
            var statusLimit = false;
            showLoading();
            const Vurl = editerType === 'normal' ? firstVideoUrl : editUrl;
            const formData = new FormData();
            formData.append('video_url', Vurl);
            formData.append('src_url', orginUrl);
            formData.append('project_id', projectID);
            formData.append('editer_type', editerType);
            
            const response = await fetch(`https://samrt-loader.com/kydwon/api/addtoedit_prossess`, {
                method: 'POST',
                headers: {
                    'X-Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: formData,
            });
    
            if (response.status === 204) {
                // Handle the 204 status code
                done = false;
                statusLimit = true;
            } else {
                // Handle other response statuses
                const data = await response.json();
                if (data.success) {
                    done = true;
                } else {
                    // Handle the case where `success` is false
                    showAlert({
                        text: language === 'ar' ? 
                            'حدث خطأ أثناء الإضافة الرجاء المحاولة فيديو اخر لايزيد عن 1 دقيقة' : 
                            'An error occurred while adding, Please try login again',
                        icon: 'error',
                        position: language === 'ar' ? 'top-end' : 'top-start',
                    });
                }
            }
        } catch (error) {
            showAlert({
                text: language === 'ar' ? 
                    'حدث خطأ أثناء الإضافة الرجاء المحاولة فيديو اخر لايزيد عن 1 دقيقة' : 
                    'An error occurred while adding, Please try login again',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start',
            });
        } finally {
            hideLoading();
            if (done) {
                showAlert({
                    text: language === 'ar' ? 
                        'تمت الإضافة بنجاح' : 
                        'Adding successful',
                    icon: 'success',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                });
                handleCloseEditerBox();
                if(!isEditer){
                  setShowGoToEditerModel(true);
                }
            } else {
                if(statusLimit){
                    showAlert({
                        text: language === 'ar' ? 
                            'لقد وصلت للحد الأقصى للفيديوهات لهذه القائمة' : 
                            'You reached the video limit to this list',
                        icon: 'error',
                        position: language === 'ar' ? 'top-end' : 'top-start',
                    });
                    if(!isEditer){
                      setShowGoToEditerModel(true);
                    }
                } else {
                    showAlert({
                        text: language === 'ar' ? 
                            'حدث خطأ أثناء الإضافة الرجاء المحاولة فيديو اخر لايزيد عن 1 دقيقة' : 
                            'An error occurred while adding, Please try login again',
                        icon: 'error',
                        position: language === 'ar' ? 'top-end' : 'top-start',
                    });
                }
            }
        }
    };
    

    const handleAddNewEditerBox = async() => {
        try{
            var done = false;
            setLoadingAddEditBox(true);
            const formData = new FormData();
            formData.append('project_by_user_id', localStorage.getItem('userid'));
            formData.append('projject_name', projectName);
            formData.append('project_start_date', "01-01-2024");
            const response = await fetch(`https://samrt-loader.com/kydwon/api/add/projects_video_editer`, {
              method: 'POST',
              headers: {
                'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
              },
              body: formData,
            });
            const data = await response.json();
            if(data.success){
                done = true;
            } else {
                showAlert({
                    text: language === 'ar' ? 
                      'حدث خطأ أثناء الإضافة الرجاء المحاولة لاحقا' : 
                      'An error occurred while adding, Please try login again',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                  });
            }
        } catch(error) {
            showAlert({
                text: language === 'ar' ? 
                  'حدث خطأ أثناء الإضافة الرجاء المحاولة لاحقا' : 
                  'An error occurred while adding, Please try login again',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start',
              });
        } finally {
            setLoadingAddEditBox(false);
            handleCloseAddEditerModal();
            if(done){
                showAlert({
                    text: language === 'ar' ? 
                      'تمت الإضافة بنجاح' : 
                      'Adding successful',
                    icon: 'success',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                  });
                  handleOpenEditerBox();
            }
        }
    };

    const handlePlayButtonClick = async() => {
        // Add your logic to handle the play button click
        console.log("Play button clicked!");
        setOpenVideoModal(true);
      };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" my={2} textAlign={language === 'ar' ? 'right' : 'left'}>
            {firstVideoUrl && (
                <Box mb={2} width="100%" textAlign="center">
                    <h2>{firstVideoTitle}</h2>
                    <VideoThumbnail imageUrl={videoCover} onClick={handlePlayButtonClick} />
                    {!isEditer && (
                    <Box my={2} width='100%'>
                    <ButtonGroup variant="contained" color="inherit" aria-label="outlined button group">
                       <Button aria-label={"add_to_share_box"} variant="contained" color="inherit" onClick={handleOpenShareBox}>
                           {language === 'ar' ? 'إضافة إلى المشاركات' : 'Add to share box'}
                       </Button>
                       <Button aria-label={"add_to_editer"} variant="contained" color="inherit" onClick={() => handleOpenEditerBox('normal','')}>
                           {language === 'ar' ? 'إضافة إلى المحرر' : 'Add to editer'}
                       </Button>
                     </ButtonGroup>
                   </Box>
                    )}
                </Box>
            )}
            <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                {videoData.map((video, index) => (
                    video.file !== "" && (
                    <Box key={index} mx={1} my={1} textAlign="center">
                        <h2>{video.resolution_type}</h2>
                        {video.resolution_type === "mp3" ? (
                            <ButtonGroup variant="contained" color="inherit" aria-label="outlined button group">
                                <Button aria-label={"download_video-" + video.resolution_type} variant="contained" color="success" href={video.file} download>
                                    <FileDownloadIcon style={{fontSize:'medium'}}/>&nbsp;&nbsp;{language === 'ar' ? 'تحميل' : 'Download'}
                                </Button>
                                <Button aria-label={"recognize_audio"} variant="contained" color="info" onClick={() => handleOpenModal(video.file)}>
                                    <MusicNoteIcon style={{fontSize:'medium'}}/>&nbsp;&nbsp;{language === 'ar' ? 'التعرف على الموسيقى' : 'Recognize'}
                                </Button>
                            </ButtonGroup>
                        ) : (
                            <div>
                                {isMobile ? (
                                    <ButtonGroup variant="contained" color="inherit" aria-label="outlined button group">
                                        <Button aria-label={"download_video-" + video.resolution_type} variant="contained" color="success" href={video.file} download>
                                            <FileDownloadIcon style={{fontSize:'medium'}}/>&nbsp;&nbsp;{language === 'ar' ? 'تحميل' : 'Download'}
                                        </Button> 
                                        <Button disabled={loadingShare} aria-label={"download_share_video-" + video.resolution_type} variant="contained" color="info" onClick={() => shareVideo(video.file)}>
                                            {loadingShare ? (
                                               <span>
                                                   <CircularProgress size={24} />&nbsp;&nbsp;{language === 'ar' ? 'جار التحميل ...' : 'Donloading ...'}
                                                </span>
                                            ) : (
                                                <span>
                                                    <SendAndArchiveIcon style={{fontSize:'medium'}}/>&nbsp;&nbsp;{language === 'ar' ? 'تحميل و مشاركة' : 'Download & Share'}
                                                </span>
                                            )}
                                        </Button> 
                                    </ButtonGroup>
                                ) : (
                                    <Button aria-label={"download_video-" + video.resolution_type} variant="contained" color="success" href={video.file} download>
                                        <FileDownloadIcon style={{fontSize:'medium'}}/>&nbsp;&nbsp;{language === 'ar' ? 'تحميل' : 'Download'}
                                    </Button> 
                                )}
                            </div>
                        )}
                    </Box>
                    )
                ))}
            </Box>
            {firstVideoUrl !== "" && (
                <Box width={'100%'} display="flex" my={2} flexDirection="row" justifyContent="center" flexWrap="wrap">
                  <VideoEditor videoURL={firstVideoUrl} isMobile={isMobile} onEditerClick={(type,eurl) => handleOpenEditerBox(type,eurl)} isEditer={isEditer}/>
                </Box>
            )}
            <Box width={'100%'} display="flex" my={4} flexDirection="row" justifyContent="center" flexWrap="wrap">
              <Button aria-label={"pay me a caffee"} variant="contained" color="info" onClick={goToPaymentPage} >
                <CoffeeIcon style={{fontSize:'medium'}}/>&nbsp;&nbsp;{language === 'ar' ? 'إدعمنا بكوب قهوة عالماشي' : 'Pay me a coffee'}
              </Button> 
            </Box>
            {/* Modal to display video */}
            <Modal open={openVideoModal} onClose={handleCloseVideoModal}>
                <Box sx={{ width: '80vw', maxWidth: '800px', height: '450px', backgroundColor: [modelBackgroundColor], overflow: 'hidden', margin: 'auto' }}>
                    <ReactPlayer url={firstVideoUrl} controls width="100%" style={{ maxHeight: '380px' }} />
                    <Box my={2} textAlign="center">
                        <Button aria-label="close" variant="contained" onClick={handleCloseVideoModal}>{language === 'ar' ? 'إغلاق' : 'Close'}</Button>
                    </Box>
                </Box>
            </Modal>
            {/* Dialog to display payment support */}
            <Dialog open={openSupportPaymentModal} onClose={handleCloseSupportPaymentModal} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
            <DialogTitle textAlign={'center'}>{language === 'ar' ? 'الشراء بواسطة باي بال' : 'PayPal Payment'}</DialogTitle>
              <DialogContent>
                <TextField
              label={language === 'ar' ? "حساب باي بال" : "Your PayPal account"}
              variant="outlined"
              fullWidth
              type="email"
              value={email}
              onChange={handleEmailChange}
              sx={{ mb: 4 }}
            />
            <TextField
              label={language === 'ar' ? "القيمة (يورو)" : "Amount (EUR)"}
              variant="outlined"
              fullWidth
              type="number"
              value={amount}
              onChange={handleAmountChange}
              sx={{ mb: 4 }}
            />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseSupportPaymentModal} color="secondary">
                  {language === 'ar' ? 'إغلاق' : 'Cancel'}
                </Button>
                <Button disabled={loading} onClick={handlePay} color="success">            
                {loading ? (
                    <span>
                        <CircularProgress size={24} />
                    </span>
                ) : (
                    <span>
                        {language === 'ar' ? 'شراء' : 'Pay'}
                    </span>
                )}
                </Button>
              </DialogActions>
            </Dialog>
            {/* Modal to display recognize audio */}
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={{ width: '80vw', maxWidth: '500px', height: '350px', backgroundColor: [modelBackgroundColor], overflow: 'hidden', margin: 'auto' }}>
                    <Box width="100%" my={5} textAlign={'center'}>
                        {recognizeLoading && <LinearProgress />}
                        {recognizeError && <Typography color="error">{recognizeError}</Typography>}
                        {recognizeResult && (
                        <div>
                            <h2>{language === 'ar' ? 'معلومات موسيقى المقطع' : 'Recognition Result:'}</h2>
                            <p>{language === 'ar' ? 'العنوان:' : 'Title:'} {recognizeResult[0].title}</p>
                            <p>{language === 'ar' ? 'الفنان:' : 'Artist:'} {recognizeResult[0].artists.map(artist => artist.name).join(', ')}</p>
                            <p>{language === 'ar' ? 'الألبوم:' : 'Album:'} {recognizeResult[0].album.name}</p>
                            <p>{language === 'ar' ? 'تاريخ النشر:' : 'Release Date:'} {recognizeResult[0].release_date}</p>
                        </div>
                        )}
                    </Box>
                    <Box my={2} textAlign="center">
                        <Button aria-label="close" variant="contained" onClick={handleCloseModal}>{language === 'ar' ? 'إغلاق' : 'Close'}</Button>
                    </Box>
                </Box>
            </Modal>
            <Dialog open={openShareBox} onClose={handleClose} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'إضافة مشاركة' : 'Add new share'}</DialogTitle>
        <DialogContent>
          <TextField
            disabled={true}
            margin="dense"
            label={language === 'ar' ? 'رابط فيديو المشاركة' : 'Share video url'}
            type="text"
            fullWidth
            variant="outlined"
            value={firstVideoUrl}
          />
          <TextField
            autoFocus
            margin="dense"
            label={language === 'ar' ? 'تعليق' : 'Comment'}
            type="text"
            fullWidth
            variant="outlined"
            value={shareComment}
            onChange={handleShareBoxCommentChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button disabled={loadingShareBox} onClick={() => handleAddToShareBox(firstVideoUrl)} color="success">            
            {loadingShareBox ? (
                    <span>
                        <CircularProgress size={24} />
                    </span>
                ) : (
                    <span>
                        {language === 'ar' ? 'مشاركة' : 'Share'}
                    </span>
                )}
          </Button>
        </DialogActions>
      </Dialog>
       
      <Dialog open={showGoToEditerModel} onClose={handleCloseGoToEditer} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogContent>
            <Typography textAlign={'center'} color="textSecondary">
               {language === 'ar' ? 'يمكنك الأن تحرير الفيديو , هل تريد الذهاب لقائمة المحرر ؟' : 'You can now edit the video, do you want to go to the editor menu?'}
            </Typography>
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseGoToEditer} color="secondary">
            {language === 'ar' ? 'ليس الأن' : 'Stay'}
          </Button>
          <Button aria-label={"close"} onClick={handleGoToEditer} color="success">
            {language === 'ar' ? 'الذهاب للمحرر' : 'Go now'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteEditerBox} onClose={handleCloseDeleteEditerBox} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'حذف الموضوع' : 'Delete project'}</DialogTitle>
        <DialogContent>
            <Typography textAlign={'center'} color="textSecondary">
               {language === 'ar' ? 'سيتم حذف الموضوع ولن تتمكن من إستعادة البيانات, هل تريد الحذف ؟' : 'This project will deleted for ever and you will be unable to restore it, Are you sure of delete ?'}
            </Typography>
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseDeleteEditerBox} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button aria-label={"delete"} disabled={loadingDeleteEditerBox} onClick={() => handleDeletEditerBox(selectedProjectID)} color="error">            
            {loadingDeleteEditerBox ? (
                    <span>
                        <CircularProgress size={24} />
                    </span>
                ) : (
                    <span>
                        {language === 'ar' ? 'حذف' : 'Delete'}
                    </span>
                )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAddEditerModal} onClose={handleCloseAddEditerModal} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'إضافة موضوع' : 'Add new project'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label={language === 'ar' ? 'عنوان الموضوع' : 'Project name'}
            type="text"
            fullWidth
            variant="outlined"
            value={projectName}
            onChange={handleProjectNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseAddEditerModal} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button aria-label={"add"} disabled={loadingAddEditBox} onClick={handleAddNewEditerBox} color="success">            
            {loadingAddEditBox ? (
                    <span>
                        <CircularProgress size={24} />
                    </span>
                ) : (
                    <span>
                        {language === 'ar' ? 'إضافة' : 'Add'}
                    </span>
                )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditerBox} onClose={handleCloseEditerBox} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'قائمة المحرر' : 'Editer list'}</DialogTitle>
        <DialogContent>
          <DialogContentText textAlign={'center'}>
            {language === 'ar' ? 'إختيار أو إضافة موضوع جديد للمحرر' : 'Choose and add your editer projects'}
          </DialogContentText>
          {loadingEditerBox ? (
            <Box width="100%" minWidth={'300px'} my={5} textAlign={'center'}>
                <CircularProgress color='error' size={24} />
            </Box>
    ) : editerBoxData.length > 0 ? (
        <Box sx={{ maxHeight: '350px', overflow: 'auto' }}>
        <List>
          {editerBoxData.map((project) => (
            <React.Fragment key={project.project_id}>
              <ListItem>
                <ListItemButton onClick={() => handleChoose(project.project_id)}>
                  <ListItemText
                    primary={project.projject_name}
                    secondary={language === 'ar' ? `( ${project.project_video_count} / 4 )` : `( ${project.project_video_count} / 4 )`}
                  />
                </ListItemButton>
                <Tooltip title={language === 'ar' ? 'تعديل' : 'Edit'}>
                  <IconButton edge="end" disabled={true} aria-label="edit" onClick={() => handleEdit(project.project_id)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={language === 'ar' ? 'حذف' : 'Delete'}>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(project.project_id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
        </Box>
      ) : (
        <Typography textAlign={'center'} color="textSecondary">
          {language === 'ar' ? 'لم يتم العثور على مواضيع' : 'No projects found'}
        </Typography>
      )}
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseEditerBox} color="primary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button aria-label={"add project"} onClick={handleOpenAddNewEditerBox} color="success">
            {language === 'ar' ? 'إضافة موضوع جديد' : 'Add new project'}
          </Button>
        </DialogActions>
      </Dialog>
        </Box>
    );
};

export default VideoList;
