import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useLanguage } from './LanguageContext';

const MySwal = withReactContent(Swal);

const useSweetAlert = () => {
  const { language } = useLanguage();

  const showAlert = ({ text, icon, position }) => {
    return MySwal.fire({
      text,
      icon,
      position,  // Position the alert at the top-end
      showConfirmButton: false,  // Hide the confirmation button
      toast: true,  // Make it look like a toast
      timer: 5000,
      // RTL for Arabic
      customClass: {
        popup: language === 'ar' ? 'swal-rtl' : '',  // Add RTL class if language is Arabic
      },
      // Set the direction of the text
      html: `<div style="direction: ${language === 'ar' ? 'rtl' : 'ltr'};">${text}</div>`,
    });
  };

  return { showAlert };
};

export default useSweetAlert;
